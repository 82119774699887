import React from "react";

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
        <img src="/svgs/next.svg" className="w-10"/>
        </div>
    );
}

export default NextArrow