import {Link} from "react-router-dom";
import React from "react";
import CallIcon from '@mui/icons-material/Call';
import GavelIcon from '@mui/icons-material/Gavel';
const Footer = () => {
    return (
        <div className="h-full bg-no-repeat bg-cover bg-center text-white mt-20"
             style={{backgroundImage: `url(/footer-bg.png)`}}>
            <div className="md:mx-20 max-md:mx-10">
                <img src="/logo-text.svg" className="w-60 md:h-60 max-md:h-20"/>
                <div className="flex flex-col gap-4">
                    {/*<div className="md:flex max-md:grid max-md:grid-cols-2 items-center gap-4">*/}
                    {/*    <div className="flex items-center gap-3">*/}
                    {/*        <p>Location1</p>*/}
                    {/*        <img src="/svgs/arrow.svg" className="md:w-25 md:h-25 max-md:w-3 max-md:h-3"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="flex items-center gap-3">*/}
                    {/*        <p>Location2</p>*/}
                    {/*        <img src="/svgs/arrow.svg" className="md:w-25 md:h-25 max-md:w-3 max-md:h-3"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="flex items-center gap-3">*/}
                    {/*        <p>Location3</p>*/}
                    {/*        <img src="/svgs/arrow.svg" className="md:w-25 md:h-25 max-md:w-3 max-md:h-3"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="flex items-center gap-3">*/}
                    {/*        <p>Location4</p>*/}
                    {/*        <img src="/svgs/arrow.svg" className="md:w-25 md:h-25 max-md:w-3 max-md:h-3"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="flex items-center gap-3">*/}
                    {/*        <p>Location5</p>*/}
                    {/*        <img src="/svgs/arrow.svg" className="md:w-25 md:h-25 max-md:w-3 max-md:h-3"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<p className="border-b"></p>*/}
                    <div className="md:flex justify-between pb-5 font-archivo">
                        <div className="flex items-center justify-between gap-3">
                            <div className="flex items-center gap-1">
                                <CallIcon/>
                                <Link to="/contactUs" className="text-white text-sm m-0">Contact Us</Link>
                            </div>
                           <div className="flex items-center gap-1">
                               <GavelIcon/>
                               <Link to="/terms-conditions" className="text-sm text-white m-0"
                                     target="_blank">
                                   Terms and conditions
                               </Link>
                           </div>
                        </div>
                        <div className="flex items-center gap-3 max-md:pt-3">
                            {/*<img src="/svgs/facebook.svg"/>*/}
                            <Link className="m-0"
                                to="https://www.instagram.com/beachbed_company" target="_blank">
                                <img src="/svgs/instagram.svg"/>
                            </Link>
                            {/*<img src="/svgs/tiktok.svg"/>*/}
                        </div>
                    </div>
                    <p className="pb-4">© 2024 <span className="font-bold">beachbeds</span>, All Rights
                        Reserved</p>
                </div>
            </div>
        </div>
    )
}
export default Footer;
