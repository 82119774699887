import { Navigate, Outlet } from "react-router-dom";

export const RequiresAuthentication = () => {
    const isLoggedIn = !!localStorage.getItem("token");
    const handleNavigateUser = () => {
        return <Navigate to="/401" replace />;
    };
    return isLoggedIn ? <Outlet /> : handleNavigateUser();
};

export const RequiresAdminAuthentication = () => {
    const isLoggedIn = !!localStorage.getItem("token");
    const isBeach = (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'beach_officer' )
    const handleNavigateUser = () => {
        return <Navigate to="/401" replace />;
    };
    return (isLoggedIn && isBeach) ? <Outlet /> : handleNavigateUser();
};

export const RequiresBeachAuthentication = () => {
    const isLoggedIn = !!localStorage.getItem("token");
    const isAdmin = localStorage.getItem('role') !== 'user'

    const handleNavigateUser = () => {
        return <Navigate to="/401" replace />;
    };
    return (isLoggedIn && isAdmin) ? <Outlet /> : handleNavigateUser();
};
