import Header from "../../components/Header";
import LoaderComp from "../../components/LoaderComp";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {setTotalValues, validatePaymentIntent} from "../../store/Auth/AuthActions.ts";
import './index.css'
import moment from "moment/moment";
import {checkIfWeekend} from "../../util";
import SmallBanner from "../../components/SmallBanner";
import {smallPageBanner} from "../../constants/homeData";
import Footer from "../../components/Footer";

const OrderComplete = () => {
    const urlParams = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const paymentIntent = new URLSearchParams(urlParams.search).get('payment_intent');
    const {isLoading, currentReceipts, subTotalBag, totalBag} = useSelector((state) => state.auth)
    const [activeTab, setActiveTab] = useState(3)
    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                step: 1,
                label: "Bag Review"
            },
            {
                step: 2,
                label: "Payment"
            },
            {
                step: 3,
                label: "Receipt"
            }
        ]
    )

    useEffect(() => {
        if (!paymentIntent) {
            navigate('/my-bag')
        } else {
            dispatch(validatePaymentIntent(paymentIntent))
        }
    }, []);

    useEffect(() => {
        let subTotal = 0
        currentReceipts.map((bag) => {
            if (bag.reservation_type === 'default') {
                subTotal = subTotal +
                    ((bag.count - (bag.split_with_count ?? 0)) * (checkIfWeekend(bag.selected_date) ?
                        Number(bag.beach_item.pivot.weekend_price) :
                        Number(bag.beach_item.pivot.weekday_price)))
            } else {
                subTotal = subTotal +
                    (bag.count * Number(bag.beach.day_pass_price))
            }
        })
        dispatch(setTotalValues(subTotal))
    }, [currentReceipts]);

    return (
        <>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            {
                !isLoading ?
                    <div className="container">
                        <div className="row">
                            <div className="flex py-3 max-md:gap-2">
                                {
                                    breadCrumb.map((item, index) => (
                                        <div className="flex gap-2 my-3">
                                            <p className={"m-0 max-md:text-sm md:text-xl" + ((index + 1) <= activeTab ? ' breadcrumb-text-active' : 'breadcrumb-text-disabled')}>{item.label}</p>
                                            {
                                                index !== breadCrumb.length - 1 &&
                                                <div className="d-flex align-items-center mr-2">
                                                    <div
                                                        className={((index + 1) <= activeTab ? 'rounded-point-active' : 'rounded-point-disabled')}></div>
                                                </div>

                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="justify-content-center">
                                <div className="row">
                                    <div className="text-center">
                                        <h1 className={"text-primary-dark-green text-3xl max-md:text-xl"}>You're all set!</h1>
                                        <p className={"text-primary-dark-green"}>Check your inbox and spam folders for a
                                            confirmation email</p>
                                    </div>
                                    <div className="md:grid grid-cols-[2fr_1fr] mt-5">
                                       <div>
                                           {
                                               currentReceipts.map((item) => (

                                                   (item.count - (item.split_with_count ?? 0) > 0) && (
                                                       item.reservation_type === 'default' ?
                                                           <div
                                                               className="shadow-xl md:mx-4 px-3 mb-3 rounded-xl py-3">
                                                               <div
                                                                   className="md:flex items-center text-primary-dark-green justify-between gap-2 py-3">
                                                                   <div className="flex items-center gap-2">
                                                                       <img src="/svgs/beach.svg"/>
                                                                       <h5 className="font-bold md:text-xl max-md:text-sm">{item.beach.name}</h5>
                                                                   </div>
                                                                   <div className="max-md:mt-2 flex items-center gap-1"><img src="/svgs/calendar-check.svg" /> {moment(item.selected_date).format("dddd MMMM, DD")}</div>
                                                               </div>
                                                               <div className="flex items-center md:gap-4 max-md:gap-2 mb-3">
                                                                   <img
                                                                       className={"md:w-1/2 md:h-48 max-md:w-40 max-md:h-40 rounded-xl"}
                                                                       src={item.beach.thumbnail_image}/>
                                                                   <div className="text-primary-dark-green">
                                                                       <div className={"m-0 gap-2 flex items-center"}>
                                                                           <img src={item.item.image}
                                                                                className="w-10 h-10"/>
                                                                           {item.item.name}
                                                                       </div>
                                                                       <div className="px-3">
                                                                           <p className="max-md:mt-3 md:py-3 flex items-center gap-1">
                                                                               <span
                                                                                   className="bg-primary-orange rounded-full w-2 h-2"></span>
                                                                               Qty: {item.count - (item.split_with_count ?? 0)}
                                                                           </p>
                                                                           <div
                                                                               className="flex items-center gap-1 max-md:py-4">
                                                                              <span
                                                                                  className="bg-primary-orange rounded-full w-2 h-2"></span> Total:
                                                                               ${(item.count - (item.split_with_count ?? 0)) * (checkIfWeekend(item.selected_date) ? Number(item.beach_item.pivot.weekend_price) : Number(item.beach_item.pivot.weekday_price))}
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               {
                                                                   item.beach.note &&
                                                                   <div className="px-1 py-3">
                                                                       <div
                                                                           className="flex items-center p-3 gap-3 text-primary-dark-green bg-primary-light-green rounded-xl max-md:text-sm">
                                                                       <img src="/svgs/warning.svg"/> {item.beach.note}
                                                                       </div>
                                                                   </div>
                                                               }
                                                           </div>
                                                           :
                                                           <>
                                                               <div className="row  mt-5 px-3 mb-3">
                                                                   <div className="col-md-3">
                                                                       <img className={"card-beach-image-receipts"}
                                                                            src={item.beach.thumbnail_image}/>
                                                                   </div>
                                                                   <div className="col align-items-center text-primary-dark-green">
                                                                       <div className="row">
                                                                           <div className="col-md-9">
                                                                               <h5>{item.beach.name}</h5>
                                                                           </div>
                                                                           <div
                                                                               className="col-md-3 d-flex justify-content-end">
                                                                               ${item.count * item.beach.day_pass_price}
                                                                           </div>
                                                                       </div>
                                                                       <div className="row ">
                                                                           <p className={"m-0 "}>
                                                                               <b>Day Pass Price: </b>
                                                                               ({item.count}) /
                                                                               {moment(item.selected_date).format("dddd MMMM, DD")}
                                                                           </p>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               {
                                                                   item.beach.note &&
                                                                   <div className="row mb-3">
                                                                       <div className="col-md-12">
                                                                           <div
                                                                               className="flex items-center p-3 gap-3 text-primary-dark-green bg-primary-light-green rounded-xl">
                                                                               <img
                                                                                   src="/svgs/warning.svg"/> {item.beach.note}
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               }
                                                           </>
                                                   )
                                               ))
                                           }
                                       </div>
                                        <div className="">
                                            <div></div>
                                            <div className="shadow-xl text-primary-dark-green p-5 rounded-xl">
                                                <h3 className="font-bold text-xl underline text-center mb-3">Order Information</h3>
                                                <div className="flex items-center justify-between py-2">
                                                    Subtotal
                                                    <p>$ {subTotalBag}</p>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <b>VAT</b>
                                                    <p>--</p>
                                                </div>
                                                <div className="flex items-center justify-between py-2">
                                                    <b>Total</b>
                                                    <p>$ {totalBag}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <LoaderComp/>
            }
            <Footer/>
        </>
    )
}
export default OrderComplete
