import {twMerge} from "tailwind-merge";

const LoaderComp = ({className, svgClassName}) => {
    return (
        <div className="bg-white fixed inset-0 z-50">
            <section
                id="loader-section"
                className={twMerge(
                    "absolute top-0 grid h-screen w-full place-items-center bg-[radial-gradient(#00505944,#005059)] ",
                    className,
                )}>
                <div>
                    <svg
                        viewBox="0 0 100 86"
                        fill="#005059"
                        xmlns="http://www.w3.org/2000/svg"
                        className={twMerge("h-24 w-24 animate-pulse", svgClassName)}
                    >
                        <path d="M81.9026 41.16V40.69C92.0726 36.67 94.4426 29.81 94.4426 23.07C94.4326 4.8 79.1826 0.96 60.7326 0.19L50.4826 0C50.1526 0 49.8226 0 49.4926 0H6.23258C2.72258 0 -0.0874202 2.9 0.0025798 6.4C0.40258 21.23 0.402578 33.5 0.402578 43.76C0.402578 54.02 0.40258 64.47 0.0025798 78.75C-0.0974202 82.26 2.72258 85.16 6.23258 85.16H49.4826C77.2726 85.16 99.1626 81.61 99.1626 60.91C99.1626 52.28 95.2626 44.23 81.8926 41.16H81.9026ZM73.1426 71.98H23.0426C17.1126 71.98 12.2926 67.16 12.2926 61.23V37.51C12.2926 36.43 13.1726 35.56 14.2426 35.56C15.3126 35.56 16.1926 36.44 16.1926 37.51V52.94C18.0526 51.4 20.4426 50.47 23.0326 50.47H73.1326C79.0626 50.47 83.8826 55.29 83.8826 61.22C83.8826 67.15 79.0626 71.97 73.1326 71.97L73.1426 71.98Z" />
                        <path d="M73.1426 54.65H23.0426C19.4126 54.65 16.4726 57.6 16.4726 61.22C16.4726 64.84 19.4226 67.79 23.0426 67.79H73.1426C76.7726 67.79 79.7126 64.84 79.7126 61.22C79.7126 57.6 76.7626 54.65 73.1426 54.65Z" />
                    </svg>
                    <p className={"text-[#005059] animate-pulse text-center"}>Loading ...</p>
                </div>

            </section>
        </div>

    );
};
export default LoaderComp;