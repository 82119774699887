import Header from "../../components/Header";
import {useDispatch, useSelector} from "react-redux";
import {getMyReservations, signOutUser} from "../../store/Auth/AuthActions.ts";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import './style.css'
import moment from "moment/moment";
import {checkIfWeekend} from "../../util";
import {LinearProgress, linearProgressClasses} from "@mui/material";
import Slider from "@mui/material/Slider";
import SmallBanner from "../../components/SmallBanner";
import {smallPageBanner} from "../../constants/homeData";
import Footer from "../../components/Footer";
import styled from "@emotion/styled";
import DeleteProfileModal from "./DeleteProfileModal";
import DeleteIcon from '@mui/icons-material/Delete';
const MyAccount = () => {
    const {
        firstName,
        beachBedPoints,
        myReservations,
        currentTotalReservations,
        nextLevel,
        myLevel
    } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#F96300AA',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#F96300',
        },
    }));
    const signOut = () => {
        dispatch(signOutUser())
        navigate('/')
    }
    const [openDeleteModal , setOpenDeleteModal] = useState(false)
    const editAccountToggle = () => {
        navigate('/edit-my-profile')
    }
    useEffect(() => {
        dispatch(getMyReservations())
    }, []);
    return (
        <>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            <div className="container-fluid px-lg-5 px-md-5">
                <div className="md:flex items-center pt-8">
                    <div className="md:flex gap-4 text-primary-dark-green font-bold">
                        <div className="flex items-center gap-1 text-primary-dark-green text-lg">
                            <img src="/svgs/cash-refund.svg" className="w-6 h-6"/>
                            You
                            have <b>{beachBedPoints ?? 0}</b> points.
                        </div>
                        <div className="flex max-md:mt-5">
                            <div className="flex items-center underline">
                                <img src="/svgs/edit.svg"/>
                                <button onClick={editAccountToggle} className={" px-3  me-3"}>Edit Account
                                </button>
                            </div>
                            <div className="flex items-center underline">
                                <img src="/svgs/user.svg"/>
                                <button onClick={signOut} className={" px-3"}>Sign Out</button>
                            </div>
                           <div className="flex items-center gap-2">
                               <DeleteIcon className="text-[#F96300]"/>
                               <div onClick={()=>setOpenDeleteModal(true)} className={"flex items-center text-primary-dark-green underline rounded-lg cursor-pointer"}>
                                   Delete Account
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-8">
                    <div className="col-6">
                        <h2 className="text-2xl max-md:text-xl text-primary-dark-green uppercase font-archivo">Level</h2>
                        <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <img src={myLevel?.badge_image} height={50} width={50} className={"rounded-circle"}/>
                    </div>
                    <div className="col-3 ms-5">
                        {
                            nextLevel?.badge_image &&
                            <img src={nextLevel?.badge_image} height={50} width={50} className={"rounded-circle ms-5"}/>
                        }
                    </div>
                </div>
                {
                    (nextLevel && myLevel) &&
                    <div className="row mt-3">
                        <div className="col-4">
                            <BorderLinearProgress variant="determinate"
                                                  value={currentTotalReservations > nextLevel.value ? 100 : currentTotalReservations * 100 / nextLevel.value}/>

                        </div>
                    </div>
                }

                {
                    (nextLevel && myLevel) ?
                        <div className="row mt-1">
                            <div className="col-3">
                                <h2 className="text-lg text-primary-dark-green uppercase ">{myLevel?.value} reservations</h2>
                            </div>
                        </div>
                        :
                        <p className="font-archivo">Total Number : {currentTotalReservations} </p>
                }

                {
                    myReservations.length > 0 ?
                        <>
                            <div className="mt-5">
                                <h2 className="text-2xl max-md:text-xl text-primary-dark-green">All Time
                                    Reservations</h2>
                                <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                            </div>
                            <div className="row">
                                <div className="card bg-gradient-to-t from-[#00414A] to-[#005662]">
                                    <div className="grid grid-cols-6 my-3">
                                        <h2 className="md:text-lg max-md:text-sm text-white ">Beach</h2>
                                        <h2 className="md:text-lg max-md:text-sm text-white ">Date</h2>
                                        <h2 className="md:text-lg max-md:text-sm text-white">Item</h2>
                                        <h2 className="md:text-lg max-md:text-sm text-white">#Items</h2>
                                        <h2 className="md:text-lg max-md:text-sm text-white">Total</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="card bg-white shadow-md overflow-x-auto">
                                    {
                                        myReservations.map((item) => (
                                            item.reservation_type === 'default' ?
                                                <div
                                                    className="grid grid-cols-6 py-3 text-primary-gray md:text-lg text-xs">
                                                    <p>{item.beach?.name}</p>
                                                    <p> {moment(item.selected_date).format("dddd MMMM, DD")} </p>
                                                    <p>{item.beach_item?.name}</p>
                                                    <p>{item.count}</p>
                                                    <p>
                                                        $ {item.count * (checkIfWeekend(item.selected_date) ? Number(item.beach_item?.pivot?.weekend_price) : Number(item.beach_item?.pivot?.weekday_price))}
                                                    </p>
                                                    <u><h5>Book Again</h5></u>
                                                </div>
                                                :
                                                <div
                                                    className="grid grid-cols-6 text-primary-gray py-3 md:text-xl text-xs">
                                                    <p>{item.beach?.name}</p>
                                                    <p> {moment(item.selected_date).format("dddd MMMM, DD")} </p>
                                                    <p>Day Pass</p>
                                                    <p>{item.count}</p>
                                                    <p>
                                                        $ {item.count * item.beach?.day_pass_price}
                                                    </p>
                                                    <u><h5 className={"table-header"}>Book Again</h5></u>
                                                </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </>

                        :
                        <div className="row mt-5">
                            <h4 className={"header-text"}>You don't have any reservations yet.</h4>
                        </div>
                }
            </div>
            {openDeleteModal && <DeleteProfileModal open={openDeleteModal} handleClose={()=>setOpenDeleteModal(false)}/>}
            <Footer/>
        </>
    )
}
export default MyAccount
