import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography} from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import {setMultipleSelectedItems, setUserMultiSelectingAction} from "../../store/MultipleSelect/MultipleSelectActions";
import {useDispatch, useSelector} from "react-redux";
import { redoOptionHandled,
    setSavedSectionsAction,
    setUndoData, undoOptionHandled
} from "../../store/Map/MapActions";

const CustomContextMenu = ({onClose, x, y}) => {
    const {userMultiSelecting} = useSelector((state) => state.multipleSelect)
    const {savedSections , undoRedoIndex , watchedSavedSections ,currentIndexMap , undoIndexArray} = useSelector((state) => state.map)

    const dispatch = useDispatch()
    const handleMultipleSelect = ()=>{
        dispatch(setUserMultiSelectingAction(true))
    }
    const undoMultipleSelect = ()=>{
        let data = [...savedSections]
       data= data.map((item)=>({
            ...item,
            multiple_selected:false,
        }))
        dispatch(setSavedSectionsAction(data))
        dispatch(setUserMultiSelectingAction(false))
        dispatch(setMultipleSelectedItems([]))
        dispatch(setUndoData(data))

    }
    const handleUndo = () => {
        dispatch(undoOptionHandled())
    }
    const handleRedo = () =>{
        dispatch(redoOptionHandled())
    }

    return (
        <div
            style={{
                position: 'absolute',
                top: y,
                left: x,
                border: '1px solid #ccc',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                zIndex: '100000'
            }}
        >
            <Paper sx={{width: 320, maxWidth: '100%'}}>
                <MenuList>
                    <MenuItem onClick={handleUndo}>
                        <ListItemIcon>
                            <UndoIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Undo</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘Z
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleRedo}>
                        <ListItemIcon>
                            <RedoIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Redo</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘Y
                        </Typography>
                    </MenuItem>
                    {
                        userMultiSelecting
                            ?
                            <MenuItem onClick={undoMultipleSelect}>
                                <ListItemIcon>
                                    <DynamicFeedIcon fontSize="small"/>
                                </ListItemIcon>

                                <ListItemText>Undo Multiple Select</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                </Typography>
                            </MenuItem>
                            :
                            <MenuItem onClick={handleMultipleSelect}>
                                <ListItemIcon>
                                    <DynamicFeedIcon fontSize="small"/>
                                </ListItemIcon>

                                <ListItemText>Multiple Select</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                </Typography>
                            </MenuItem>
                    }


                </MenuList>
            </Paper>
        </div>
    );
}
export default CustomContextMenu