import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    modifyMap,
    setItemInformation, setShowSwappingMessage,
    swapSeats
} from "../../../../store/BeachAdmin/BeachAdminActions";
import ShowImageModal from "../../../../components/ShowImageModal";
import {Snackbar, Alert} from '@mui/material';
import {Backdrop, CircularProgress} from "@mui/material";
const DisabledAdminItem = ({
                               item,
                               index,
                               addingNewReservation,
                               getReservationsBySearch,
                               clickedItemIndex,
                               setClickedItemIndex
                           }) => {
    const dispatch = useDispatch()
    const {
        map,
        isSwapping,
        itemToSwap,
        selectedDate,
    } = useSelector((state) => state.beachAdmin)
    const [showImage, setShowImage] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false);
    const showSwappingMessage = useSelector((state) => state.beachAdmin.showSwappingMessage);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // const handleItemClicked = () => {
    //     if (!item.isCaptureImage) {
    //         setClickedItemIndex(index);
    //         if (isSwapping) {
    //             // todo handle swap here
    //             if (item.reservation_status !== 'checked_in') {
    //                 dispatch(swapSeats(itemToSwap, item, selectedDate));
    //                 setShowSnackbar(true);
    //                 dispatch(setShowSwappingMessage(false));
    //             }
    //         } else {
    //             if (item.reservation_status !== 'canceled' && item.reservation_id) {
    //                 dispatch(setItemInformation(item, true))
    //             } else {
    //                 if (addingNewReservation) {
    //                     if (item.can_be_reserved === 1) {
    //                         const updatedInitialMap = map.map((mapItem, i) =>
    //                             i === index ? {...mapItem, is_selected_to_add: !mapItem.is_selected_to_add} : mapItem
    //                         );
    //                         dispatch(modifyMap(updatedInitialMap))
    //
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         setShowImage(true)
    //     }
    // }
    // const handleItemClicked = () => {
    //     if (!item.isCaptureImage) {
    //         setClickedItemIndex(index);
    //         if (isSwapping) {
    //             if (itemToSwap.can_be_reserved === 1 && item.can_be_reserved === 1) {
    //                 if (item.reservation_status !== 'checked_in') {
    //                     dispatch(swapSeats(itemToSwap, item, selectedDate));
    //                     setShowSnackbar(true);
    //                     dispatch(setShowSwappingMessage(false));
    //                 }
    //             } else {
    //                 setErrorSnackbarMessage('You cannot swap with this item.');
    //                 setShowErrorSnackbar(true);
    //                 dispatch(setShowSwappingMessage(false));
    //             }
    //         } else {
    //             if (item.reservation_status !== 'canceled' && item.reservation_id) {
    //                 dispatch(setItemInformation(item, true))
    //             } else {
    //                 if (addingNewReservation) {
    //                     if (item.can_be_reserved === 1) {
    //                         const updatedInitialMap = map.map((mapItem, i) =>
    //                             i === index ? {...mapItem, is_selected_to_add: !mapItem.is_selected_to_add} : mapItem
    //                         );
    //                         dispatch(modifyMap(updatedInitialMap))
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         setShowImage(true)
    //     }
    // }

    const handleItemClicked = () => {
        if (!item.isCaptureImage) {
            setClickedItemIndex(index);

            if (isSwapping) {
                if (itemToSwap && itemToSwap.can_be_reserved === 1 && item.can_be_reserved === 1) {
                    setIsLoading(true);
                    dispatch(swapSeats(itemToSwap, item, selectedDate)).then(() => {
                        setShowSnackbar(true);
                        dispatch(setShowSwappingMessage(false));
                        setIsLoading(false);
                    }).catch(() => {
                        setIsLoading(false);
                    });
                } else {
                    setErrorSnackbarMessage('You cannot swap with this item.');
                    setShowErrorSnackbar(true);
                    dispatch(setShowSwappingMessage(false));
                }
            } else {
                if (item.reservation_status !== 'canceled' && item.reservation_id) {
                    dispatch(setItemInformation(item, true));
                } else {
                    if (addingNewReservation) {
                        if (item.can_be_reserved === 1) {
                            const updatedInitialMap = map.map((mapItem, i) =>
                                i === index ? {...mapItem, is_selected_to_add: !mapItem.is_selected_to_add} : mapItem
                            );
                            dispatch(modifyMap(updatedInitialMap));
                        }
                    }
                }
            }
        } else {
            setShowImage(true);
        }
    }

    return (
        <>
            <Snackbar
                open={showErrorSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowErrorSnackbar(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert onClose={() => setShowErrorSnackbar(false)} severity="error">
                    {errorSnackbarMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert onClose={() => setShowSnackbar(false)} severity="success">
                    Swap success!
                </Alert>
            </Snackbar>
            <div
                onClick={handleItemClicked}
                style={{
                    display: 'grid',
                    left: item?.startX,
                    top: item?.startY,
                    width: item.width ?? '50px',
                    height: item.height ?? '50px',
                    filter: `${item.is_selected_to_add ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)' : ''} ${isSwapping && clickedItemIndex !== index && item.can_be_reserved === 1 ? 'drop-shadow(0px 0px 7px #298a3e)' : ''}`.trim(),
                    // filter: isSwapping && clickedItemIndex !== index && item.can_be_reserved === 1 ? 'drop-shadow(0px 0px 7px #298a3e)' : 'none',
                    opacity: isSwapping && clickedItemIndex === index ? 0.5 : 1,
                    color: "black",
                    transform: `rotate(${item.rotateDegree}deg)`,
                    justifyContent: 'center',
                    zIndex: 10,
                    // filter: (item.is_selected_to_add) && 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)'
                }}
                className="absolute">
                {
                    item.isCaptureImage ?
                        <img src={'/' + item.image} height={item.height ?? '50px'} width={item.width ?? '50px'}/>
                        :
                        <>
                            {
                                addingNewReservation ?
                                    ((item.reservation_status === 'canceled' || !item.reservation_status) && item.can_be_reserved === 1 ?
                                            <>
                                                <img style={{
                                                    position: "absolute",
                                                    filter: 'drop-shadow(rgb(41, 138, 62) 1px 1px 7px)'
                                                }} src={item.image} height={item.height ?? '50px'}
                                                     width={item.width ?? '50px'}
                                                />
                                            </>
                                            :
                                            item.can_be_reserved === 1 ?
                                                <>
                                                    <img style={{
                                                        position: "absolute",
                                                        filter: 'brightness(0) saturate(100%) invert(45%) sepia(39%) saturate(3675%) hue-rotate(330deg) brightness(111%) contrast(101%)'
                                                    }} src={item.image} height={item.height ?? '50px'}
                                                         width={item.width ?? '50px'}
                                                    />
                                                    {
                                                        item.user &&
                                                        <span style={{
                                                            zIndex: 20,
                                                            fontSize: item.special_height ? item.special_height * 12 / 50 : '12px',
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            height: 'max-content',
                                                            alignSelf: 'center'
                                                        }}>{item.user?.first_name?.charAt(0).toLocaleUpperCase()}{item.user?.last_name?.charAt(0).toLocaleUpperCase()}</span>
                                                    }
                                                </>
                                                :
                                                <img style={{
                                                    position: "absolute",
                                                }} src={item.image} height={item.height ?? '50px'}
                                                     width={item.width ?? '50px'}
                                                />
                                    )
                                    :
                                    item.reservation_status === 'reserved' ?
                                        <>
                                            <img style={{
                                                position: "absolute",
                                                filter: 'brightness(0) saturate(100%) invert(45%) sepia(39%) saturate(3675%) hue-rotate(330deg) brightness(111%) contrast(101%)'
                                            }} src={item.image} height={item.height ?? '50px'}
                                                 width={item.width ?? '50px'}
                                            />
                                            {
                                                item.user &&
                                                <span style={{
                                                    zIndex: 20,
                                                    fontSize: item.special_height ? item.special_height * 12 / 50 : '12px',
                                                    fontWeight: 'bold',
                                                    color: 'white',
                                                    height: 'max-content',
                                                    alignSelf: 'center'
                                                }}>{item.user?.first_name?.charAt(0).toLocaleUpperCase()}{item.user?.last_name?.charAt(0).toLocaleUpperCase()}</span>
                                            }
                                        </>
                                        :
                                        <>
                                            <img style={{
                                                position: "absolute", filter: item.reservation_status === 'checked_in' ?
                                                    'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)'
                                                    : item.reservation_status === 'reservation_confirmed' && 'brightness(0) saturate(100%) invert(93%) sepia(74%) saturate(3489%) hue-rotate(326deg) brightness(97%) contrast(105%)',
                                            }} src={item.image} height={item.height ?? '50px'}
                                                 width={item.width ?? '50px'}/>
                                            {
                                                (item.user && item.reservation_status !== 'Canceled') &&
                                                <span style={{
                                                    zIndex: 20,
                                                    fontSize: item.special_height ? item.special_height * 12 / 50 : '12px',
                                                    fontWeight: 'bold',
                                                    color: 'white',
                                                    height: 'max-content',
                                                    alignSelf: 'center'
                                                }}>{item.user?.first_name?.charAt(0).toLocaleUpperCase()}{item.user?.last_name?.charAt(0).toLocaleUpperCase()}</span>
                                            }
                                        </>
                            }
                        </>
                }
            </div>

            {
                showImage &&
                <ShowImageModal open={showImage} handleClose={() => setShowImage(false)} imageUrl={item.imageAttached}/>
            }
            {showSwappingMessage &&
                <div
                    className="absolute text-sm bg-primary-dark-green text-white top-[5%] left-[50%] px-3 py-2 rounded-lg"
                    style={{
                        transform: 'translate(-50%, -50%)', zIndex: 1000
                    }}>
                    You're swapping a seat !
                </div>
            }
            {isLoading &&
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
        </>

    )
}
export default DisabledAdminItem
