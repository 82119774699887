import axios from "axios";

export class AxiosMiddleware {
    static boot() {
        // Add axios default base URL
        axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

        // Interceptor on request
        axios.interceptors.request.use(function (config) {
            const token = localStorage.getItem("token");
            config.headers.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        // Interceptor on response
        axios.interceptors.response.use(
            response => response,
            error => {
                if (!error.response) {
                    // Network error
                    window.dispatchEvent(new CustomEvent('axiosNetworkError'));
                }
                switch (error?.response?.status) {
                    case 401:
                        // Handle 401 error
                        // window.location.href = '401';
                        break;
                    case 403:
                        // Handle 403 error
                        // window.location.href = 'verify-email';
                        break;
                    default:
                        return Promise.reject(error);
                }
            }
        );
    }
}
