export const guestsFiltersData = {
    filters: [
        {
            category: 'gender',
            type: 'checkbox',
            options: [
                {
                    label: 'Male',
                    value: 'male'
                },
                {
                    label: 'Female',
                    value: 'female'
                }
            ]
        },
        {
            category: 'age',
            type: 'radio',
            options: [
                {
                    label: '< 18 yrs',
                    value: '0,18'
                },
                {
                    label: '18 - 50 yrs',
                    value: '18,50'
                },
                {
                    label: '> 50 yrs',
                    value: '50,1000'
                },
            ]
        },
        {
            category: 'beach_points',
            type: 'radio',
            options: [
                {
                    label: '< 50 pts',
                    value: '0,50'
                },
                {
                    label: '50 - 100 pts',
                    value: '50,100'
                },
                {
                    label: '100 -150 pts',
                    value: '100,150'
                },
                {
                    label: '> 150 pts',
                    value: '150,100000'
                },
            ],
        },
        {
            category: 'tags',
            type: 'checkbox',
            options: []
        },
    ],
    colors: {
        'beach_points': {
            '< 50 pts': '#A5782B',
            '50 - 100 pts': '#F96300',
            '100 -150 pts': '#8ABABF',
            '> 150 pts': '#A1A1A1'
        }
    }
};

export const filtersLabel = {
    guest_types: {
        'VIP': 'VIP',
        'Regular': 'Regular',
        'New': 'New'
    },
    guest_status: {
        'Active': 'Active',
        'Inactive': 'Inactive',
        'Pending': 'Pending'
    }
};
