import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {setCurrentSectionAction, setSavedSectionsAction, setUndoData} from "../../../store/Map/MapActions";

const NewSectionDetails = ({currentMapHeight}) =>{
    const { currentSection , sectionCategories , savedSections} = useSelector((state) => state.map)
    const dispatch = useDispatch()
    const handleTypeChanged = (event) => {
        dispatch(setCurrentSectionAction({...currentSection, type: event.target.value}))
    }
    const handleNameChanged = (event) => {
        dispatch(setCurrentSectionAction({...currentSection, name: event.target.value}))
    }
    const handleRoundedChanged = (event) => {
        dispatch(setCurrentSectionAction({...currentSection, rounded: event.target.value}))
    }

    const submitSectionDetails = (value) => {
        let data = [...savedSections]
        let index = savedSections.length - 1
        data[index] = {...value, isReady: true}
        dispatch(setSavedSectionsAction(data))
        dispatch(setUndoData(data))

        dispatch(setCurrentSectionAction(null))
    }

    return (
        <div style={{
            position: 'absolute',
            left:  currentSection.startX + 30,
            top:( currentSection.startY < currentMapHeight/2 )?  currentSection.startY + 10 : currentSection.startY - 200 ,
            width: '215px',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
            borderRadius: '10px',
            zIndex: 10
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h5 className={"mt-3"} style={{color: '#2b737c'}}>Enter section details</h5>
                    </div>
                    <div className="col-md-12 mt-3">
                        <p className={"m-0"}>Section category</p>
                    </div>
                    <div className="col-md-12 mt-2">
                        <select onChange={handleTypeChanged} className={"form-control"}>
                            <option value=""></option>
                            {
                                sectionCategories.map((category , index)=>(
                                    <option key={index} value={category.code}>{category.title}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-md-12 mt-3">
                        <p className={"m-0"}>Section Name</p>
                    </div>
                    <div className="col-md-12 mt-3">
                        <input className={"form-control"} onChange={handleNameChanged} />
                    </div>
                    <div className="col-md-6 mt-3">
                        <p>Rounded?</p>
                    </div>
                    <div className="col-md-6 mt-3">
                        <input type={"checkbox"} onChange={handleRoundedChanged} />
                    </div>
                    <div className={"col-md-12 mt-4 mb-3"}>
                        <button onClick={() => submitSectionDetails(currentSection)}
                                className={"btn col-md-12"}
                                style={{backgroundColor: '#005662', color: 'white'}}>Submit
                        </button>
                    </div>
                </div>

            </div>

        </div>
    )
}
export default NewSectionDetails