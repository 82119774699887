import axios from "axios";
import {beachAdminSlice} from "./BeachAdminSlice";

export const getBeachDashboardInformation = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))

        let beachId = sessionStorage.getItem('beach_id')
        const response = await axios.get('/beach/' + beachId + '/get-dashboard-information?date=' + data)
        dispatch(beachAdminSlice.actions.setInitialMap(response.data.data.map))
        dispatch(beachAdminSlice.actions.setMap(response.data.data.map))
        dispatch(beachAdminSlice.actions.setCountry(response.data.data.country))
        dispatch(beachAdminSlice.actions.setMapScreenWidth(response.data.data.beach.map_initial_width))
        dispatch(beachAdminSlice.actions.setMapScreenHeight(response.data.data.beach.map_initial_height))
        dispatch(beachAdminSlice.actions.setBeach(response.data.data.beach))
        dispatch(beachAdminSlice.actions.setStats(response.data.data.stats))
        dispatch(beachAdminSlice.actions.setReservations(response.data.data.reservations))
        dispatch(beachAdminSlice.actions.setIsLoading(false))

    }
}

export const handleChangeDateModal = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setChangeDate(data))
    }
}


export const handleSelectedDateChange = (data) => {
    return async (dispatch) => {
        sessionStorage.setItem('current_selected_date', data)
        dispatch(beachAdminSlice.actions.setSelectedDate(data))
    }
}

export const syncSelectedDate = (date) => {
    return async (dispatch) => {
        localStorage.setItem('selectedDate', date);
        dispatch(beachAdminSlice.actions.syncSelectedDate(date));
    };
};
export const filterMapInformation = (filter, date) => {
    return async (dispatch) => {
        filter = {
            ...filter,
            date: date
        }
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        let beachId = sessionStorage.getItem('beach_id')
        const response = await axios.get(`/beach/${beachId}/filter-dashboard-information`, {params: filter})
        dispatch(beachAdminSlice.actions.setInitialMap(response.data.data.map))
        dispatch(beachAdminSlice.actions.setMap(response.data.data.map))
        dispatch(beachAdminSlice.actions.setReservations(response.data.data.reservations))
        dispatch(beachAdminSlice.actions.setIsLoading(false))

    }
}
export const getBuilderInfo = () => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.get('beach/' + sessionStorage.getItem('beach_id') + '/section-builder')
        dispatch(beachAdminSlice.actions.setItemBackground(response.data.data))
        dispatch(beachAdminSlice.actions.setIsLoading(false))
    }
}

export const checkInReservation = (id, date) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.put('beach/' + sessionStorage.getItem('beach_id') + '/check-in-reservation/' + id + '?date=' + date)
        dispatch(setupBeachInformation(response))
        dispatch(setItemInformation({}, false))
        dispatch(beachAdminSlice.actions.setIsLoading(false))
    }
}

export const checkInReservationFromQr = (beachId, reservationId) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.put('beach/' + beachId + '/check-in-reservation/' + reservationId)

        dispatch(beachAdminSlice.actions.setIsLoading(false))
    }
}
export const confirmReservation = (id, date) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.put('beach/' + sessionStorage.getItem('beach_id') + '/confirm-reservation/' + id + '?date=' + date)
        dispatch(setupBeachInformation(response))
        dispatch(setItemInformation({}, false))

        dispatch(beachAdminSlice.actions.setIsLoading(false))
    }
}

export const cancelReservation = (id, date) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.put('beach/' + sessionStorage.getItem('beach_id') + '/cancel-reservation/' + id + '?date=' + date)
        dispatch(setupBeachInformation(response))
        dispatch(setItemInformation({}, false))

        dispatch(beachAdminSlice.actions.setIsLoading(false))
    }
}

export const setupBeachInformation = (response) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setInitialMap(response.data.data.map))
        dispatch(beachAdminSlice.actions.setMap(response.data.data.map))
        dispatch(beachAdminSlice.actions.setStats(response.data.data.stats))
        dispatch(beachAdminSlice.actions.setReservations(response.data.data.reservations))
        dispatch(setItemInformation({}, false))

    }
}

export const setLoading = (value) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(value))
    }
}


export const setMap = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setMap(data))
    }
}
export const addNewReservation = (value) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setAddNewReservationModal(value))
    }
}
export const openSettingsPage = (value) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setOpenSettingsModal(value))
    }
}
export const openAddSettingModal = (value) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setOpenAddSettingModal(value))
    }
}
export const handleAddWalkIn = (value) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.handleAddWalkIn(value))
    }
}

export const setItemInformation = (data, showModal) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setItemInformation(data))
        dispatch(beachAdminSlice.actions.setShowItemModal(showModal))
    }
}
export const selectItemToAdd = (data, showModal) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setItemInformation(data))
        dispatch(beachAdminSlice.actions.setShowItemModal(showModal))
    }
}

export const modifyMap = (map) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setMap(map))
    }
}

export const addNewCustomReservationAction = (value) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.handleAddCustomReservation(value))
    }
}

export const submitAddWalkin = (data, selectedDate) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/add-walkin-reservation', {
            data: data,
            date: selectedDate
        })
        dispatch(addNewReservation(false))
        dispatch(setupBeachInformation(response))
        dispatch(beachAdminSlice.actions.setIsLoading(false))
    }
}

export const openMapModalAction = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setOpenMapModal(data))
    }
}
export const setAddingWalkinAction = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setAddingWalking(data))
    }
}


export const submitAddReservation = (data, details, selectedDate) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoadingAddingReservation(true))
        const response = await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/add-new-reservation', {
            date: selectedDate,
            data: data, ...details
        })
        dispatch(setupBeachInformation(response))
        dispatch(beachAdminSlice.actions.setIsLoadingAddingReservation(false))

    }
}
export const addSettingAction = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        try {
            const response = await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/add-setting', data)
            dispatch(openAddSettingModal(false))
        } catch (e) {
            dispatch(beachAdminSlice.actions.setIsLoading(false))
            throw e;
        } finally {
            dispatch(beachAdminSlice.actions.setIsLoading(false))
        }
    }
}
export const findUserByEmailAction = (email) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('/find-user', {email: email})
            return response.data.data
        } catch (e) {
            dispatch(beachAdminSlice.actions.setIsLoading(false))
        } finally {
            dispatch(beachAdminSlice.actions.setIsLoading(false))
        }
    }
}

export const searchUsers = (search) => {
    return async (dispatch) => {
        // dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.get('/search-users?search=' + search)
        dispatch(beachAdminSlice.actions.setUsers(response.data.data))
        // dispatch(beachAdminSlice.actions.setIsLoading(false))

    }
}

export const setUsersAction = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setUsers(data))
    }
}

export const handleSwapAction = (item, isSwapping) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsSwapping(isSwapping))
        dispatch(beachAdminSlice.actions.setItemToSwap(item))
        dispatch(beachAdminSlice.actions.setShowItemModal(false))
    }
}

export const swapSeats = (from, to, date) => {
    return async (dispatch) => {
        const response = await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/swap-seats', {
            from_reservation_id: from.reservation_id,
            to_reservation_id: to.reservation_id,
            map_index: to.index,
            date: date
        })
        dispatch(handleSwapAction({}, false))
        dispatch(setupBeachInformation(response))

    }
}

export const getUserBeachInfo = (id, criterias) => {
    return async (dispatch) => {
        let newCriterais = JSON.parse(JSON.stringify(criterias))
        const response = await axios.get('beach/' + sessionStorage.getItem('beach_id') + '/user-info/' + id)
        let selectedCriterias = response.data.data.criterias
        newCriterais.map((item, index) => {
            let found = selectedCriterias.some((criteria) => criteria.id === item.id)
            newCriterais[index].isSelected = found

        })
        dispatch(beachAdminSlice.actions.setUserBeachInfo(response.data.data))
        dispatch(beachAdminSlice.actions.setCriterias(newCriterais))

    }
}

export const getCriterais = () => {
    return async (dispatch) => {
        const response = await axios.get('/beach/' + sessionStorage.getItem('beach_id') + '/criterias')
        dispatch(beachAdminSlice.actions.setCriterias(response.data.data))
    }
}
export const setCriteriasAction = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setCriterias(data))
    }
}
export const setUserBeachInformation = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setUserBeachInfo(data))
    }
}
export const updateUserInfoAction = (userId, criterias, note) => {
    return async (dispatch) => {
        let data = criterias.filter((item) => item.isSelected === true)
        const response = await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/user-info/' + userId, {
            note: note,
            data: data
        })
    }
}
export const getReportInfo = (data) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.get('beach/' + sessionStorage.getItem('beach_id') + '/get-report-info', {params: data})
        dispatch(beachAdminSlice.actions.setReportInfo(response.data.data))
        dispatch(beachAdminSlice.actions.setIsLoading(false))
    }
}

export const exportReport = (start, end) => {
    return async (dispatch) => {
        dispatch(beachAdminSlice.actions.setIsLoading(true))
        const response = await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/export-report-info', {
            from_date: start,
            to_date: end
        }, {
            responseType: 'blob' // Set the response type to blob for file download
        })
        if (response && response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.xlsx');
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        dispatch(beachAdminSlice.actions.setIsLoading(false))

    }
}
export const setShowSwappingMessage = beachAdminSlice.actions.setShowSwappingMessage;

