import { configureStore } from '@reduxjs/toolkit';
import mapReducer from '../store/Map/MapSlice'
import authReducer from './Auth/AuthSlice.ts'
import homeReducer from '../store/Home/HomeSlice'
import homePageReducer from '../store/HomePage/HomePageSlice'
import multipleSelectReducer from '../store/MultipleSelect/MultipleSelectSlice'
import beachAdminReducer from '../store/BeachAdmin/BeachAdminSlice'
import guestReducer from '../store/Guest/GuestSlice'
import reservationsReducer from '../store/Dashboard/Reservations/ReservationsSlice.ts'
import analyticsReducer from '../store/Analytics/AnalyticsSlice.ts'
export const store = configureStore({
  reducer: {
      map: mapReducer,
      auth : authReducer ,
      home: homeReducer,
      homePage:homePageReducer,
      multipleSelect: multipleSelectReducer,
      beachAdmin:beachAdminReducer,
      guest:guestReducer,
      reservations: reservationsReducer,
      analytics: analyticsReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
