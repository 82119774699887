import {Box, Modal, Button} from "@mui/material";
import React, {useState} from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";

export const Logout = ({open, handleClose}) => {

    const navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px',
        outline: 0,
    };
    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        navigate('/login-dashboard')
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <div className="bg-white text-primary-dark-green rounded-md">
                    <CloseIcon
                        className="absolute right-3 top-3 cursor-pointer text-[#B0CBCE]"
                        onClick={handleClose}
                    />
                    <h2 className="text-xl font-bold mb-4 text-center">Log Out</h2>
                    <p className="text-sm text-primary-gray font-archivo-light py-3 text-center">Are you sure you want
                        to log out
                        ?</p>
                    <div className="flex justify-center">
                        <button type="button" className="bg-primary-dark-green text-white rounded-md w-full py-2"
                                onClick={handleLogout}>Log
                            out
                        </button>
                    </div>
                    <div className="text-center font-archivo-light text-sm py-3 cursor-pointer"
                         onClick={handleClose}>Discard
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
export default Logout;
