import {multipleSelectSlice} from "./MultipleSelectSlice";

export const setMultipleSelectedItems = (data) => {
    return (dispatch)=>{
        dispatch(multipleSelectSlice.actions.setMultipleSelectedItems(data))
    }
}
export const setUserMultiSelectingAction = (data) => {
    return (dispatch)=>{
        dispatch(multipleSelectSlice.actions.setUserMultiSelecting(data))
    }
}