import SearchIcon from "@mui/icons-material/Search";
import MapIcon from "@mui/icons-material/Map";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DateRangeIcon from "@mui/icons-material/DateRange";
import React, {useCallback, useEffect, useState} from "react";
import Filters from "../../components/Filters.tsx";
import {guestsFiltersData, filtersLabel} from "../../../../constants/guestsFiltersData.ts";
import {GuestsTableData} from "../../../../constants/tableData.ts";
import Table from "../../components/Table.tsx";
import AssignPointsModal from "./components/AssignPointsModal.tsx";
import axios from "axios";
import {format} from "date-fns";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import ClearIcon from '@mui/icons-material/Clear';
const Guests = () => {

    const dispatch =useDispatch();
    const [rows, setRows] = useState(GuestsTableData.rows);
    const [selectedDate, setSelectedDate] = useState(null);
    const [search, setSearch] = useState(localStorage.getItem('searchInput') || '');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isAssignPointsModalOpen, setIsAssignPointsModalOpen] = useState(false);
    const [guestsFilter, setGuestsFilter] = useState({
        ...guestsFiltersData
    });
    const [tags, setTags] = useState([]);
    const errorMessage = useSelector((state: RootState) => state.reservations.errorMessage);

    const handleEditRow = useCallback(
        ({id, field, value}) => {
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? {...row, [field]: value} : row))
            );
        },
        []
    );
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearch(value);
        localStorage.setItem('searchInput', value);
    };
    const handleClearSearch = () => {
        setSearch('');
        localStorage.removeItem('searchInput');
    };

    const openFilter = () => {
        setIsFilterOpen(!isFilterOpen);
    };
    const toggleFilterSideBar = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const processFilters = (filters) => {
        const params = {};
        if (filters.gender) {
            params.gender = JSON.stringify(filters.gender.map(option => option.toLowerCase()));
        }
        if (filters.age) {
            params.age = filters.age
        }
        if (filters.beach_points) {
            params.points = filters.beach_points
        }
        if (filters.tags) {
            params.tags = JSON.stringify(filters.tags);
        }
        return params;
    };

    const [selectedFilters, setSelectedFilters] = useState({});

    const applyFilters = (filters) => {
        setSelectedFilters(filters);
        const params = processFilters(filters);
        getGuests(params);
    };
    const resetFilters = () => {
        setGuestsFilter({filters: []});
        getGuests();
    };

    const guests = rows.filter(row => {
        return Object.values(row).some(value =>
            String(value).toLowerCase().includes(search.toLowerCase())
        );
    });
    const beachId = sessionStorage.getItem('beach_id');
    const getGuests = async (params = {}) => {
        try {
            const response = await axios.get(`/beach/${beachId}/guests`, {params});
            setRows(response.data.data.guests);

            const tags = response.data.data.tags.map(tag => ({label: tag.label, value: tag.id}));
            setTags(tags);

            const filters = {...guestsFiltersData};
            const tagsFilterIndex = filters.filters.findIndex(filter => filter.category === 'tags');
            if (tagsFilterIndex !== -1) {
                filters.filters[tagsFilterIndex] = {
                    ...filters.filters[tagsFilterIndex],
                    options: tags
                };
            } else {
                filters.filters.push({
                    category: 'tags',
                    type: 'checkbox',
                    options: tags
                });
            }
            setGuestsFilter(filters);
        } catch (error) {
            console.error("Error", error);
        }
    };
    useEffect(() => {
        getGuests();
    }, [beachId]);

    const openAssignPointsModal = () => {
        setIsAssignPointsModalOpen(true);
    }
    const closeAssignPointsModal = () => {
        setIsAssignPointsModalOpen(false);
    }
    // const handleExportExcel = () => {
    //     dispatch(exportExcelGuests(
    //         selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '',
    //         selectedFilters,
    //         search
    //     ));
    // };
    return (
        <div className="container pt-10">
            <p className="text-primary-dark-green font-archivo-bold text-xl pb-4">Guests</p>
            <div className="md:flex justify-content-between items-center">
                <div className="relative my-4">
                    <SearchIcon
                        style={{color: "#F96300"}}
                        className="absolute left-3 top-1/2 transform -translate-y-1/2"
                    />
                    <input
                        type="text"
                        value={search}
                        onChange={handleSearchChange}
                        className="pl-10 pr-10 py-2 border border-gray-300 rounded w-full"
                        placeholder="Search..."
                    />
                    {search && (
                        <button
                            onClick={handleClearSearch}
                            className="absolute right-3 top-3 transform -translate-y-1/2"
                        >
                            <ClearIcon/>
                        </button>
                    )}
                </div>
                <div className="flex gap-3 items-center">
                    <div className="bg-[#F3F7F7] rounded-lg p-2" onClick={openFilter}>
                        <FilterAltIcon className="cursor-pointer #00414A"/>
                    </div>
                    <button type="button" className="text-white rounded-md p-2 bg-primary-dark-green text-sm">Assign
                        Points
                    </button>
                    {/*<button type="button" className="underline text-sm text-primary-orange" onClick={handleExportExcel}>*/}
                    {/*    Export Excel*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="overflow-x-auto">
                <Table
                    columns={GuestsTableData.columns}
                    rows={guests}
                    setRows={setRows}
                    onEditCellChangeCommitted={handleEditRow}
                    pageType="guests"
                    tags={tags}
                />
            </div>
            <Filters
                filterData={{...guestsFilter, open: isFilterOpen, toggleDrawer: toggleFilterSideBar}}
                onApply={applyFilters}
                onReset={resetFilters}
                filtersLabel={filtersLabel}
            />
            <AssignPointsModal
                isOpen={isAssignPointsModalOpen}
                onClose={closeAssignPointsModal}/>
        </div>
    )
}
export default Guests;
