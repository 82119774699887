import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {checkIfWeekend} from "../../../util";
import LoaderComp from "../../../components/LoaderComp";
import {Backdrop, Checkbox, CircularProgress, Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";
import {Link} from "react-router-dom";

const StripeForm = () => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const {bagInformation, subTotalBag, totalBag, isLoading} = useSelector((state) => state.auth)
    const {uniqueBag, subTotal, total} = useSelector((state) => state.guest)
    const [totalItems, setTotalItems] = useState(0);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (bagInformation && bagInformation.length > 0) {
            let totalPrice = 0;
            bagInformation.forEach((bagItem) => {
                bagItem.items.forEach((item) => {
                    if (item.count - (item.split_with_count ?? 0) !== 0) {
                        totalPrice += (item.count - (item.split_with_count ?? 0)) *
                            (checkIfWeekend(item.selected_date) ?
                                Number(item.beach_item.pivot.weekend_price) :
                                Number(item.beach_item.pivot.weekday_price));
                    }
                });
            });
            setTotalItems(totalPrice);
        }
    }, [bagInformation]);

    const [isProcessing, setIsProcessing] = useState(false)
    const [errorStripe, setErrorStripe] = useState('')

    const handleCloseSnackBar = () => {
        setErrorStripe('')
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true)
        sessionStorage.removeItem('beach_url_key')
        sessionStorage.removeItem('is_guest')
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: process.env.REACT_APP_FRONT_END + "/order-complete",
            },
        });

        if (result.error) {
            setIsProcessing(false)
            setErrorStripe(result.error.message)
        } else {
        }
    };
    const includedFees = () => {
        let totalFees = 0;
        bagInformation.forEach(bagItem => {
            const beachBedFees = Number(bagItem.beach.beach_bed_fees);
            bagItem.items.forEach(item => {
                totalFees += (item.count - (item.split_with_count ?? 0)) * beachBedFees;
            });
        });
        return totalFees;
    };

    useEffect(() => {
        if (bagInformation && bagInformation.length > 0) {
            console.log('Bag Information111:', bagInformation);
        }
    }, [bagInformation]);

    return (
        <div className={"md:flex md:items-center"}>
            <div className="col-md-6 mt-5">
                <PaymentElement/>
            </div>
            {
                (bagInformation && bagInformation.length > 0) &&
                <>
                    <div className="col-md-6">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="shadow-3xl rounded-2xl text-primary-dark-green pt-3">
                                    <div className="mx-4">
                                        {
                                            bagInformation.map((bagItem) => (
                                                bagItem.reservation_type === 'default' ?
                                                    <>
                                                        <div className="mt-3">
                                                            <div
                                                                className="text-white mt-3 py-1">
                                                                <p className="mt-3 px-3 py-2 text-center rounded-full text-primary-dark-green">  {moment(bagItem.selected_date).format("dddd MMMM, DD")}</p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3">
                                                            <div className="flex justify-between">
                                                                <div className="flex gap-2 items-center">
                                                                    <img src="/svgs/beach.svg"/>
                                                                    {bagItem.beach.name}
                                                                </div>
                                                                <p className={"m-0"}>
                                                                    Price: {(bagItem.count - (bagItem.splittedNumber ?? 0)) * (checkIfWeekend(bagItem.selected_date) ? Number(bagItem.beach_item.pivot.weekend_price) : Number(bagItem.beach_item.pivot.weekday_price))}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className={"mt-3 text-md flex items-center justify-between"}>
                                                                <div className="flex items-center gap-2">
                                                                    <img src="/svgs/seats.svg"/>
                                                                    {bagItem.item.name}
                                                                </div>
                                                                <p>
                                                                    Qty:{bagItem.count - (bagItem.splittedNumber ?? 0)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className={"divider "}/>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="row mt-3 justify-content-center">
                                                            <p className="font-bold text-xl">Payment Information</p>
                                                            <div className="flex items-center pt-3 gap-1">
                                                                <img
                                                                    src="/svgs/calendar-check.svg"/> {moment(bagItem.selected_date).format("dddd MMMM, DD")}
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className="">
                                                                <div className="flex items-center gap-1">
                                                                    <img src="/svgs/bed-icon.svg"/> {bagItem.beach.name}
                                                                    {/*<p className={"m-0"} style={{fontSize: '14px'}}>*/}
                                                                    {/*    <b>Day Pass </b>({bagItem.count})*/}
                                                                    {/*</p>*/}
                                                                </div>
                                                                {/*<div className="col-md-4 d-flex justify-content-end">*/}
                                                                {/*    <p className={"m-0"}>*/}
                                                                {/*        {bagItem.count * bagItem.beach.day_pass_price}*/}
                                                                {/*    </p>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </>

                                            ))
                                        }
                                        {bagInformation && bagInformation.map((bag, index) => (
                                            <div key={index}>
                                                <div className="">
                                                    {bag.items.map((item, itemIndex) => (
                                                        item.count - (item.split_with_count) !== 0 && (
                                                            <div key={itemIndex}>
                                                                <div
                                                                    className="text-primary-dark-green my-4 rounded py-2">
                                                                    <div
                                                                        className="overflow-x-auto flex items-center max-md:grid max-md:grid-cols-[2fr_1fr_1fr] max-md:items-center max-md:gap-2 md:gap-3 max-md:text-sm">
                                                                        <div className="md:flex items-center">
                                                                            <span
                                                                                className="bg-primary-orange rounded-full w-2 h-2"></span>
                                                                            {/*<img src={item.item.image} className="w-10 h-10"/>*/}
                                                                            <p className="px-1">{item.item.name}</p>
                                                                        </div>
                                                                        <p className="flex items-center"> x{item.count - item.split_with_count}</p>
                                                                        <p> ${checkIfWeekend(item.selected_date) ? Number(item.beach_item.pivot.weekend_price) : Number(item.beach_item.pivot.weekday_price)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                        {/*<div className="mt-3">*/}
                                        {/*    <div className="d-flex">*/}
                                        {/*        <div className="col-8">*/}
                                        {/*            <b>Subtotal</b>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="col-4 d-flex justify-content-end">*/}
                                        {/*            $ {subTotalBag}*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div className="col-8">
                                                    <b>VAT</b>
                                                </div>
                                                <div className="col-4 d-flex justify-content-end">
                                                    --
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div className="mb-3 d-flex">
                                                <div className="col-8">
                                                    <b>Total</b>
                                                </div>
                                                <div className="col-4 d-flex justify-content-end">
                                                    $ {totalItems.toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div className="d-flex">
                                                <div className="col-8">
                                                    <p className="pr-2 text-lg text-red-500">Included Fees*</p>
                                                </div>
                                                <div className="col-4 d-flex justify-content-end font-archivo-bold">
                                                    $ {includedFees().toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <Link to="/terms-conditions"
                                                  className="text-sm text-primary-dark-green m-0"
                                                  target="_blank">
                                                Terms and conditions
                                            </Link>
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                                sx={{color: '#B0CBCE', '&.Mui-checked': {color: '#F96300'}}}
                                            />
                                        </div>
                                        <div className="row mt-3  justify-content-center">
                                            <div className="mb-3 flex justify-center">
                                                <button onClick={handleSubmit} disabled={!isChecked}
                                                        className={`bg-primary-orange text-white px-10 py-2 rounded-full flex items-center ${!isChecked ? 'cursor-not-allowed opacity-50' :''}`}>
                                                    Pay Now
                                                    {isProcessing &&
                                                        <CircularProgress size={24} style={{color: '#FFFFFF'}}
                                                                          className="ml-2"/>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                (uniqueBag && uniqueBag.length > 0) &&
                <>
                    <div className="col-md-6 pb-3">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="total-card">
                                    <div className="container">
                                        {
                                            uniqueBag.map((bagItem) => (
                                                bagItem.reservation_type === 'default' ?
                                                    <>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className="col-md-10 mt-3 text-center date-text">
                                                                <b>
                                                                    {moment(bagItem.selected_date).format("dddd MMMM, DD")}
                                                                </b>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className="col-md-10 d-flex">
                                                                <div className="col-md-8">
                                                                    {bagItem.beach.name}
                                                                    <p className={"m-0"} style={{fontSize: '14px'}}>
                                                                        {bagItem.item.name} (
                                                                        {bagItem.count - (bagItem.splittedNumber ?? 0)})
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4 d-flex justify-content-end">
                                                                    <p className={"m-0"}>
                                                                        {(bagItem.count - (bagItem.splittedNumber ?? 0)) * (checkIfWeekend(bagItem.selected_date) ? Number(bagItem.beach_item.pivot.weekend_price) : Number(bagItem.beach_item.pivot.weekday_price))}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className="col-md-10">
                                                                <div className={"divider "}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className="col-md-10 mt-3 date-text">
                                                                <b>
                                                                    {moment(bagItem.selected_date).format("dddd MMMM, DD")}
                                                                </b>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className="col-md-10 d-flex">
                                                                <div className="col-md-8">
                                                                    {bagItem.beach.name}
                                                                    <p className={"m-0"} style={{fontSize: '14px'}}>
                                                                        <b>Day Pass </b>({bagItem.count})
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-4 d-flex justify-content-end">
                                                                    <p className={"m-0"}>
                                                                        {bagItem.count * bagItem.beach.day_pass_price}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3 justify-content-center">
                                                            <div className="col-md-10">
                                                                <div className={"divider "}/>
                                                            </div>
                                                        </div>
                                                    </>

                                            ))
                                        }
                                        <div className="row justify-content-center mt-3">
                                            <div className="col-md-10 d-flex">
                                                <div className="col-8">
                                                    <b>Subtotal</b>
                                                </div>
                                                <div className="col-4 d-flex justify-content-end">
                                                    $ {subTotal}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-3">
                                            <div className="col-md-10 d-flex">
                                                <div className="col-8">
                                                    <b>VAT</b>
                                                </div>
                                                <div className="col-4 d-flex justify-content-end">
                                                    --
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3  justify-content-center">
                                            <div className="col-md-10 mb-3 d-flex">
                                                <div className="col-8">
                                                    <b>Total</b>
                                                </div>
                                                <div className="col-4 d-flex justify-content-end">
                                                    $ {totalItems.toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3  justify-content-center">
                                            <div className="col-md-10 mb-3 d-flex">
                                                <button onClick={handleSubmit}
                                                        className={"bg-primary-orange text-white px-10 py-2 rounded-full flex items-center"}>
                                                    Pay Now
                                                    {isProcessing &&
                                                        <CircularProgress size={24} style={{color: '#FFFFFF'}}
                                                                          className="ml-2"/>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {errorStripe && (
                <Snackbar open={true} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                    <Alert onClose={handleCloseSnackBar} severity="error">
                        {errorStripe}
                    </Alert>
                </Snackbar>
            )}
            {/*<Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={!!errorStripe} autoHideDuration={6000}*/}
            {/*          onClose={handleCloseSnackBar}>*/}
            {/*    <Alert onClose={handleCloseSnackBar} severity="error" sx={{width: '100%'}}>*/}
            {/*        {errorStripe}*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </div>
    )
}
export default StripeForm
