import {Box, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    openChangeNumberModalAction,
    setCurrentSectionAction,
    setSavedSectionsAction, setUndoData
} from "../../../store/Map/MapActions";

const ItemInformation = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius:'15px',
        outline:0,

    };
    const {
        openChangeNumberModal,selectedItem , savedSections , selectedItemIndex
    } = useSelector((state) => state.map)
    const dispatch = useDispatch()
    const [changingNumber , setChangingNumber] = useState(false)
    const [number , setNumber] = useState(selectedItem.item_number)

    const handleClose = () =>{
        dispatch(openChangeNumberModalAction( false, {} , null));
    }
    const saveNumber = () =>{
        let data = [...savedSections]
        data[selectedItemIndex] = {...data[selectedItemIndex] , item_number: number }
        dispatch(setSavedSectionsAction(data))
        handleClose()
    }

    const deleteItem = () => {
        dispatch(setCurrentSectionAction(null))
        let array = [...savedSections];
        array.splice(selectedItemIndex, 1);
        dispatch(setSavedSectionsAction(array))
        dispatch(setUndoData(array))
        handleClose()
    }
    return (
        <Modal
            open={openChangeNumberModal}
            onClose={handleClose}>
            <Box sx={style}>
                <div style={{background : '#005662' , color:'white' , borderTopLeftRadius:'10px' , borderTopRightRadius:'10px'}}>
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-12 mt-1">
                                <div className="float-end">
                                    <CloseIcon onClick={handleClose} style={{cursor:'pointer'}}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h3 className={"mt-1 mb-3"}>{selectedItem.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    changingNumber  ?
                        <div className={"container"}>
                            <div className="row mb-4 justify-content-center mt-4">
                                <div className="col-md-10">
                                        <label>Set Item Number</label>
                                        <input value={number} onChange={(e)=>setNumber(e.target.value)} className={"form-control mt-2"}  type={"text"}/>
                                </div>
                            </div>
                            <div className="row  justify-content-center mt-4 mb-4 ">
                                <button onClick={saveNumber} className={"primary-button mt-1 col-10"}>Save Number</button>
                            </div>
                        </div>
                        :
                        <div className="container">
                            <div className="row justify-content-center mt-4">
                                <div onClick={()=>setChangingNumber(true)}   className="col-md-10  d-flex justify-content-center align-items-center " style={{background:'#a0e5b5' , color:'#005662' ,borderRadius:'18px' ,cursor:'pointer' }}>
                                    <p className={" mt-2 mb-2 ms-2 m-0"}><b>Change Item Number</b></p>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-4 mb-4">
                                <div onClick={deleteItem}   className="col-md-10  d-flex justify-content-center align-items-center " style={{background:'#f78697' , color:'white' ,borderRadius:'18px' ,cursor:'pointer' }}>
                                    <p className={" mt-2 mb-2 ms-2 m-0"}><b>Delete Item</b></p>
                                </div>
                            </div>
                        </div>

                }


            </Box>

        </Modal>
    )
}
export default ItemInformation