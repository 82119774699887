import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    addToBag, getBeachIdByKey,
    getBeachInfoById,
    getBuilderInfo,
    getMapByBeachId, handleSelectedItems,
    setMap,
    setSelectedItems
} from "../../store/Home/HomeActions";
import '../SelectSeats/style.css'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate, useSearchParams} from "react-router-dom";
import DisabledItem from "../../components/Item/DisabledItem";
import DisabledSection from "../../components/DisabledSection";
import {setLoading} from "../../store/Map/MapActions";
import moment from "moment";
import {checkIfWeekend} from "../../util";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {Backdrop, Box, CircularProgress} from "@mui/material";
import CheckoutModal from "../SelectSeats/CheckoutModal";
import {setGuestBagAction, setGuestOriginalItems, setGuestTotalValues} from "../../store/Guest/GuestActions";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TextField} from '@mui/material';
import dayjs from 'dayjs';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Space} from "react-zoomable-ui";
import SwipeIcon from "@mui/icons-material/Swipe";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";


const customTheme = createTheme({
    components: {
        typography: {
            fontFamily: 'archivo-regular',
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontFamily: 'archivo-regular',
                    color: "#00414A",
                    '&.Mui-selected': {
                        backgroundColor: '#00414A',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#00414A',
                        }
                    },
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                label: {
                    fontFamily: 'archivo-regular',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: '#00414A',
                        backgroundColor: 'white',
                        fontFamily: 'archivo-regular',
                        fontWeight: 'bold',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white',
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'white',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                        '& .MuiInputBase-input': {
                            backgroundColor: 'white',
                        },
                    },
                },
            },
        },
    },
});

export const GuestCheckoutFlow = () => {
    const [date, setDate] = useState()
    const {
        selectedBeach,
        map,
        mapScreenWidth,
        mapScreenHeight,
        initialMap,
        totalItems,
        totalAmount,
        dayPassPrice,
        selectedItems,
        isFull
    } = useSelector((state) => state.home)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dayPass, setDayPass] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeStep, setActiveStep] = useState(0);
    const [isMobileScreen, setIsMobileScreen] = useState(false)
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const [showCheckoutModal, setShowCheckoutModal] = useState(false)

    useEffect(() => {
        dispatch(getBeachIdByKey(searchParams.get('key'))).then(() => {
            dispatch(getBeachInfoById())
            dispatch(getBuilderInfo())
            let initialDate = moment().format('YYYY-MM-DD')
            dispatch(getMapByBeachId(initialDate))
        })
    }, [])
    const spaceRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (date) {
            dispatch(getMapByBeachId(moment(date, 'dddd D MMMM YYYY').format('YYYY-MM-DD')))
        }
    }, [date])


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialMap]);

    useEffect(() => {
        handleResize()
    }, [initialMap])

    useEffect(() => {
        let selectedItems = []
        let totalItems = 0
        let totalAmount = 0
        let isWeekend = checkIfWeekend(date)
        initialMap.map((item, index) => {
            if (item.is_selected) {
                selectedItems.push({
                    ...item,
                    map_index: index,
                    date: moment(date, 'dddd D MMMM YYYY').format('YYYY-MM-DD')
                })
                totalItems = totalItems + 1
                totalAmount = totalAmount + (isWeekend ? Number(item.pivot.weekend_price) : Number(item.pivot.weekday_price))
            }
        })
        dispatch(handleSelectedItems(selectedItems, totalItems, totalAmount))
    }, [initialMap])


    const handleDateChanged = (newValue) => {
        if (newValue) {
            const formattedDate = newValue.format('dddd D MMMM YYYY');
            setDate(formattedDate);
            setActiveStep(1);
        } else {
            setDate('');
        }
    };
    const handleResize = () => {
        let currentMapSize = window.innerWidth * (10 / 12);
        // 0.8 is a number calculated
        let ratio = (mapScreenWidth / (currentMapSize - 40)) / 0.8;
        // 140 is the margin block
        // 20 is the border
        let currentMapSizeHeight = window.innerHeight - (140 + 20);
        let heightRatio = mapScreenHeight / currentMapSizeHeight;


        let data = initialMap?.map(section => ({
            ...section,
            startX: section.startX / ratio,
            endX: section.endX / ratio,
            startY: section.startY / heightRatio,
            endY: section.endY / heightRatio,
            height:  (section.special_height ?? 50)  / heightRatio + 'px',
            width:  (section.special_width ?? 50)  / heightRatio + 'px'
        }));
        dispatch(setMap(data));
        dispatch(setLoading(false));

    }

    function handleBack() {
        navigate('/beach-info')
    }

    function groupItems(items) {
        let itemMap = new Map();

        items.forEach(item => {
            const itemId = item.id;
            if (itemMap.has(itemId)) {
                itemMap.get(itemId).count++;
            } else {
                itemMap.set(itemId, {
                    ...item,
                    count: 1,
                    date: moment(date, 'dddd D MMMM YYYY').format('YYYY-MM-DD'),
                    beach_name: selectedBeach.name,
                    beach_image: selectedBeach.thumbnail_image,
                    beach_id: selectedBeach.id
                });
            }
        });

        return Array.from(itemMap.values());
    }

    const initViewPort = async (vp) => {
        vp.setBounds({x: [0, mapScreenWidth], y: [0, mapScreenHeight]});
        vp.camera.centerFitAreaIntoView({
            left: 0,
            top: 0,
            width: mapScreenWidth,
            height: mapScreenHeight
        });
        // vp.containerHeight = 300
    };

    const bookSeats = () => {
        if (!selectedItems || selectedItems.length === 0) {
            return;
        }
        const groupedItems = groupItems(selectedItems);
        dispatch(setGuestBagAction(groupedItems));
        dispatch(setGuestOriginalItems(selectedItems));
        setShowCheckoutModal(true);
    }

    const addDayPass = () => {
        let data = [
            {
                beach_id: selectedBeach.id,
                beach_name: selectedBeach.name,
                beach_image: selectedBeach.thumbnail_image,
                type: 'day_pass',
                date: date,
                count: dayPass,
                day_pass_price: dayPassPrice,
            }
        ]
    }

    const handleBackStepper = () => {
        setDate("")
        setActiveStep(0)
    }

    return (
        <div className="background-image-fill md:h-[100vh]" style={{backgroundImage: `url('${selectedBeach.background_image}')`}}>
            <div className="background-overlay-seats md:h-[100%] max-md:h-full">
                <div className="container-fluid">
                    <div className="row h-100 max-md:py-8 max-md:px-3">
                        <div
                            className="col-md-4 col-lg-4 col-12 p-0 md:grid md:h-[100%] md:justify-center md:items-center">
                            {
                                <div className="grid">
                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        <Step>
                                            <StepLabel>
                                                <h4 style={{color: "white"}}>Select Your Date</h4>
                                            </StepLabel>
                                            {date && (
                                                <div
                                                    onClick={handleBackStepper}
                                                    className={"mt-2 text-primary-dark-green bg-white font-bold text-sm rounded-full mx-8 px-3 py-2 cursor-pointer"}>
                                                    <b>{date}</b>
                                                </div>
                                            )}
                                            <StepContent>
                                                {/*<div>*/}
                                                {/*    <input onChange={handleDateChanged}*/}
                                                {/*           min={today} placeholder={"Date"}*/}
                                                {/*           value={moment(date,'dddd D MMMM YYYY').format('YYYY-MM-DD')} type={"date"}*/}
                                                {/*           className={"mt-2 date-input"} />*/}
                                                {/*</div>*/}
                                                <ThemeProvider theme={customTheme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={date ? dayjs(date, 'YYYY-MM-DD') : null}
                                                            onAccept={handleDateChanged}
                                                            views={['year', 'month', 'day']}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            minDate={dayjs(today)}
                                                            inputFormat="YYYY-MM-DD"
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider>
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                <h4 style={{color: "white"}}>Select Your Seats</h4>
                                            </StepLabel>
                                            <StepContent>

                                                {
                                                    isFull ?
                                                        <h1>No available seats for:</h1>
                                                        :
                                                        <>
                                                            <div>
                                                                Select Your Seats for the
                                                            </div>
                                                            <div>
                                                                following date:
                                                            </div>
                                                        </>

                                                }

                                                {
                                                    totalItems > 0 &&
                                                    <>
                                                        <ul className="selected-items-list mt-3">
                                                            {groupItems(selectedItems).map((item, index) => (
                                                                <li key={index}
                                                                    className="flex gap-2 text-white text-md max-md:text-sm py-1">
                                                                    <span>{item.count}x</span>
                                                                    <span>{item.name}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <div onClick={bookSeats}
                                                             className={"flex cursor-pointer items-center justify-between mt-3 bg-primary-orange hover:bg-primary-dark-green  border-white border-1  rounded-full px-4 py-2"}>
                                                            <div
                                                                className="flex items-center gap-1"
                                                            >
                                                                <p> Book Now</p>
                                                                <p>({totalItems})</p>
                                                            </div>
                                                            <p className="mt-1"> $ {totalAmount}</p>
                                                        </div>
                                                    </>
                                                }
                                                <Box sx={{mb: 2, mt: 3}}>
                                                    <div>
                                                        <b style={{cursor: 'pointer'}} onClick={handleBackStepper}>
                                                            <ArrowBackIcon/>
                                                            <span className={"ms-2"}>Back</span>
                                                        </b>
                                                    </div>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                    </Stepper>
                                </div>

                            }
                        </div>
                        <div className="col-md-8 col-lg-8 col-12 p-0">
                            {
                                !isMobileScreen ?
                                    <div className="map-preview">
                                        <div
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                border: '1px solid black',
                                                position: 'relative',
                                                opacity: (!date || isFull) ? '0.3' : ''
                                            }}>
                                            {

                                                map?.map((section, index) => (
                                                    section.type === 'ITEM' ?
                                                        (
                                                            <DisabledItem activeStep={activeStep} date={date}
                                                                          key={'item_disabled_dragged_' + index}
                                                                          index={index}
                                                                          item={section}/>
                                                        )
                                                        : (
                                                            <DisabledSection key={'section_disabled_dragged_' + index}
                                                                             section={section} index={index}/>
                                                        )
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="map-preview-mobile">
                                        {
                                            (initialMap && initialMap.length > 0) ?
                                                <>
                                                    <div className={"text-black ms-2 mt-2 text-xs"}>
                                                        Use your fingers to navigate the map. <SwipeIcon/>
                                                        <div className={"flex flex-row items-center text-black mt-2"}>
                                                            {/*<p className={""}>+</p>*/}
                                                            {/*<p className={""}>-</p>*/}
                                                            {/*/!*<ZoomInIcon className={"ms- cursor-pointer"} onClick={() => spaceRef.current?.viewPort?.camera.moveBy(0, 0, 0.1)} />*!/*/}
                                                            {/*<ZoomOutIcon className={"ms-2 cursor-pointer"} onClick={() => spaceRef.current?.viewPort?.camera.moveBy(0, 0, -0.1)} />*/}
                                                            {/* <ArrowUpwardIcon  onClick={() =>*/}
                                                            {/*     spaceRef.current?.viewPort?.camera.moveBy(0, -20, 0, undefined, undefined, {*/}
                                                            {/*         durationMilliseconds: 500,*/}
                                                            {/*     })*/}
                                                            {/* } />*/}
                                                            {/*<ArrowBackIcon  onClick={() =>*/}
                                                            {/*    spaceRef.current?.viewPort?.camera.moveBy(-20, 0, 0, undefined, undefined, {*/}
                                                            {/*        durationMilliseconds: 500,*/}
                                                            {/*    })*/}
                                                            {/*} />*/}
                                                        </div>
                                                    </div>
                                                    <Space
                                                        onCreate={initViewPort}
                                                        ref={spaceRef}
                                                        style={{
                                                            height: '380px',
                                                            overflowY: "scroll",
                                                            overflowX: "scroll",
                                                            border: '1px solid black',
                                                            position: 'relative',
                                                            opacity: (!date || isFull) ? '0.3' : ''
                                                        }}>
                                                        {
                                                            initialMap?.map((section, index) => (
                                                                section.type === 'ITEM' ?
                                                                    (
                                                                        <DisabledItem activeStep={activeStep}
                                                                                      date={date}
                                                                                      key={'item_disabled_dragged_' + index}
                                                                                      index={index}
                                                                                      item={section}/>
                                                                    )
                                                                    : (
                                                                        <DisabledSection
                                                                            key={'section_disabled_dragged_' + index}
                                                                            section={section} index={index}/>
                                                                    )
                                                            ))
                                                        }
                                                    </Space>
                                                </>
                                                :
                                                <Backdrop
                                                    open={true}
                                                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                                                    <CircularProgress color="inherit"/>
                                                </Backdrop>

                                        }

                                    </div>

                            }

                        </div>
                    </div>
                </div>
            </div>
            {
                showCheckoutModal &&
                <CheckoutModal open={showCheckoutModal} handleClose={() => setShowCheckoutModal(false)}/>
            }
        </div>
    )
}
export default GuestCheckoutFlow
