export const filtersLabel = {
    reservation_types: {
        default: 'Pre Booked',
        walk_in: 'Walk-In',
        //day_pass: 'Day Pass'
    },
    reservation_status: {
        checked_in: 'Checked In',
        reserved: 'Reserved',
        canceled: 'Canceled',
        reservation_confirmed: 'Confirmed'
    }
};
