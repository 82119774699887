import axios from 'axios';
import { setIsLoading, setAnalyticsData, setError } from './AnalyticsSlice.ts';

export const getAnalytics = ({ start_date = '', end_date = '' }) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        let beachId = sessionStorage.getItem('beach_id');
        const response = await axios.get(`/beach/${beachId}/analytics`, {
            params: { start_date, end_date },
        });
        if (response.data.success) {
            dispatch(setAnalyticsData(response.data.data));
        } else {
            dispatch(setError('Failed to fetch analytics data.'));
        }
    } catch (error) {
        dispatch(setError(error.message));
    } finally {
        dispatch(setIsLoading(false));
    }
};
