import {createSlice} from "@reduxjs/toolkit";
export const initialState = {
    selectedArea: null,
    allBeaches:[],
    selectedSectionIndex: null,
    optionSelected: 'move',
    currentSection: null,
    mapScreenWidth: 1920,
    mapScreenHeight: 970,
    currentScreen: null,
    isDraggingInside: false,
    isOver: false,
    plans:[],
    initialSavedSections:[],
    selectedPlanId:1,
    savedSections: [],
    itemsToDrop: [],
    sectionCategories:[],
    itemBackground:[],
    isLoading: false,
    error: null,
    autoGenerateModal:false,
    autoGenerateItem:{},
    watchedSavedSections: [],
    currentIndexMap : 0,
    undoIndexArray:[],
    redoIndexArray:[],
    selectedItem:{},
    selectedItemIndex:null ,
    openChangeNumberModal:false,
    isCapturingImage:false,
    itemToCapture:{},
    itemToCaptureIndex:null,
    showRotateModal:false,
    showRotateModalItem:{},
    showRotateModalItemIndex:null

}
export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setSelectedArea(state, action) {
            state.selectedArea = action.payload
        },
        setSelectedSectionIndex(state, action) {
            state.selectedSectionIndex = action.payload
        },
        setOptionSelected(state, action) {
            state.optionSelected = action.payload
        },
        setCurrentSection(state, action) {
            state.currentSection = action.payload
        },
        setSavedSections(state, action) {
            state.savedSections = action.payload
        },
        setShowRotateModal(state, action) {
            state.showRotateModal = action.payload
        },
        setShowRotateModalItem(state, action) {
            state.showRotateModalItem = action.payload
        },
        setShowRotateModalItemIndex(state, action) {
            state.showRotateModalItemIndex = action.payload
        },
        setInitialSavedSections(state, action) {
            state.initialSavedSections = action.payload
        },
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        setItemIsOver(state, action) {
            state.isOver = action.payload
        },
        setItemIsDraggingInside(state, action) {
            state.isDraggingInside = action.payload
        },
        setSectionBuilder(state , action) {
            state.itemsToDrop = action.payload.items?.map((item)=>({
                ...item,
                isReady:true,
                type: 'ITEM'
            }))
            state.itemsToDrop.unshift({
                name:'Image',
                isCaptureImage:true,
                can_be_reserved:0,
                type:'ITEM',
                is_a_seat:0,
                image:'capture.svg'
            })
            state.sectionCategories = action.payload.section_categories

            let itemBackgroundObject = {}
            action.payload.section_categories.map((item)=>{
                itemBackgroundObject[item.code] = item.background_image
            })
            state.itemBackground = itemBackgroundObject
        },
        setIsLoading(state , action){
            state.isLoading = action.payload
        },
        setCaptureImageSection(state,action){
          state.isCapturingImage = action.payload
        },
        setItemCapture(state,action){
            state.itemToCapture = action.payload
        },
        setItemCaptureIndex(state,action){
            state.itemToCaptureIndex = action.payload
        },
        setMapScreenWidth(state , action){
            state.mapScreenWidth = action.payload
        },
        setMapScreenHeight(state , action){
            state.mapScreenHeight = action.payload
        },
        setAllBeaches(state , action){
            state.allBeaches = action.payload
        },
        setAutoGenerateItem(state , action){
            state.autoGenerateItem = action.payload
        },
        setAutoGenerateModal(state , action){
            state.autoGenerateModal = action.payload
        },
        setOpenChangeNumberModal(state,action){
            state.openChangeNumberModal = action.payload
        },
        setSelectedItem(state,action){
            state.selectedItem = action.payload
        },
        setSelectedItemIndex(state,action){
            state.selectedItemIndex = action.payload
        },
        setFirstUndoData(state , action){
            state.watchedSavedSections = [action.payload]
        },

        setWatchedSavedSections(state , action){
            state.watchedSavedSections.push(action.payload)
            state.undoIndexArray = [...state.undoIndexArray , state.currentIndexMap]
            state.currentIndexMap= state.watchedSavedSections.length - 1
            state.redoIndexArray = []
        },


        handleUndo(state){
            if(state.undoIndexArray.length > 0){
                const newArray = state.undoIndexArray.slice(0, -1);
                state.redoIndexArray = [...state.redoIndexArray , state.currentIndexMap]
                state.currentIndexMap = state.undoIndexArray[state.undoIndexArray.length -1]
                state.undoIndexArray = newArray
                state.savedSections = state.watchedSavedSections[state.currentIndexMap]
            }

        },
        handleRedo(state){
            if(state.redoIndexArray.length > 0){
                const newArray = state.redoIndexArray.slice(0, -1);
                state.undoIndexArray = [...state.undoIndexArray , state.currentIndexMap]
                state.currentIndexMap = state.redoIndexArray[state.redoIndexArray.length -1]
                state.redoIndexArray = newArray
                state.savedSections = state.watchedSavedSections[state.currentIndexMap]
            }
        }


    }
})

export default mapSlice.reducer;
