import "./style.css";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Formik, Field, ErrorMessage} from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import {useDispatch, useSelector} from "react-redux";
import {getProfileInformation, updatePassword, updateProfileInfo} from "../../store/Auth/AuthActions.ts";
import LoaderComp from "../../components/LoaderComp";
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";
import SmallBanner from "../../components/SmallBanner";
import {smallPageBanner} from "../../constants/homeData";
import Footer from "../../components/Footer";

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import {createTheme,ThemeProvider} from "@mui/material/styles";

const customTheme = createTheme({
    typography: {
        fontFamily: "archivo-regular",
        color: "#005662 !important"
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily:'archivo-regular',
                    width: '100%',
                    '& .MuiFormLabel-root ':{
                        color:'#005662 !important',
                        borderColor: '#005662 !important',
                        fontSize:'17px',
                        top:'-8%',
                        '& .MuiFormLabel-root ':{
                            color:'#005662',
                            '&.Mui-focused': {
                                color: '#005662',
                                borderColor: '#005662',
                            }
                        }
                    },
                    '& .MuiInputBase-root': {
                        color: 'white',
                        backgroundColor: 'rgba(177, 203, 207,0.95)',
                        borderRadius:'50px',
                        '& .MuiInputBase-input': {
                            color: 'white',
                            backgroundColor: 'rgba(177, 203, 207, 0.28)',
                            borderRadius:'50px',
                            fontFamily:'archivo-regular',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#005662 !important',
                        },
                        '&:hover fieldset': {
                            borderColor: '#005662',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#005662',
                        },
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(177, 203, 207, 0.28)',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'rgba(177, 203, 207, 0.28)',
                            borderColor:'#005662'
                        },
                    },
                },
            },
        },
    },
});

export default function EditAccount() {
    const navigate = useNavigate();
    const dispatch= useDispatch()
    const {
        profileInfo,isLoading,errorUpdatePassword
    } = useSelector((state) => state.auth)
    const [showSuccess , setShowSuccess]=useState(false)
    const [accountInfo, setAccountInfo] = useState({
        first_name: profileInfo.first_name,
        last_name: profileInfo.last_name,
        email: profileInfo.email,
        phone_number: profileInfo.phone_number,
        gender: profileInfo.gender,
        date_of_birth: profileInfo.date_of_birth,
        changePassword: false,
        old_password: "",
        password: "",
        password_confirmation: "",
    });
    const saveSchema = yup.object().shape({
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        gender: yup.string().required("Gender is required"),
        date_of_birth: yup.date().required("Date of birth is required"),
        old_password: accountInfo.changePassword && yup.string().required("Old Password is required"),
        password: accountInfo.changePassword &&  yup
            .string()
            .required("New password is required")
            .min(8, "Password must be 8 characters at minimum")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                "Password must contain at least one special character"
            ),
        password_confirmation: accountInfo.changePassword &&  yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm new password is required"),
    });


    useEffect(() => {
        dispatch(getProfileInformation())
    }, []);

    useEffect(() => {
        setAccountInfo({...profileInfo} ,
            {changePassword: false,
                old_password: "",
                password: "",
                password_confirmation: "",})
    }, [profileInfo]);

    function goBack() {
        navigate(-1);
    }
    const handleCloseSnackBar = () =>{
        setShowSuccess(false)
    }
    function updateProfile(values){
        dispatch(updateProfileInfo(values))
        if(accountInfo.changePassword){
            dispatch(updatePassword(values))
        }
        setShowSuccess(true)
        navigate('/')
    }

    function passwordChange() {
        setAccountInfo((prev) => ({
            ...prev,
            changePassword: !accountInfo.changePassword,
        }));
    }

    return (
        <>
            <Header hasBackground={true} />
            <SmallBanner data={smallPageBanner}/>
            {
                !isLoading ?
                    <div  className="container-fluid px-lg-5 px-md-5">
                        <div className="mt-3">
                            <div className="">
                                <h2 className="text-2xl max-md:text-xl text-primary-dark-green">
                                    <button onClick={goBack} className={"me-1"}>
                                        <ArrowBackIcon style={{color:'#005662' , cursor:'pointer'}}/>
                                    </button>
                                    Edit Account Information</h2>
                                <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                            </div>
                        </div>
                        {
                            errorUpdatePassword &&
                            <div className="row w-100 justify-content-center mt-2">
                                <div className="col-md-6">
                                    <div className="alert alert-danger text-center py-2" role="alert">
                                        Incorrect Old Password
                                    </div>
                                </div>
                            </div>
                        }
                        <Formik
                            initialValues={accountInfo}
                            enableReinitialize={true}
                            validationSchema={saveSchema}
                            onSubmit={(values) => {
                                setAccountInfo(values);
                                // setAccountInfo((prev) => ({
                                //     ...prev,
                                //     old_password: values.password,
                                //     password: "",
                                //     password_confirmation: "",
                                // }));
                                updateProfile(values)
                            }}
                        >
                            {({handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="editContainer">
                                        <div>
                                            <div className="infoContainer">
                                                <div>
                                                    <Field
                                                        name="first_name"
                                                        className="infoStyle"
                                                        type="text"
                                                        placeholder="First name"
                                                    />
                                                    <ErrorMessage
                                                        name="first_name"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>
                                                <div>
                                                    <Field
                                                        name="last_name"
                                                        className="infoStyle"
                                                        type="text"
                                                        placeholder="Last name"
                                                    />
                                                    <ErrorMessage
                                                        name="last_name"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>
                                            </div>
                                            <div className="infoContainer">
                                                <div>
                                                    <Field
                                                        name="email"
                                                        className="infoStyle"
                                                        disabled
                                                        type="text"
                                                        placeholder="Email"
                                                    />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>
                                                <div>
                                                    <Field
                                                        name="phone_number"
                                                        className="infoStyle"
                                                        disabled
                                                        type="string"
                                                        placeholder="Phone Number"
                                                    />
                                                    <ErrorMessage
                                                        name="phone_number"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>
                                            </div>
                                            <div className="infoContainer">
                                                <div>
                                                    <Field name="gender" className="infoStyle w-100" as="select">
                                                        <option value="">Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name="gender"
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>
                                                {/*<div>*/}
                                                {/*    <Field*/}
                                                {/*        name="date_of_birth"*/}
                                                {/*        className="infoStyle"*/}
                                                {/*        type="date"*/}
                                                {/*        placeholder="date"*/}
                                                {/*    />*/}
                                                {/*    <ErrorMessage name="date_of_birth" component="div" className="error"/>*/}
                                                {/*</div>*/}

                                                <ThemeProvider theme={customTheme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Field name="date_of_birth">
                                                            {({ form, field }) => (
                                                                <DatePicker
                                                                    views={['year', 'month', 'day']}
                                                                    label={"Date Of Birth"}
                                                                    value={dayjs(field.value)}
                                                                    onChange={(value) => form.setFieldValue(field.name, value ? value.format('YYYY-MM-DD') : '')}
                                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                                    clearable
                                                                />
                                                            )}
                                                        </Field>
                                                    </LocalizationProvider>
                                                </ThemeProvider>
                                            </div>
                                            {accountInfo.changePassword && (
                                                <div>
                                                    <div className="infoContainer">
                                                        <Field
                                                            name="old_password"
                                                            className="infoStyle"
                                                            type="password"
                                                            placeholder="Old password"
                                                        />
                                                        <ErrorMessage
                                                            name="old_password"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    <div className="infoContainer">
                                                        <div>
                                                            <Field
                                                                name="password"
                                                                className="infoStyle"
                                                                type="password"
                                                                placeholder="New password"
                                                            />
                                                            <ErrorMessage
                                                                name="password"
                                                                component="div"
                                                                className="error"
                                                            />
                                                        </div>
                                                        <div>
                                                            <Field
                                                                name="password_confirmation"
                                                                className="infoStyle"
                                                                type="password"
                                                                placeholder="Confirm"
                                                            />
                                                            <ErrorMessage
                                                                name="password_confirmation"
                                                                component="div"
                                                                className="error"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <a href="#" onClick={passwordChange} className="cursor-pointer">
                                                    {accountInfo.changePassword
                                                        ? "Cancel Password Change"
                                                        : "Change Password"}
                                                </a>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button className="saveButton" type="submit">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                    :
                    <LoaderComp/>
            }
            <Footer/>
            <Snackbar anchorOrigin={{vertical:'top' , horizontal:'right'}} open={showSuccess} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={'success'} sx={{ width: '100%' }}>
                    Profile Update Successfully
                </Alert>
            </Snackbar>
        </>
    );
}
