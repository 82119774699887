import {Link} from "react-router-dom";

const DownloadApp = () => {
    return (
        <div
            className="bg-no-repeat bg-cover bg-center md:mt-56 mb-10 max-md:my-20 max-md:p-8 rounded-lg relative"
            style={{backgroundImage: `url(/app-bg.png)`}}>
            <div className="container">
                <div className="md:grid grid-cols-2 place-items-center">
                    <div className="text-white font-archivo">
                        <h2 className="font-bold text-3xl">Mobile App Coming Soon !</h2>
                        <p className="text-lg font-light py-3 max-md:hidden">Our app is on its way! Get ready to dive into a world of convenience where booking,
                            check-in, and ordering are made effortless at your beloved resorts.
                            Stay tuned for the launch and elevate your leisure time with us!</p>
                        <div className="flex items-center gap-2 pt-3">
                            <Link to="" className="cursor-pointer m-0">
                                <img src="/google-play.png"/>
                            </Link>
                            <Link to="" className="cursor-pointer">
                                <img src="/app-store.png"/>
                            </Link>
                        </div>
                    </div>
                    <img
                        src="/images/phone.png"
                        className="max-w-[200px] scale-125 z-20 lg:-translate-y-24 -translate-y-16 w-3/4 translate-x-32 max-md:hidden"
                    />
                </div>

            </div>
        </div>
    )
}

export default DownloadApp
