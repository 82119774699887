import React, {useState} from "react";
import {Formik, Field, ErrorMessage} from "formik";
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {registerAction} from "../../store/Auth/AuthActions.ts";
import {toggleBagSideBar} from "../../store/Home/HomeActions";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'

import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Select, MenuItem, FormControl, InputLabel, TextField, Checkbox} from '@mui/material';

const customTheme = createTheme({
    typography: {
        fontFamily: "archivo-regular",
        color: "white"
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily: 'archivo-regular',
                    width: '100%',
                    '& .MuiFormLabel-root': {
                        color: 'white',
                        fontSize: '17px',
                        top: '-8%',
                        '&.Mui-focused': {
                            color: 'white',
                        }
                    },
                    '& .MuiInputBase-root': {
                        color: 'white',
                        backgroundColor: 'rgba(177, 203, 207, 0.28)',
                        borderRadius: '50px',
                        '& .MuiInputBase-input': {
                            color: 'white',
                            backgroundColor: 'rgba(177, 203, 207, 0.28)',
                            borderRadius: '50px',
                            fontFamily: 'archivo-regular',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        color: 'white',
                        '&.Mui-focused': {
                            color: 'white',
                        }
                    },
                    '& .MuiOutlinedInput-root': {
                        // '& fieldset': {
                        //     borderColor: 'white',
                        // },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                    }
                }
            }
        },
    },
});
const UserRegister = () => {
    const dispatch = useDispatch()
    const [emailTaken, setEmailTaken] = useState(false)
    const [fieldValue, setFieldValue] = useState();
    const [registerCreds, setRegisterCreds] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        gender: "",
        date_of_birth: "",
        password: "",
        password_confirmation: "",
    });
    const navigate = useNavigate()
    const registerSchema = yup.object().shape({
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        email: yup
            .string()
            .email("Invalid email address format")
            .required("Email is required"),
        phone_number: yup.string().required("Phone Number is required"),
        gender: yup.string().required("Gender is required"),
        date_of_birth: yup.date().required("Date of birth is required"),
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be 8 characters at minimum")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                "Password must contain at least one symbol"
            ),
        password_confirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required"),
    });
    const [isChecked, setIsChecked] = useState(false);

    const navigateBack = () => {
        navigate(-1)
    }
    const registerCall = (values) => {
        dispatch(registerAction(values))
            .then((response) => {
                localStorage.setItem("email", values.email);
                handleUserRedirect(response)
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    setEmailTaken(true)
                }
            })
    }

    function handleUserRedirect(response) {
        if (!response.email_verified_at) {
            navigate('/verify-email')
            return;
        }
        let fromPage = localStorage.getItem('route_from')
        if (fromPage && fromPage === "checkout") {
            dispatch(toggleBagSideBar(true))
            navigate('/beach-info')
        } else {
            navigate("/")
        }
    }

    const handleDateChange = (date, setFieldValue) => {
        const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
        setFieldValue('date_of_birth', formattedDate);
    };
    return (
        <section
            className="background-image bg-cover bg-no-repeat"
            style={{backgroundImage: `url(/main-background.png)`}}
        >
            <div className="overlay">
                <div className="container">
                    <button className="exit">
                        <CloseIcon onClick={navigateBack}/>
                    </button>
                    <h1 className="my-5 md:text-4xl max-md:text-xl">
                        <b>Create an account</b>
                    </h1>
                    <ThemeProvider theme={customTheme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Formik
                                initialValues={registerCreds}
                                validationSchema={registerSchema}
                                onSubmit={(values) => {
                                    registerCall(values)
                                }}
                            >
                                {({handleSubmit, values, setValues, setFieldValue ,setFieldTouched}) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="inputContainers mt-4">
                                            <div className="flex flex-col">
                                                <Field
                                                    name="first_name"
                                                    className="inputStyle"
                                                    type="text"
                                                    placeholder="First name"
                                                />
                                                <ErrorMessage
                                                    name="first_name"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <Field
                                                    name="last_name"
                                                    className="inputStyle"
                                                    type="text"
                                                    placeholder="Last name"
                                                />
                                                <ErrorMessage
                                                    name="last_name"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="inputContainers mt-4">
                                            <div>
                                                <Field
                                                    name="email"
                                                    className="inputStyle"
                                                    type="text"
                                                    placeholder="Email"
                                                />
                                                {
                                                    emailTaken &&
                                                    <div className={"error"}>Email Already taken</div>
                                                }
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                            <div>
                                                <PhoneInput
                                                    name={"phone_number"}
                                                    defaultCountry={"LB"}
                                                    placeholder={"Phone number"}
                                                    className={"inputStyle"}
                                                    value={registerCreds.phone_number}
                                                    onChange={(phone) => setValues({...values, phone_number: phone})}
                                                />
                                                <ErrorMessage
                                                    name="phone_number"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="inputContainers mt-4">
                                            <div>
                                                {/*<Field name="gender" className="inputStyle w-100" as="select">*/}
                                                {/*    <option value="">Gender</option>*/}
                                                {/*    <option value="male">Male</option>*/}
                                                {/*    <option value="female">Female</option>*/}
                                                {/*</Field>*/}
                                                {/*<ErrorMessage*/}
                                                {/*    name="gender"*/}
                                                {/*    component="div"*/}
                                                {/*    className="error"*/}
                                                {/*/>*/}
                                                <ThemeProvider theme={customTheme}>
                                                    <FormControl className="w-100 gender text-white">
                                                        <InputLabel id="gender-label" className="text-xl"
                                                                    style={{letterSpacing: 'normal'}}>Gender</InputLabel>
                                                        <Select
                                                            labelId="gender-label"
                                                            id="gender"
                                                            value={values.gender}
                                                            onChange={(e) => setFieldValue('gender', e.target.value)}
                                                            label="Gender"
                                                            className="inputStyle"
                                                            style={{letterSpacing: 'normal'}}
                                                        >
                                                            <MenuItem value="male">Male</MenuItem>
                                                            <MenuItem value="female">Female</MenuItem>
                                                        </Select>
                                                        <ErrorMessage name="gender" component="div" className="error"/>
                                                    </FormControl>
                                                </ThemeProvider>
                                            </div>
                                            {/*<div>*/}
                                            {/*    <Field*/}
                                            {/*        name="date_of_birth"*/}
                                            {/*        className="inputStyle"*/}
                                            {/*        type="date"*/}
                                            {/*        placeholder="DOB"*/}
                                            {/*    />*/}
                                            {/*    <ErrorMessage*/}
                                            {/*        name="date"*/}
                                            {/*        component="div"*/}
                                            {/*        className="error"*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            <div className="w-[100%] date-picker">
                                                {/*<DatePicker*/}
                                                {/*    value={values.date_of_birth ? dayjs(values.date_of_birth) : null}*/}
                                                {/*    onChange={(date) => handleDateChange(date, setFieldValue)}*/}
                                                {/*    renderInput={(params) => <TextField {...params} />}*/}
                                                {/*    inputFormat="YYYY-MM-DD"*/}
                                                {/*/>*/}
                                                <DatePicker
                                                    views={['year', 'month', 'day']}
                                                    label={"Date Of Birth"}
                                                    value={values.date_of_birth ? dayjs(values.date_of_birth) : null}
                                                    onChange={(date) => handleDateChange(date, setFieldValue)}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        style={{letterSpacing: 'normal'}}/>}
                                                    inputFormat="YYYY-MM-DD"
                                                />
                                                <ErrorMessage name="date_of_birth" component="div" className="error"/>
                                            </div>
                                        </div>
                                        <div className="inputContainers mt-4">
                                            <div>
                                                <Field
                                                    name="password"
                                                    className="inputStyle"
                                                    type="password"
                                                    placeholder="Password"
                                                    onFocus={() => setFieldTouched('password', true, true)}
                                                />
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    name="password_confirmation"
                                                    className="inputStyle"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                />
                                                <ErrorMessage
                                                    name="password_confirmation"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="inputContainers">
                                            <div className="flex items-center mt-2">
                                                <Link to="/terms-conditions"
                                                      className="text-sm text-white m-0"
                                                      target="_blank">
                                                    Terms and conditions
                                                </Link>
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(e) => setIsChecked(e.target.checked)}
                                                    sx={{color: '#B0CBCE', '&.Mui-checked': {color: '#F96300'}}}
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-5">
                                            <div className="col-md-6">
                                                <button
                                                    className={`buttonStyle font-bold py-2 ${!isChecked ? 'cursor-not-allowed opacity-50' : ''}`}
                                                    type="submit"
                                                    disabled={!isChecked}
                                                >
                                                    Register
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </LocalizationProvider>
                    </ThemeProvider>
                </div>
            </div>
        </section>
    );
}
export default UserRegister
