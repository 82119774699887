import React, {useEffect, useState} from 'react';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from '@mui/icons-material/Mail';
import Person2Icon from '@mui/icons-material/Person2';
import BedIcon from '@mui/icons-material/Bed';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {filterMapInformation, handleSelectedDateChange} from '../../../../../store/BeachAdmin/BeachAdminActions'
import {useDispatch, useSelector} from "react-redux";
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';
import {MobileDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {format} from 'date-fns';
import {Box, Grid, TextField, CircularProgress, Backdrop} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
                        display: 'none',
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#00414A',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.css-1e6y48t-MuiButtonBase-root-MuiButton-root': {
                        color: 'red',
                    },
                },
            },
        },
    },
});

const ReservationsDetails = ({stats, reservations, search, setSearch}) => {
    const dispatch = useDispatch();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    // const [search, setSearch] = useState('');
    const [filteredReservations, setFilteredReservations] = useState(reservations);
    const [filter, setFilter] = useState({
        search: '',
        reservation_status: ''
    })
    const [selectedDate, setSelectedDate] = useState(null);
    const {selectedDate: reduxSelectedDate} = useSelector((state) => state.beachAdmin);
    const [loading, setLoading] = useState(false);
    const [loadingTimeout, setLoadingTimeout] = useState(null);

    useEffect(() => {
        if (reduxSelectedDate) {
            setSelectedDate(new Date(reduxSelectedDate));
        }
    }, [reduxSelectedDate]);
    const handleDatePickerOpen = () => {
        setIsDatePickerOpen(true);
    };
    // const handleSearchChange = (event) => {
    //     const value = event.target.value;
    //     setSearch(value);
    //     localStorage.setItem('searchInput', value);
    //
    //     if (loadingTimeout) {
    //         clearTimeout(loadingTimeout);
    //     }
    //     const timeout = setTimeout(() => {
    //         setLoading(true);
    //         const filter = { search: value, reservation_status: '' };
    //         dispatch(filterMapInformation(filter, selectedDate)).then(() => {
    //             setLoading(false);
    //         });
    //     }, 600);
    //
    //     setLoadingTimeout(timeout);
    // };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        localStorage.setItem('searchInput', value);

        if (loadingTimeout) {
            clearTimeout(loadingTimeout);
        }
        const timeout = setTimeout(() => {
            setLoading(true);
            const filter = {search: value, reservation_status: ''};
            dispatch(filterMapInformation(filter, selectedDate)).then(() => {
                setLoading(false);
            });
        }, 600);

        setLoadingTimeout(timeout);
    };
    const handleClearSearch = () => {
        setSearch('');
        localStorage.removeItem('searchInput');
        setLoading(true);
        const filter = {search: '', reservation_status: ''};
        dispatch(filterMapInformation(filter, selectedDate)).then(() => {
            setLoading(false);
        });
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleOkButtonClick = async () => {
        setIsDatePickerOpen(false);
        if (selectedDate) {
            const formattedDate = format(selectedDate, 'yyyy-MM-dd');
            localStorage.setItem('selectedDate', formattedDate);
            dispatch(handleSelectedDateChange(formattedDate));
            dispatch(filterMapInformation({search, reservation_status: ''}, formattedDate));
        }
    };
    const reservationStatusDisplay = (status) => {
        switch (status) {
            case 'reserved':
                return 'Reserved';
            case 'checked_in':
                return 'Checked In';
            case 'canceled':
                return 'Canceled';
            case 'reservation_confirmed':
                return 'Confirmed';
            default:
                return status;
        }
    };

    useEffect(() => {
        if (selectedDate) {
            localStorage.setItem('selectedDate', format(selectedDate, 'yyyy-MM-dd'));
        }
    }, [selectedDate]);

    useEffect(() => {
        const date = localStorage.getItem('selectedDate');
        if (date) {
            setSelectedDate(new Date(date));
        }
        if (search) {
            console.log("IN SEARCH")
            dispatch(filterMapInformation({search: search}, date)).then(() => {
                setLoading(false);
            });
        }
    }, []);

    return (
        <div className="flex flex-col">
            <div className="xl:flex md:grid items-center gap-2 p-4">
                <div className="relative w-full text-[#B0CBCE]">
                    <SearchIcon
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#B0CBCE]"
                    />
                    <input
                        type="text"
                        value={search}
                        onChange={handleSearchChange}
                        className="pl-10 pr-10 py-2 border bg-[#B0CBCE]/20 text-[#B0CBCE] rounded w-full"
                        placeholder="Search..."
                    />
                    {search && (
                        <button
                            onClick={handleClearSearch}
                            className="absolute right-3 top-3 transform -translate-y-1/2"
                        >
                            <ClearIcon/>
                        </button>
                    )}
                </div>
                <div className="bg-[#F3F7F7] md:flex md:justify-center rounded-lg p-2" onClick={handleDatePickerOpen}>
                    <CalendarMonthIcon className="cursor-pointer text-[#00414A]"/>
                </div>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            open={isDatePickerOpen}
                            onClose={() => setIsDatePickerOpen(false)}
                            onAccept={handleOkButtonClick}
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} sx={{display: 'none'}}/>}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </div>
            <div className="grid xl:grid-cols-2 grid-cols-1 gap-2 p-4">
                <div className="bg-primary-dark-green text-white rounded-lg p-2 flex flex-col items-center">
                    <p className={"m-0 mt-3 font-bold"}>{stats.pending_reservations}</p>
                    <p className={"font-archivo-light xl:text-sm md:text-xs"}>Pending</p>
                </div>
                <div className="bg-primary-dark-green text-white rounded-lg p-2 flex flex-col items-center">
                    <p className={"m-0 mt-3 font-bold"}>{stats.checked_in_reservations}</p>
                    <p className={"font-archivo-light xl:text-sm md:text-xs"}>Checked-In</p>
                </div>
                <div className="bg-primary-dark-green text-white rounded-lg p-2 flex flex-col items-center">
                    <p className={"m-0 mt-3 font-bold"}>{stats.available_seats}</p>
                    <p className={"font-archivo-light xl:text-sm md:text-xs"}>Available</p>
                </div>
                <div className="bg-primary-dark-green text-white rounded-lg p-2 flex flex-col items-center">
                    <p className={"m-0 mt-3 font-bold"}>{stats.canceled_reservations}</p>
                    <p className={"font-archivo-light xl:text-sm md:text-xs"}>Cancellations</p>
                </div>
            </div>
            <p className="border-b border-primary-gray/10"></p>
            <div className="px-1">
                {reservations.map((item, index) => (
                    <div key={item.id} className={"border rounded-lg my-4 p-3"}>
                        <div
                            className="flex items-center justify-between gap-2 text-primary-dark-green md:text-xs xl:text-lg">
                            <p className="font-bold">#{index + 1}</p>
                            <p>  {reservationStatusDisplay(item.reservation_status)}</p>
                        </div>
                        <p className="border-b border-primary-gray/10 py-2"></p>
                        {item.user && (
                            <div className="flex items-center justify-between pt-3">
                                <p className="text-primary-gray xl:text-sm md:text-xs">{item.user.first_name} {item.user.last_name}</p>
                                <Person2Icon className="text-[#B0CBCE]"/>
                            </div>
                        )}
                        {/*{item.guest_name && (*/}
                        {/*    <div className={"text-primary-gray text-sm font-bold"}>*/}
                        {/*        {item.guest_name}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        <div className="py-2">
                            {item.user?.phone_number ? (
                                <div className="flex items-center justify-between">
                                    <p className={"m-0 text-primary-gray text-sm"}>{item.user.phone_number}</p>
                                    <LocalPhoneIcon className="cursor-pointer text-[#B0CBCE]"/>
                                </div>
                            ) : (
                                <>
                                    {item.user?.email && (
                                        <div className={"flex items-center justify-between my-2"}>
                                            <div className="col-md-10 p-0 d-flex align-items-center overflow-x-auto">
                                                <p className={"m-0 whitespace-nowrap text-primary-gray text-sm"}>{item.user?.email}</p>
                                            </div>
                                            <MailIcon style={{
                                                cursor: 'pointer',
                                                color: '#B0CBCE',
                                                fontSize: '18px'
                                            }}/>
                                        </div>
                                    )}
                                    {/*{item.user && (*/}
                                    {/*    <div className="flex items-center justify-between pt-3">*/}
                                    {/*        <p className="text-primary-gray text-sm">{item.user.first_name} {item.user.last_name}</p>*/}
                                    {/*        <Person2Icon className="text-[#B0CBCE]"/>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </>
                            )}
                        </div>
                        <div className="flex items-center justify-between">
                            {
                                item.reservation_date && (
                                    <>
                                        <p className="text-primary-gray xl:text-sm md:text-xs">{item.reservation_date ? format(new Date(item.reservation_date), 'MM/dd/yyyy') : 'No date available'}</p>
                                        <EventAvailableIcon className="text-[#B0CBCE]"/>
                                    </>
                                )
                            }
                        </div>
                        <div className="py-2">
                            {item.item ? (
                                <div className="flex items-center justify-between">
                                    <p className={"m-0 text-primary-gray xl:text-sm md:text-xs"}>{item.item?.name}</p>
                                    <BedIcon className="text-[#B0CBCE]"/>
                                </div>
                            ) : item.reservation_type === 'walk_in' ? (
                                <p className={"m-f0 text-primary-dark-green xl:text-sm md:text-xs"}>Walk-in</p>
                            ) : (
                                item.reservation_type === 'day_pass' && (
                                    <p className={"m-0 text-primary-dark-green xl:text-sm md:text-xs"}>Day Pass
                                        ({item.day_pass_count})</p>
                                )
                            )}
                        </div>
                        {item.reservation_status === 'canceled' && (
                            <p className={"text-red-700 text-sm py-2 bg-red-700/10 my-3 text-center rounded-md"}><b>Canceled
                                Reservation</b></p>
                        )}
                        <div className="flex items-center justify-between py-2">
                            <p className="text-primary-gray xl:text-lg md:text-xs">Amount</p>
                            <div className="flex items-center gap-2 xl:text-lg md:text-xs">
                                <p className="text-primary-dark-green">${item.amount_per_reservation}</p>
                                <p className={(item.is_paid || (item.amount_per_reservation != null && item.amount_per_reservation !== "0.00")) ? "text-[#00AF53]" : "text-[#F96300]"}>
                                    /{(item.is_paid || (item.amount_per_reservation != null && item.amount_per_reservation !== "0.00")) ? "paid" : "unpaid"}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {loading &&
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
        </div>
    );
};

export default ReservationsDetails;
