import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    totalNumbers: [],
    bookingGender: [],
    bookingAges: [],
    dailyBookings: [],
    isLoading: false,
    error: null,
};

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setTotalNumbers(state, action) {
            state.totalNumbers = action.payload;
        },
        setBookingGender(state, action) {
            state.bookingGender = action.payload;
        },
        setBookingAges(state, action) {
            state.bookingAges = action.payload;
        },
        setDailyBookings(state, action) {
            state.dailyBookings = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setAnalyticsData(state, action) {
            state.totalNumbers = action.payload.totalNumbers;
            state.bookingGender = action.payload.bookingGender;
            state.bookingAges = action.payload.bookingAges;
            state.dailyBookings = action.payload.dailyBookings;
        },
        resetAnalytics(state) {
            state.totalNumbers = [];
            state.bookingGender = [];
            state.bookingAges = [];
            state.dailyBookings = [];
        },
    },
});

export const {
    setIsLoading,
    setTotalNumbers,
    setBookingGender,
    setBookingAges,
    setDailyBookings,
    setError,
    setAnalyticsData,
    resetAnalytics,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

