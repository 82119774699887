import {Avatar, Box, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {checkIfWeekend} from "../../../util";
import GuestLogin from "../GuestLogin";
import ItemsSelected from "../ItemsSelected";
import GuestStripe from "../GuestStripe";
import {useDispatch, useSelector} from "react-redux";
import {guestSlice} from "../../../store/Guest/GuestSlice";
import {addToCartAction} from "../../../store/Home/HomeActions";
import {addToGuestCard} from "../../../store/Guest/GuestActions";
import EmailIcon from "@mui/icons-material/Email";

const CheckoutModal = ({open, handleClose}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: '80%' },
        maxHeight: '90vh',
        overflowY: 'auto',
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 24,
        borderRadius: '15px',
        outline: 0,
    };
    const [total, setTotal] = useState()
    const [activeStep, setActiveStep] = useState(0)
    const {bag , originalItems } = useSelector((state) => state.guest)
    const dispatch = useDispatch()
    const [email, setEmail] = useState("");

    useEffect(() => {
        setEmail(localStorage.getItem('email'))
        let total = 0;
        bag.map((item) => {
            total = total + (item.count * (checkIfWeekend(item.date) ? Number(item.pivot.weekend_price) : Number(item.pivot.weekday_price)))
        })
        setTotal(total)
        if (localStorage.getItem('token')) {
            dispatch(addToGuestCard(originalItems , bag))
                .then(()=>{
                    setActiveStep(1)
                })
        }
    }, []);
    return (
        <Modal
            open={open}
            >
            <Box sx={style}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-2 me-3">
                            <div className="float-end">
                                <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}}/>
                            </div>
                        </div>
                    </div>

                    {
                        activeStep === 0 ?
                            <GuestLogin handleUserLoggedIn={() => setActiveStep(1)}/> :
                            activeStep === 1 ?
                                <>
                                    <ItemsSelected handleNotYourEmail = {()=>setActiveStep(0)} handlePay={()=>setActiveStep(2)}/>
                                </>
                                :
                                <>
                                    <GuestStripe handleNotYourEmail = {()=>setActiveStep(0)} />

                                </>
                    }
                </div>
            </Box>
        </Modal>
    )
}
export default CheckoutModal
