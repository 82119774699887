import Header from "../../components/Header";
import Slider from "./Slider";
import PopularThisSeason from "./PopularThisSeason";
import DiscoverAll from "./DiscoverAll";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCountries} from "../../store/Home/HomeActions";
import LoaderComp from "../../components/LoaderComp";
import {Box, Modal} from "@mui/material";
import {ErrorMessage, Field, Formik} from "formik";
import * as yup from "yup";
import {setupProfileAction} from "../../store/Auth/AuthActions.ts";
import PhoneInput from "react-phone-number-input";

export const HomePage = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius:'15px',
        outline:0
    };

    const {isLoading, isLoadingPopular} = useSelector((state) => state.home)
    const dispatch = useDispatch()
    const [setupInfo, setSetupInfo] = useState({
        first_name: '',
        last_name: '',
        password:'',
        password_confirmation:'',
        phone_number:''
    });
    const setupProfileSchema = yup.object().shape({
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be 8 characters at minimum")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                "Password must contain at least one symbol"
            ),
        password_confirmation:  yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm new password is required"),
        phone_number: yup.string().required("Phone Number is required"),

    });
    const setupProfile = (values) =>{
        dispatch(setupProfileAction(values))
            .then(()=>{
            setShowModal(false)
        })
    }

    const [showModal , setShowModal] = useState(false)
    useEffect(()=>{{
        let firstName = localStorage.getItem('firstName')
        let lastName = localStorage.getItem('lastName')
        let token = localStorage.getItem('token')
        if(token && (!firstName || !lastName || firstName === 'null' || lastName === 'null')){
            setShowModal(true)
        }
    }},[])


    return (
        <>

            <Slider/>
            <Header/>
            {
                !isLoading ?
                    !isLoadingPopular ? <PopularThisSeason/> : <LoaderComp/>
                    :
                    <LoaderComp/>
            }
            {
                !isLoading ? <DiscoverAll/> :<LoaderComp/>
            }

            {
                showModal &&
                <Modal
                    keepMounted
                    open={showModal}>
                    <Box sx={style}>
                        <div style={{background : '#005662' , color:'white' , borderTopLeftRadius:'10px' , borderTopRightRadius:'10px'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center ">
                                        <h3 className={"mt-3 mb-3"}>Set-Up Profile</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div className="container">
                           <Formik
                               initialValues={setupInfo}
                               validationSchema={setupProfileSchema}
                               onSubmit={(values) => {
                                   setupProfile(values)
                               }}>
                               {({ handleSubmit ,values , setValues}) => (
                                   <form onSubmit={handleSubmit}>
                                       <div className="row justify-content-center mt-3">
                                           <div className={"col-md-10"}>
                                               <Field
                                                   name="first_name"
                                                   className="inputStyleDark"
                                                   type="text"
                                                   placeholder="First name"
                                               />
                                               <ErrorMessage
                                                   name="first_name"
                                                   component="div"
                                                   className="error"
                                               />
                                           </div>
                                       </div>
                                       <div className="row justify-content-center  mt-4">
                                           <div className={"col-md-10"}>
                                               <Field
                                                   name="last_name"
                                                   className="inputStyleDark"
                                                   type="text"
                                                   placeholder="Last name"
                                               />
                                               <ErrorMessage
                                                   name="last_name"
                                                   component="div"
                                                   className="error"
                                               />
                                           </div>
                                       </div>
                                       <div className="row justify-content-center  mt-4">
                                           <div className={"col-md-10"}>
                                               <PhoneInput
                                                   name={"phone_number"}
                                                   defaultCountry={"LB"}
                                                   placeholder={"Enter phone number"}
                                                   className={"inputStyleDark"}
                                                   value={setupInfo.phone_number}
                                                   onChange={(phone) => setValues({...values , phone_number:phone})}
                                               />
                                               <ErrorMessage
                                                   name="phone_number"
                                                   component="div"
                                                   className="error"
                                               />
                                           </div>
                                       </div>
                                       <div className="row justify-content-center mt-3">
                                           <div className={"col-md-10"}>
                                               <Field
                                                   name="password"
                                                   className="inputStyleDark"
                                                   type="password"
                                                   placeholder="New Password"
                                               />
                                               <ErrorMessage
                                                   name="password"
                                                   component="div"
                                                   className="error"
                                               />
                                           </div>
                                       </div>
                                       <div className="row justify-content-center mt-3">
                                           <div className={"col-md-10"}>
                                               <Field
                                                   name="password_confirmation"
                                                   className="inputStyleDark"
                                                   type="password"
                                                   placeholder="Confirm New Password"
                                               />
                                               <ErrorMessage
                                                   name="password_confirmation"
                                                   component="div"
                                                   className="error"
                                               />
                                           </div>
                                       </div>

                                       <div className="row justify-content-center mt-2">
                                           <div className="col-md-6">
                                               <button className="buttonStyleDark py-2" type="submit">
                                                   Submit
                                               </button>
                                           </div>
                                       </div>
                                   </form>
                               )}
                           </Formik>
                       </div>

                    </Box>
                </Modal>
            }
        </>
    )
}
export default HomePage
