import {Box, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const ShowImageModal = ({open, handleClose, imageUrl}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius: '15px',
        outline: 0,
    };

    const imageStyle = {
        position: 'relative',
        borderRadius: '10px',
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Box sx={style} className="md:h-[70vh] md:w-[60%] max-md:w-[80%] max-md:h-[50%]">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-1">
                            <div className="float-end">
                                <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="font-bold text-xl text-primary-dark-green">
                                Section Live Image</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="text-center md:mt-5 max-md:mt-4">
                            <img src={imageUrl} style={imageStyle}
                                 className="md:h-[50vh] md:w-[80vw] max-md:h-60 max-md:w-full"/>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default ShowImageModal;
