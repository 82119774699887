import {MobileStepper} from "@mui/material";
import {useTheme} from "@emotion/react";
import {useState} from "react";
import SwipeableViews from "react-swipeable-views";
import './header.css'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
export const Slider = () =>{
    const theme = useTheme();
    const navigate=useNavigate()
    const {backgroundSlider , isLoading} = useSelector((state) => state.home)
    const [activeStep, setActiveStep] = useState(0);
    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const selectBeach = (id) => {
        localStorage.setItem('selected_beach', id)
        navigate('/beach-info')
    }
    return(
        <div>
            {
                !isLoading && (
                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        threshold={backgroundSlider.length}>
                        {
                             backgroundSlider.map((item , index)=>(
                                <div key={'background_slider_' + index} className="background-image-container"  style={{ backgroundImage: `url('${item.background_image}')` }}>
                                    <div className="background-overlay">
                                        <div className="container mt-5">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h1>{item.name}</h1>
                                                    <p>{item.location}</p>
                                                </div>
                                            </div>
                                            <div className="row mt-5 justify-content-center">
                                                <div className="col-md-10 ">
                                                    <button onClick={()=>selectBeach(item.id)} className="secondary-button">Book</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </SwipeableViews>
                )
            }
            <MobileStepper
                color="red"
                className="testimonial-stepper"
                steps={backgroundSlider.length}
                position="static"
                activeStep={activeStep}
                nextButton={null}
                backButton={null}
            />
        </div>
    )
}
export default Slider
