import {useQuery} from 'react-query';
import axios from 'axios';
import {
    setAllBeaches,
    setHomePageData, setIsLoading,
    setLocationFilters,
    setMaxPrice,
    setMinPrice
} from "../../store/HomePage/HomePageSlice";
import {useDispatch} from "react-redux";
import {homeSlice} from "../../store/Home/HomeSlice";

export const useHomePageInfo = (filters, isEnabled) => {

    let countryId = localStorage.getItem('country_id');
    const dispatch = useDispatch()
    const {data, isLoading} = useQuery([countryId],
        {
        queryKey: ["homePage"],
        staleTime: 5 * 60 * 1000,
        queryFn: async () => {
            dispatch(homeSlice.actions.setIsLoading(true))

            const url = `country/${countryId}/home`;
            const response = await axios.get(url)
            const defaultSlider = response.data.data.categories.find(item => item.type === 'default_slider');
            if (defaultSlider) {
                const { min_price, max_price } = defaultSlider;
                dispatch(setMinPrice(defaultSlider.min_price));
                dispatch(setMaxPrice(defaultSlider.max_price));
                dispatch(setLocationFilters(defaultSlider.locations));
            }
            dispatch(setHomePageData(response.data.data.categories));
            dispatch(setAllBeaches(response.data.data.all_beaches));
            let allBeaches = response.data.data.all_beaches.map((beach)=>(
                {
                    ...beach,
                    position:{
                        lat: Number(beach.latitude),
                        lng: Number(beach.longitude)
                    }
                }
            ))
            dispatch(homeSlice.actions.setAllBeachesMap(allBeaches))
            dispatch(homeSlice.actions.setIsLoading(false))
        }
    },
        {
            // The query will not execute until the userId exists
            enabled: !!countryId,
        }
        )
    return {}
};
export const useCountries = () => {

    const dispatch = useDispatch()
    const {data, isLoading} = useQuery({
        queryKey: ["countries"],
        staleTime: 5 * 60 * 1000,
        queryFn: async () => {
                dispatch(homeSlice.actions.setIsLoading(true))
                await axios.get('/countries').then((response) => {
                    dispatch(homeSlice.actions.setCountries(response.data.data))
                })
                dispatch(homeSlice.actions.setIsLoading(false))
        }
    })
    return {}
};


export const useBeachInfo = ({ beach_id }) => {
    const dispatch = useDispatch();

    const {data , isLoading} =   useQuery({
        queryKey: ["beach", beach_id], // Ensure there's no unintended space in your query key
        staleTime: 5 * 60 * 1000, // 5 minutes
        queryFn: (data) =>
            axios.get(`/beach/${beach_id}`)
                .then(res => res.data),
        onSuccess: (data) => {
            dispatch(homeSlice.actions.setIsLoading(false));
        },
        onSettled: () => {
            dispatch(homeSlice.actions.setIsLoading(false));
        },
        onError: (error) => {
            console.error(error);
            dispatch(homeSlice.actions.setIsLoading(false));
        }
    });
    return {data , isLoading}
    // Depending on your implementation, you may return data or query state from this hook
};