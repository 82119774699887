import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import {useSelector} from "react-redux";

const NumberBookingsChart = () => {
    const dailyBookings = useSelector((state) => state.analytics.dailyBookings);

    const data = dailyBookings.map((booking) => ({
        name: booking.reservation_date,
        bookings: booking.count,
    }));

    const xLabels = data.map(item => item.name);
    const yData = data.map(item => item.bookings);

    return (
        <div className="w-full h-full">
            <div className="">
                <LineChart
                    xAxis={[
                        {
                            scaleType: 'band',
                            data: xLabels,
                            // label: 'Days of the Week',
                        },
                    ]}
                    yAxis={[
                        {
                            scaleType: 'linear',
                            // label: 'Number of Bookings',
                        },
                    ]}
                    series={[
                        {
                            type: 'line',
                            data: yData,
                            label: 'Number of Bookings',
                            showPoints: true,
                            color: '#005662',
                        },
                    ]}
                    height={300}
                    tooltip={{
                        enable: true,
                        formatter: (value) => `${value} bookings`,
                    }}
                />
            </div>
        </div>
    );
};

export default NumberBookingsChart;
