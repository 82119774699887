import {useNavigate} from "react-router-dom";

const UnauthorizedPage= () =>{
    const navigate = useNavigate()
    const navigateToLogin = () => {
        navigate('/login-user')
    }
    return (
        <div>
            <div className="background">
                <img src="white-logo.svg" />
            </div>
            <div className="container mt-5">
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 text-center">
                        <h1 className={"header-text"}>401</h1>
                        <p className={"header-text"}>You either don't have access or your session has ended.</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center">
                        <button onClick={navigateToLogin} className={"primary-button px-4"}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UnauthorizedPage