const Orders = () =>{
    return(
        <div className="flex flex-col items-center justify-center">
           <img src="../../../../../images/dashboard/coming-soon.png" className="py-3"/>
            <p className="text-2xl text-primary-dark-green font-bold py-2">Coming Soon...</p>
            <p className="text-primary-gray pt-3 text-center">
                This page is currently under construction and will be available soon <br/> Stay tuned for updates !
            </p>
        </div>
    )
}
export default Orders
