import React  from "react";
import {useDrag} from "react-dnd";
import {ItemTypes } from "../../ItemTypes";

export const Item = ({ section}) => {
    const [, drag] = useDrag({
        type:ItemTypes.ITEM,
        item: {...section} ,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div style={{cursor:'pointer' , width:'50px' , height: '50px'}} ref={drag}>
            {
                section.image ?
                    (
                        <img src={section.image} width="50" height="50" />
                    )
                    :
                    <h5>{section.title}</h5>
            }
        </div>
    );
};
