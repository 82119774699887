import React, {useRef} from "react";
import {useDrag} from "react-dnd";
import {ItemTypes } from "../../ItemTypes";
import {useSelector} from "react-redux";

export const Section = ({ section, index  }) => {
    const { optionSelected , itemBackground } = useSelector((state)=> state.map)
    const mapRef = useRef(null);
    const [, drag] = useDrag({
        type:ItemTypes.SECTION,
        item: {...section , index} ,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    return (
       <>

           {
               section &&
               <div
                   ref={optionSelected === 'move' ? drag : mapRef }
                   style={{
                       position: 'absolute',
                       left: section.startX < section.endX ? section.startX : section.endX ,
                       top: section.startY < section.endY ? section.startY : section.endY ,
                       width: Math.abs(section.endX - section.startX) ,
                       height: Math.abs(section.endY - section.startY) ,
                       border: !section.type && '2px dashed #599198',
                       backgroundImage: `url(${itemBackground[section.type]})`,
                       pointerEvents: 'auto',
                       backgroundSize:'cover',
                       backgroundPosition:'center',
                       borderRadius:section.rounded && '50%'
                   }}
               >
                   {
                       !section.type &&
                       (
                           <>
                               <div style={{position:'absolute' , top:0 , left:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( -50% , -50%)'}} />
                               <div style={{position:'absolute' , top:0 , right:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( 50% , -50%)'}} />
                               <div style={{position:'absolute' , bottom:0 , left:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( -50% , 50%)'}}/>
                               <div style={{position:'absolute' , bottom:0 , right:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( 50% , 50%)'}}/>
                           </>
                       )
                   }
               </div>
           }


       </>

    );
};
