import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    addToBag,
    getBeachInfoById,
    getBuilderInfo,
    getMapByBeachId, handleSelectedItems, resetMapInfo,
    setMap,
} from "../../store/Home/HomeActions";
import './style.css'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";
import DisabledItem from "../../components/Item/DisabledItem";
import DisabledSection from "../../components/DisabledSection";
import {setLoading} from "../../store/Map/MapActions";
import moment from "moment";
import {checkIfWeekend} from "../../util";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {Backdrop, Box, Button, CircularProgress} from "@mui/material";
import LoaderComp from "../../components/LoaderComp";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import dayjs from 'dayjs';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Space} from "react-zoomable-ui";

const customTheme = createTheme({
    components: {
        typography: {
            fontFamily: 'archivo-regular',
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontFamily: 'archivo-regular',
                    color: "#00414A",
                    '&.Mui-selected': {
                        backgroundColor: '#00414A',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#00414A',
                        }
                    },
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                label: {
                    fontFamily: 'archivo-regular',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: '#00414A',
                        backgroundColor: 'white',
                        fontFamily: 'archivo-regular',
                        fontWeight: 'bold',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white',
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'white',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                        '& .MuiInputBase-input': {
                            backgroundColor: 'white',
                        },
                    },
                },
            },
        },
    },
});
export const SelectSeats = () => {
    const [date, setDate] = useState()
    const {
        selectedBeach,
        map,
        mapScreenWidth,
        mapScreenHeight,
        initialMap,
        totalItems,
        totalAmount,
        dayPassPrice,
        selectedItems,
        isFull,
        isLoading
    } = useSelector((state) => state.home)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dayPass, setDayPass] = useState(1)
    const [activeStep, setActiveStep] = useState(0);
    const [isMobileScreen, setIsMobileScreen] = useState(false)
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    useEffect(() => {
        dispatch(getBeachInfoById())
        dispatch(getBuilderInfo())
        let initialDate = moment().format('YYYY-MM-DD')
        dispatch(getMapByBeachId(initialDate))
    }, [])

    useEffect(() => {
        if (date && activeStep === 1) {
            dispatch(getMapByBeachId(moment(date, 'dddd D MMMM YYYY').format('YYYY-MM-DD')))
            scrollToFirstSection()
        }
    }, [date, activeStep])

    const buttonStyle = {
        width: 30,
        height: 30,
        fontSize: 16,
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialMap]);

    useEffect(() => {
        handleResize()
    }, [initialMap])

    useEffect(() => {
        let selectedItems = []
        let totalItems = 0
        let totalAmount = 0
        let isWeekend = checkIfWeekend(date)
        initialMap.map((item, index) => {
            if (item.is_selected) {
                selectedItems.push({
                    ...item,
                    map_index: index,
                    date: moment(date, 'dddd D MMMM YYYY').format('YYYY-MM-DD')
                })
                totalItems = totalItems + 1
                totalAmount = totalAmount + (isWeekend ? Number(item.pivot.weekend_price) : Number(item.pivot.weekday_price))
            }
        })
        dispatch(handleSelectedItems(selectedItems, totalItems, totalAmount))
    }, [initialMap])

    // const handleDateChanged = (event) => {
    //     setDate(moment(event.target.value, 'YYYY-MM-DD').format('dddd D MMMM YYYY'))
    // }
    const handleDateChanged = (newValue) => {
        if (newValue) {
            const formattedDate = newValue.format('dddd D MMMM YYYY');
            setDate(formattedDate);
            setActiveStep(1);
        } else {
            setDate('');
        }
    };
    const handleResize = () => {
        let currentMapSize = window.innerWidth * (10 / 12);
        // 8/12 is currently used cz of col-md-8 and 10/12 is the col-md-10 usd in the map.
        let widthDiff = ((8 / 12 * 100) / (10 / 12)) / 100
        // 0.8 is a number calculated
        let ratio = (mapScreenWidth / (currentMapSize - 50)) / widthDiff;
        // 140 is the margin block
        // 20 is the border
        let currentMapSizeHeight = window.innerHeight - (140 + 20 * 2);
        let heightRatio = mapScreenHeight / currentMapSizeHeight;


        let data = initialMap?.map(section => ({
            ...section,
            startX: section.startX / ratio,
            endX: section.endX / ratio,
            startY: section.startY / heightRatio,
            endY: section.endY / heightRatio,
            height: (section.special_height ?? 50) / heightRatio + 'px',
            width: (section.special_width ?? 50) / heightRatio + 'px'
        }));
        dispatch(setMap(data));
        dispatch(setLoading(false));

    }

    function handleBack() {
        navigate('/beach-info')
    }

    const groupItems = (items) => {
        const countMap = {};
        items.forEach(item => {
            const id = item.id;
            if (countMap[id]) {
                countMap[id].count += 1;
            } else {
                countMap[id] = {
                    ...item,
                    beach_image: selectedBeach.thumbnail_image,
                    count: 1
                };
            }
        });
        return Object.values(countMap);
    }
    const bookSeats = () => {
        const groupedItems = groupItems(selectedItems);
        dispatch(addToBag(groupedItems, selectedItems));
        navigate(-1);
    }
    const addDayPass = () => {
        let data = [
            {
                beach_id: selectedBeach.id,
                beach_name: selectedBeach.name,
                beach_image: selectedBeach.thumbnail_image,
                type: 'day_pass',
                date: date,
                count: dayPass,
                day_pass_price: dayPassPrice,
            }
        ]
        dispatch(addToBag(data, data))
        navigate(-1)
    }

    const initViewPort = async (vp) => {
        vp.setBounds({x: [0, mapScreenWidth], y: [0, mapScreenHeight]});
        vp.camera.centerFitAreaIntoView({
            left: 0,
            top: 0,
            width: mapScreenWidth,
            height: mapScreenHeight
        });
        // vp.containerHeight = 300
    };

    const handleBackStepper = () => {
        setDate("")
        setActiveStep(0)
    }
    useEffect(() => {
        return () => {
            dispatch(resetMapInfo())
        }
    }, []);
    const firstSectionRef = useRef(null);
    const spaceRef = useRef(null);

    const scrollToFirstSection = () => {
        firstSectionRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <>
            {isLoading &&
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
            <div className="background-image-fill md:h-[100vh]"
                 style={{backgroundImage: `url('${selectedBeach.background_image}')`}}>
                <div className="background-overlay-seats md:h-[100%] max-md:h-full">
                    <div className="container-fluid">
                        <div className="row h-100 max-md:py-8 max-md:px-3">
                            <div
                                className="col-md-4 col-lg-4 col-12 p-0 md:grid md:h-[100%] md:justify-center md:items-center">
                                {
                                    <div className="grid">
                                        <Stepper activeStep={activeStep} orientation="vertical">
                                            <Step>
                                                <StepLabel>
                                                    <h4 className="text-white text-sm font-archivo">Select your date to
                                                        discover the map</h4>
                                                </StepLabel>
                                                {date && (
                                                    <div
                                                        onClick={handleBackStepper}
                                                        className={"mt-2 text-primary-dark-green bg-white font-bold text-sm rounded-full mx-8 px-3 py-2 cursor-pointer"}>
                                                        <b>{date}</b>
                                                    </div>
                                                )}
                                                <StepContent>
                                                    <div>
                                                        {/*<input onChange={handleDateChanged}*/}
                                                        {/*       min={today} placeholder={"Date"}*/}
                                                        {/*       value={moment(date, 'dddd D MMMM YYYY').format('YYYY-MM-DD')}*/}
                                                        {/*       type={"date"}*/}
                                                        {/*       className={"mt-2 date-input"}/>*/}


                                                        <ThemeProvider theme={customTheme}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    value={date ? dayjs(date, 'dddd D MMMM YYYY') : null}
                                                                    // onChange={handleDateChanged}
                                                                    onAccept={handleDateChanged}
                                                                    views={['year', 'month', 'day']}
                                                                    minDate={dayjs(today)}
                                                                    inputFormat="YYYY-MM-DD"
                                                                />
                                                            </LocalizationProvider>
                                                        </ThemeProvider>
                                                    </div>
                                                </StepContent>
                                            </Step>
                                            <Step>
                                                <StepLabel>
                                                    <h4 className={`text-white text-lg font-archivo ${!date ? 'blur-sm' : ''}`}>
                                                        Select Your Seats
                                                    </h4>
                                                </StepLabel>
                                                <StepContent>
                                                    {
                                                        isFull ?
                                                            <h1>No available seats for:</h1>
                                                            :
                                                            <div className={`text-sm ${!date ? 'blur-sm' : ''}`}>
                                                                Select Your Seats for the <br/>following date:
                                                            </div>
                                                    }
                                                    {
                                                        totalItems > 0 &&
                                                        <div>
                                                            <ul className="selected-items-list mt-3">
                                                                {groupItems(selectedItems).map((item, index) => (
                                                                    <li key={index}
                                                                        className="flex gap-2 text-md max-md:text-sm text-white py-1">
                                                                        <span>{item.count}x</span>
                                                                        <span>{item.name}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            <div
                                                                onClick={bookSeats}
                                                                className={`cursor-pointer flex items-center justify-between border-white border-1 bg-primary-orange mt-3 text-white hover:bg-primary-green rounded-full px-3 py-2 font-bold text-md ${!date ? 'blur-sm' : ''}`}>
                                                                <div className="flex items-center gap-1">
                                                                    <p> Book Now</p>
                                                                    <p>({totalItems})</p>
                                                                </div>
                                                                <p className="mt-1"> $ {totalAmount}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <Box sx={{mb: 2, mt: 3}}>
                                                        <div>
                                                            <b style={{cursor: 'pointer'}}
                                                               onClick={handleBackStepper}>
                                                                <ArrowBackIcon/>
                                                                <span className={"ms-2"}>Back</span>
                                                            </b>
                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>
                                        </Stepper>

                                    </div>

                                }
                            </div>
                            <div className="col-md-8 col-lg-8 col-12 p-0">
                                {
                                    !isMobileScreen ?
                                        <div className="map-preview">

                                            <div
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    border: '1px solid black',
                                                    position: 'relative',
                                                    opacity: (!date || activeStep !== 1 || isFull) ? '0.3' : ''
                                                }}>
                                                {

                                                    map?.map((section, index) => (
                                                        section.type === 'ITEM' ?
                                                            (
                                                                <DisabledItem date={date} activeStep={activeStep}
                                                                              key={'item_disabled_dragged_' + index}
                                                                              index={index}
                                                                              item={section}/>
                                                            )
                                                            : (
                                                                <DisabledSection key={'section_disabled_dragged_' + index}
                                                                                 section={section} index={index}/>
                                                            )
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div ref={firstSectionRef} className="map-preview-mobile">
                                            {
                                                (initialMap && initialMap.length > 0) ?
                                                    <>

                                                        <div className={"text-black ms-2 mt-2 text-xs"}>
                                                            {date && (
                                                                <div className="text-black ms-2 mt-2 text-xs">
                                                                    Use your fingers to navigate the map. <SwipeIcon/>
                                                                </div>
                                                            )}
                                                            <div
                                                                className={"flex flex-row items-center text-black mt-2"}>
                                                                {/*<ZoomInIcon className={"ms- cursor-pointer"} onClick={() => spaceRef.current?.viewPort?.camera.moveBy(0, 0, 0.1)} />*/}
                                                                {/*<ZoomOutIcon className={"ms-2 cursor-pointer"} onClick={() => spaceRef.current?.viewPort?.camera.moveBy(0, 0, -0.1)} />*/}
                                                                {/* <ArrowUpwardIcon  onClick={() =>*/}
                                                                {/*     spaceRef.current?.viewPort?.camera.moveBy(0, -20, 0, undefined, undefined, {*/}
                                                                {/*         durationMilliseconds: 500,*/}
                                                                {/*     })*/}
                                                                {/* } />*/}
                                                                {/*<ArrowBackIcon  onClick={() =>*/}
                                                                {/*    spaceRef.current?.viewPort?.camera.moveBy(-20, 0, 0, undefined, undefined, {*/}
                                                                {/*        durationMilliseconds: 500,*/}
                                                                {/*    })*/}
                                                                {/*} />*/}
                                                            </div>
                                                        </div>

                                                        <Space
                                                            onCreate={initViewPort}
                                                            ref={spaceRef}
                                                            style={{
                                                                height: '380px',
                                                                overflowY: "scroll",
                                                                overflowX: "scroll",
                                                                border: '1px solid black',
                                                                position: 'relative',
                                                                opacity: (!date || isFull) ? '0.3' : ''
                                                            }}>
                                                            {
                                                                initialMap?.map((section, index) => (
                                                                    section.type === 'ITEM' ?
                                                                        (
                                                                            <DisabledItem activeStep={activeStep}
                                                                                          date={date}
                                                                                          key={'item_disabled_dragged_' + index}
                                                                                          index={index}
                                                                                          item={section}/>
                                                                        )
                                                                        : (
                                                                            <DisabledSection
                                                                                key={'section_disabled_dragged_' + index}
                                                                                section={section} index={index}/>
                                                                        )
                                                                ))
                                                            }
                                                        </Space>
                                                    </>
                                                    :
                                                    <Backdrop
                                                        open={true}
                                                        sx={{
                                                            color: '#fff',
                                                            zIndex: (theme) => theme.zIndex.drawer + 1
                                                        }}>
                                                        <CircularProgress color="inherit"/>
                                                    </Backdrop>
                                            }

                                        </div>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default SelectSeats
