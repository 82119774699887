import moment from "moment";
import {checkIfWeekend} from "../../../util";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setGuestUniqueBag, setGuestTotalValues, getGuestBagInformation} from "../../../store/Guest/GuestActions";
import {Avatar} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {changeGuestEmail, getBagInformation, getClientSecret} from "../../../store/Auth/AuthActions.ts";
import BagContent from "../../MyBag/BagContent";
import EmailIcon from '@mui/icons-material/Email';
const ItemsSelected = ({handlePay , handleNotYourEmail}) => {
    const [isSplitPayment, setIsSplitPayment] = useState();
    const {uniqueBag, subTotal, total} = useSelector((state) => state.guest)
    const {
        selectedBeach,
    } = useSelector((state) => state.home)
    const dispatch = useDispatch()
    const [email, setEmail] = useState("");
    const [isGuest , setIsGuest]=useState(false)

    useEffect(() => {
        setIsGuest(sessionStorage.getItem('is_guest') === 'true')
        const email = localStorage.getItem('email');
        setEmail(email);
        dispatch(getBagInformation(localStorage.getItem('selected_beach')))
    }, [dispatch]);



    useEffect(() => {
        let subTotal = 0
        let beforeTotal = 0
        uniqueBag.map((uniqueBagItem) => {
            if (uniqueBagItem.reservation_type === 'default') {
                const totalSplitBed = uniqueBagItem.splitWith ? uniqueBagItem.splitWith?.reduce((accumulator, currentValue, index) => accumulator + currentValue.bedCount, 0) : 0;
                let count = uniqueBagItem.count - totalSplitBed
                beforeTotal = beforeTotal +
                    (uniqueBagItem.count * (checkIfWeekend(uniqueBagItem.selected_date) ?
                        Number(uniqueBagItem.beach_item.pivot.weekend_price) :
                        Number(uniqueBagItem.beach_item.pivot.weekday_price)))
                subTotal = subTotal +
                    (count * (checkIfWeekend(uniqueBagItem.selected_date) ?
                        Number(uniqueBagItem.beach_item.pivot.weekend_price) :
                        Number(uniqueBagItem.beach_item.pivot.weekday_price)))
            } else {
                subTotal = subTotal +
                    (uniqueBagItem.count * Number(uniqueBagItem.beach?.day_pass_price))
            }
        })

        dispatch(setGuestTotalValues(subTotal, beforeTotal))
    }, [uniqueBag]);

    const handleSplitPayment = (event, index) => {
        let data = [...uniqueBag]
        let splitWith = []
        let splitNumber = {}
        if (event.target.checked) {
            splitNumber = data[index].count - 1
            for (let i = 0; i < splitNumber; i++) {
                splitWith.push({
                    email: '',
                    bedCount: 0
                })
            }
        }
        data[index] = {...data[index], splitWith: splitWith, splitWithFriend: event.target.checked}
        setIsSplitPayment(data.some(item => item?.splitWithFriend === true))
        data = handleOddNumber(data, index)
        dispatch(setGuestUniqueBag(data))
    }
    const handleOddNumber = (data, cardIndex) => {
        if (data[cardIndex].count % (data[cardIndex].splitWith?.length + 1) === 0) {
            data[cardIndex].assignAdditionalItem = false
        } else {
            data[cardIndex].assignAdditionalItem = true
        }
        return data
    }

    const handleSplitChange = (event, emailIndex, cardIndex, name) => {
        let data = JSON.parse(JSON.stringify(uniqueBag));
        let total = 0
        let value = ""
        if (name === 'bedCount') {
            total = data[cardIndex].splitWith.reduce((accumulator, currentValue, index) => accumulator + (index !== emailIndex ? currentValue.bedCount : 0), 0);
            let max = (data[cardIndex].count - total) - 1
            value = Math.max(0, Math.min(max, Number(event.target.value)));
        }
        data[cardIndex].splitWith[emailIndex] =
            {
                ...data[cardIndex].splitWith[emailIndex],
                [name]: name === 'email' ? event.target.value : value
            }
        data[cardIndex].splittedNumber = data[cardIndex].splitWith.reduce((accumulator, currentValue, index) => accumulator + currentValue.bedCount, 0);
        dispatch(setGuestUniqueBag(data))
    }
    const handleDeleteEmail = (emailIndex, cardIndex) => {
        let data = JSON.parse(JSON.stringify(uniqueBag));
        data[cardIndex].splitWith.splice(emailIndex, 1)
        data = handleOddNumber(data, cardIndex)
        dispatch(setGuestUniqueBag(data))
    }
    const handlePayNow = (dataToSend) => {
        dispatch(getClientSecret(localStorage.getItem('selected_beach'), dataToSend , selectedBeach.payment_type))
            .then((response) => {
                if(response && selectedBeach.payment_type === 'monty'){
                    window.open(response, '_self');
                }else{
                    handlePay();
                }
               })
    }
    const handleGuestChangeEmail = () =>{
        dispatch(changeGuestEmail())
            .then(()=>{
                handleNotYourEmail()
            })
    }
    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        handleNotYourEmail()
    }


    return (
        <div className={"py-4"}>
            <div className="container">
                <p className="text-primary-dark-green text-2xl font-bold">Selected Items</p>
                <div className="flex items-center text-primary-orange font-bold gap-1 py-3">
                    <EmailIcon/>
                    Logged In as: {email}
                </div>
                {
                    isGuest ?
                        <div>
                            <u onClick={handleGuestChangeEmail} className={"text-primary-orange cursor-pointer"}>Change Email?</u>
                        </div>
                        :
                        <div>
                            <u onClick={handleLogout} className={"text-primary-orange cursor-pointer"}>Log Out?</u>
                        </div>
                }
            </div>
            <BagContent payNow={handlePayNow} beachSelected={selectedBeach} payAtDoor={selectedBeach.pay_at_door}/>
        </div>
    )
}
export default ItemsSelected
