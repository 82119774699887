import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAnalytics} from '../../../../store/Analytics/AnalyticsAction.ts';
import PieChart from './components/PieChart';
import NumberBookingsChart from './components/NumberBookingsChart';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {format} from 'date-fns';
import {DateRange} from 'react-date-range';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {enUS} from 'date-fns/locale';

const Analytics = () => {
    const dispatch = useDispatch();
    const {
        totalNumbers,
        bookingGender,
        bookingAges,
        dailyBookings,
        isLoading,
        error
    } = useSelector((state) => state.analytics);
    const genderColors = ['#F96300', '#00414A'];
    const agesColor = ['#F96300', '#00414A', '#FCB180'];


    const [dateRange, setDateRange] = useState([{ startDate: null, endDate: null, key: 'selection' }]);
    const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        setDateRange([{ ...ranges.selection, startDate: startDate ? new Date(startDate) : null, endDate: endDate ? new Date(endDate) : null }]);
    };

    const handleSubmitDateRange = () => {
        const startDate = dateRange[0].startDate ? format(dateRange[0].startDate, 'yyyy-MM-dd') : '';
        const endDate = dateRange[0].endDate ? format(dateRange[0].endDate, 'yyyy-MM-dd') : '';
        dispatch(getAnalytics({ start_date: startDate, end_date: endDate }));
        setIsDateRangePickerOpen(false);
    };

    useEffect(() => {
        dispatch(getAnalytics({}));
    }, [dispatch]);

    return (
        <div className="container mx-auto px-4">
            <div className="flex items-center gap-2 py-3 mt-4">
                <div className="bg-[#F96300]/10 rounded-lg p-2 cursor-pointer" onClick={() => setIsDateRangePickerOpen(true)}>
                    <CalendarMonthIcon className="text-[#F96300]" />
                </div>
                {dateRange[0].startDate && dateRange[0].endDate ? (
                    <span>{`${format(dateRange[0].startDate, 'MM/dd/yyyy')} - ${format(dateRange[0].endDate, 'MM/dd/yyyy')}`}</span>
                ) : (
                    <span>Start-Date/End-Date</span>
                )}
            </div>

            {isDateRangePickerOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="relative bg-white p-5 rounded-md">
                        <button
                            onClick={() => setIsDateRangePickerOpen(false)}
                            className="text-primary-dark-green absolute top-0 right-2 text-lg cursor-pointer font-bold"
                        >
                            X
                        </button>
                        <DateRange
                            editableDateInputs={true}
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange[0].startDate && dateRange[0].endDate ? dateRange : [{
                                startDate: new Date(),
                                endDate: new Date(),
                                key: 'selection'
                            }]}
                            locale={enUS}
                            rangeColors={['#00414A']}
                            color={'#00414A'}
                        />
                        <button
                            type="button"
                            className="mt-3 text-white rounded-md p-2 bg-primary-dark-green text-sm mx-2"
                            onClick={handleSubmitDateRange}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-1">
                <div className="shadow-2xl rounded-2xl border-2 border-primary-gray/20 p-3">
                    <p className="text-primary-dark-green text-lg md:p-5 max-md:pt-5 font-bold">Total Numbers</p>
                    <div className="border-b-2 border-primary-gray/10 mx-3 mb-5"></div>
                    <div className="flex items-center gap-4 md:px-5 max-md:pt-2">
                        {
                            totalNumbers?.map((analytics) => (
                                <div className="flex flex-col" key={analytics.label}>
                                    <p className="text-primary-dark-green font-bold text-3xl">{analytics.value}</p>
                                    <p className="text-primary-dark-green font-archivo-light md:text-[16px] max-md:text-xs">{analytics.label}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="py-3 shadow-2xl rounded-2xl border-2 border-primary-gray/20 p-3">
                    <p className="text-primary-dark-green text-lg md:p-5 max-md:py-3 font-bold">Booking Gender</p>
                    <div className="border-b-2 border-primary-gray/10 mx-3 mb-5"></div>
                    <div className="w-full h-full flex justify-start">
                        <PieChart
                            data={bookingGender}
                            colors={agesColor}
                            innerRadius="50%"
                        />
                    </div>
                </div>
                <div className="py-3 shadow-2xl rounded-2xl border-2 border-primary-gray/20 p-3">
                    <p className="text-primary-dark-green text-lg md:p-5 max-md:py-3 font-bold">Number of Bookings</p>
                    <div className="border-b-2 border-primary-gray/10 mx-3 mb-5"></div>
                    <div className="w-full h-full">
                        <NumberBookingsChart/>
                    </div>
                </div>
                <div className="py-3 shadow-2xl rounded-2xl border-2 border-primary-gray/20 p-3">
                    <p className="text-primary-dark-green text-lg px-4 py-3 font-bold">Booking Ages</p>
                    <div className="border-b-2 border-primary-gray/10 mx-3 mb-5"></div>
                    <div className="w-full h-full">
                        <PieChart
                            data={bookingAges}
                            colors={agesColor}
                            innerRadius="50%"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Analytics;
