import React, {useEffect, useState} from 'react';
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddNewReservationCard from '../../../../BeachDashboard/BeachMapView/AddNewReservationCard';
import {useDispatch, useSelector} from "react-redux";
import Person2Icon from '@mui/icons-material/Person2';
import {
    handleSelectedDateChange,
    submitAddWalkin,
    searchUsers
} from "../../../../../store/BeachAdmin/BeachAdminActions";
import SearchUsersDropdown from '../../../../BeachDashboard/BeachMapView/AddNewReservationCard/SearchUsersDropdown';
import {setReservationType} from '../../../../../store/Dashboard/Reservations/ReservationsSlice.ts';

const customTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily: 'archivo-regular',
                    '& .MuiInputBase-root': {
                        color: '#A1A1A1',
                        backgroundColor: 'white',
                        borderRadius: '3px',
                        border: "1px solid #A1A1A1",
                        '& .MuiInputBase-input': {
                            color: '#A1A1A1',
                            backgroundColor: 'white',
                            borderRadius: '3px',
                            fontFamily: 'archivo-regular',
                            padding: "8px 3px",
                            boxShadow: 2
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#A1A1A1',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#005F95',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#005F95',
                        },
                    },
                },
            },
        },
    },
});

const AddNewReservation = ({formData, setFormData, finishAddingReservation}) => {
    const dispatch = useDispatch();

    const initialFormData = JSON.parse(localStorage.getItem('reservationFormData')) || {
        email: '',
        numberOfPeople: '',
        selectUser: null,
        firstName: '',
        lastName: '',
        phoneNumber: '',
    };

    const [numberOfPeople, setNumberOfPeople] = useState(Number(initialFormData.numberOfPeople) || 0);

    const [email, setEmail] = useState(initialFormData.email);
    const [lastName, setLastName] = useState(initialFormData.lastName);
    const [firstName, setFirstName] = useState(initialFormData.firstName);
    const [phoneNumber, setPhoneNumber] = useState(initialFormData.phoneNumber);
    const [selectedUser, setSelectedUser] = useState(initialFormData.selectedUser);


    const [isUserSelected, setIsUserSelected] = useState(!!formData.selectedUser);
    const [emailError, setEmailError] = useState(false);
    const reservationType = useSelector((state) => state.reservations.reservationType);

    const resetForm = () => {
        setFormData({
            numberOfPeople: '',
            email: '',
            lastName: '',
            firstName: '',
            phoneNumber: '',
            selectedUser: null
        });
        setNumberOfPeople('');
        setEmail('');
        setLastName('');
        setFirstName('');
        setPhoneNumber('');
        setSelectedUser(null);
    };

    const {
        selectedDate,
        map,
        users,
        isLoadingRes
    } = useSelector((state) => state.beachAdmin);

    const [date, setDate] = useState(selectedDate ? dayjs(selectedDate) : dayjs());

    const [search, setSearch] = useState(formData.selectedUser ? formData.selectedUser.first_name : '');

    const handleReservationTypeChange = (e) => {
        dispatch(setReservationType(e.target.value));
    };

    const handleDateChange = (newValue) => {
        const formattedDate = newValue.format('YYYY-MM-DD');
        setDate(newValue);
        dispatch(handleSelectedDateChange(formattedDate));
        localStorage.setItem('selectedDate', formattedDate);
    };


    const selectUser = (value) => {
        setIsUserSelected(!!value);
        setSelectedUser(value ?? null);
        setFormData(prevData => ({
            ...prevData,
            selectedUser: value
        }));
    };

    const handleUserInfoChange = (value, name) => {
        setSelectedUser(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSearch = (e) => {
        setSearch(e);
        setEmailError(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(searchUsers(search));
        }, 500);
        return () => clearTimeout(timer);
    }, [search, dispatch]);

    const addWalkin = () => {
        let data = map?.filter((item) => item.is_selected_to_add === true);
        dispatch(submitAddWalkin(data, selectedDate));
        finishAddingReservation();
        resetForm();
    };

    useEffect(() => {
        setFormData({email, lastName, firstName, phoneNumber, numberOfPeople, selectedUser});
    }, [email, lastName, firstName, phoneNumber, numberOfPeople, selectedUser, setFormData]);

    useEffect(() => {
        if (selectedDate) {
            setDate(dayjs(selectedDate));
        }
    }, [selectedDate]);

    useEffect(() => {
        const formDataToSave = {email, lastName, firstName, phoneNumber, numberOfPeople, selectedUser};
        localStorage.setItem('reservationFormData', JSON.stringify(formDataToSave));
    }, [email, lastName, firstName, phoneNumber, numberOfPeople, selectedUser]);

    useEffect(() => {
        const savedFormData = JSON.parse(localStorage.getItem('reservationFormData'));
        if (savedFormData) {
            setEmail(savedFormData.email);
            setLastName(savedFormData.lastName);
            setFirstName(savedFormData.firstName);
            setPhoneNumber(savedFormData.phoneNumber);
            setNumberOfPeople(savedFormData.numberOfPeople);
            setSelectedUser(savedFormData.selectedUser);
        }
    }, []);

    return (
        <div className="p-4">
            <div className="">
                <p className="text-primary-gray xl:text-sm md:text-sm py-2">Select the seat you'd like to <br/> reserve
                    for your guest.</p>
                <div>
                    <RadioGroup
                        value={reservationType}
                        className="font-archivo-light text-primary-dark-green"
                    >
                        <FormControlLabel
                            value="walkIn"
                            control={
                                <Radio sx={{color: '#B0CBCE', '&.Mui-checked': {color: '#F96300'}}}/>
                            }
                            label="Walk In"
                            onChange={handleReservationTypeChange}
                            checked={reservationType === 'walkIn'}
                            className="font-archivo-light"
                        />
                    </RadioGroup>
                    <RadioGroup
                        value={reservationType}
                        className="font-archivo-light text-primary-dark-green"
                    >
                        <FormControlLabel
                            value="preBooking"
                            control={
                                <Radio sx={{color: '#B0CBCE', '&.Mui-checked': {color: '#F96300'}}}/>
                            }
                            checked={reservationType === 'preBooking'}
                            onChange={handleReservationTypeChange}
                            label="Pre-Booking"
                            className="font-archivo-light"
                        />
                    </RadioGroup>
                </div>
            </div>
            {reservationType === 'walkIn' && (
                <div>
                    <button
                        className="bg-primary-dark-green text-white rounded-md px-4 py-2 w-full my-4 xl:text-lg md:text-xs"
                        onClick={addWalkin}>Reserve Now
                    </button>
                </div>
            )}
            {reservationType === 'preBooking' && (
                <div className="mt-4">
                    <div className="py-3">
                        <SearchUsersDropdown handleSelect={selectUser} handleSearch={handleSearch} users={users ?? []}
                                             initialValue={selectedUser}/>
                    </div>
                    {/*<p className={"text-primary-dark-green font-bold"}>Reservation Date</p>*/}
                    {/*<ThemeProvider theme={customTheme}>*/}
                    {/*    <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                    {/*        <DatePicker*/}
                    {/*            value={date}*/}
                    {/*            views={['year', 'month', 'day']}*/}
                    {/*            onChange={handleDateChange}*/}
                    {/*            renderInput={(params) => <TextField {...params} />}*/}
                    {/*            minDate={dayjs().startOf('day')}*/}
                    {/*            inputFormat="YYYY-MM-DD"*/}
                    {/*            className={"mt-2 border-2 border-primary-blue rounded-full"}*/}
                    {/*        />*/}
                    {/*    </LocalizationProvider>*/}
                    {/*</ThemeProvider>*/}
                    <div className="mt-4">
                        <label className={"text-primary-dark-green font-bold"}>Number of people</label>
                        <div className="flex items-center gap-2 mt-2">
                            <Person2Icon className="text-primary-dark-green"/>
                            <div>
                                <button
                                    onClick={() => setNumberOfPeople(prev => Math.max(0, prev - 1))}
                                    className="bg-primary-dark-green text-white w-6 h-6 rounded-full"
                                >
                                    -
                                </button>
                                <span className="mx-2">{numberOfPeople}</span>
                                <button
                                    onClick={() => setNumberOfPeople(prev => prev + 1)}
                                    className="bg-primary-dark-green text-white w-6 h-6 rounded-full"
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <AddNewReservationCard
                            numberOfPeople={numberOfPeople}
                            selectedUser={selectedUser}
                            finishAdd={finishAddingReservation}
                            email={email}
                            setEmail={setEmail}
                            lastName={lastName}
                            setLastName={setLastName}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            resetForm={resetForm}
                            setSelectedUser={setSelectedUser}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddNewReservation;
