import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {loginAction} from "../../../../store/Auth/AuthActions.ts";
import {AppDispatch, RootState} from '../../../../store/store';
import {useState} from "react";

interface LoginValues {
    email: string;
    password: string;
}

interface ResetPasswordValues {
    newPassword: string;
    password: string;
}

const LogIn: React.FC = () => {
    const {errorLogin} = useSelector((state: RootState) => state.auth);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const initialLoginValues: LoginValues = {
        email: '',
        password: '',
    };

    const initialResetPasswordValues: ResetPasswordValues = {
        newPassword: '',
        confirmPassword: '',
    };
    const loginValidationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });


    const resetPasswordValidationSchema = Yup.object({
        newPassword: Yup.string().required('New Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Confirm Password is required')
    })

    const resetPassword = () => {
        setIsResetPassword(!isResetPassword)
    }

    const handleResetPassword = (values: ResetPasswordValues) => {
        console.log(values);
    }

    const handleLogin = (values: LoginValues, { setSubmitting }) => {
        dispatch(loginAction({ email: values.email, password: values.password }))
            .then((response) => {
                switch (response.role) {
                    case 'admin':
                        navigate('/all-beaches');
                        return;
                    case 'beach_officer':
                        navigate('/plans');
                        return;
                    case 'beach':
                        navigate('/analytics');
                        return;
                    case 'user':
                        navigate('/');
                        return;
                    default:
                        break;
                }
            })
            .catch((error) => {
                setSubmitting(false);
            });
    };

    return (
        <div className="flex items-center justify-center h-screen w-full bg-cover bg-center"
             style={{backgroundImage: 'url("/images/dashboard/login-bg.jpg")'}}>
            <div
                className="bg-gray-950 bg-gradient from-[#2C2C2C] to-[#686868] bg-opacity-50 p-10 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-3xl font-bold text-center text-white mb-6">Log In</h2>
                <Formik
                    initialValues={isResetPassword ? initialResetPasswordValues : initialLoginValues}
                    validationSchema={isResetPassword ? resetPasswordValidationSchema : loginValidationSchema}
                    onSubmit={isResetPassword ? handleResetPassword : handleLogin}
                >
                    {({isSubmitting}) => (
                        <Form>
                            {isResetPassword ? (
                                <>
                                    <div className="mb-4">
                                        <label className="text-sm font-bold mb-2 text-gray-200">New Password</label>
                                        <Field
                                            className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                            id="newPassword"
                                            name="newPassword"
                                            type="password"
                                            placeholder="Enter new password"
                                        />
                                        <ErrorMessage name="newPassword" component="div"
                                                      className="text-red-500 text-xs mt-1 font-bold"/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="text-sm font-bold mb-2 text-gray-200">Confirm Password</label>
                                        <Field
                                            className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirm new password"
                                        />
                                        <ErrorMessage name="confirmPassword" component="div"
                                                      className="text-red-500 text-xs mt-1 font-bold"/>
                                    </div>
                                    {/*<div className="flex items-center justify-between cursor-pointer">*/}
                                    {/*    <button*/}
                                    {/*        className="bg-primary-dark-green hover:bg-primary-dark-green-dark text-white font-bold py-2 px-4 rounded cursor-pointer"*/}
                                    {/*        type="submit"*/}
                                    {/*        disabled={isSubmitting}*/}
                                    {/*    >*/}
                                    {/*        Reset Password*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </>
                            ) : (
                                <>
                                    <div className="mb-4">
                                        <label className="text-sm font-bold mb-2 text-gray-200">Email</label>
                                        <Field
                                            className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Enter email"
                                        />
                                        <ErrorMessage name="email" component="div"
                                                      className="text-red-500 text-xs mt-1 font-bold"/>
                                    </div>
                                    <div className="mb-6">
                                        <label className="block text-sm font-bold mb-2 text-gray-200">Password</label>
                                        <Field
                                            className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="Enter password"
                                        />
                                        {/*<p className="text-sm text-white text-end underline cursor-pointer"*/}
                                        {/*   onClick={resetPassword}>Reset Password!</p>*/}
                                        <ErrorMessage name="password" component="div"
                                                      className="text-red-500 text-xs mt-1 font-bold"/>
                                    </div>
                                    {errorLogin && (
                                        <div className="text-red-500 text-sm mb-4" role="alert">
                                            Incorrect username or password.
                                        </div>
                                    )}
                                    <div className="flex items-center justify-between">
                                        <button
                                            className="bg-primary-dark-green hover:bg-primary-dark-green/60 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Log In
                                        </button>
                                    </div>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
export default LogIn;
