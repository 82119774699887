import Header from "../../components/Header";
import moment from "moment/moment";
import {checkIfWeekend} from "../../util";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getReservationByCode} from "../../store/Auth/AuthActions.ts";
import LoaderComp from "../../components/LoaderComp";
import {checkInReservation, checkInReservationFromQr} from "../../store/BeachAdmin/BeachAdminActions";
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

const QrReservation = () =>{
    const {
        isLoading,
        qrReservation,
    } = useSelector((state) => state.auth)
    const urlParams = useLocation();
    const dispatch = useDispatch()
    const qrCode = new URLSearchParams(urlParams.search).get('code');
    const navigate = useNavigate()
    const [openSnackBar , setOpenSnackBar] = useState(false)
    useEffect(()=>{
        if(qrCode){
            dispatch(getReservationByCode(qrCode))
        }
    },[])


    const handleCheckInReservation = () =>{
        dispatch(checkInReservationFromQr(qrReservation.beach.id , qrReservation.id))
            .then(()=>{
        setOpenSnackBar(true)
        navigate('/dashboard')
        })
    }


    return (
        <div>
            <Header/>
            <Snackbar anchorOrigin={{vertical:'top' , horizontal:'right'}} open={openSnackBar} autoHideDuration={6000} onClose={()=>setOpenSnackBar(false)}>
                <Alert onClose={()=>setOpenSnackBar(false)} severity={'success'} sx={{ width: '100%' }}>
                    Checked In Successfully
                </Alert>
            </Snackbar>
            {
                isLoading ?
                    <LoaderComp/>
                    :
                    <div className="container"  style={{marginTop: '250px'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 style={{color:'#005662'}}><u>Reservation</u></h2>
                            </div>
                        </div>
                        {
                            Object.keys(qrReservation).length !==0  ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                qrReservation.reservation_type === 'default' ?

                                                    <div className={"bag-card mt-5 mb-5"}>

                                                        <div className="container-fluid ">
                                                            <div className="row card-bag-header py-3 px-3">
                                                                <div className="col-md-12 ">
                                                                    <p className={"bag-card-text m-0"}>You have
                                                                        selected {moment(qrReservation.selected_date).format("dddd MMMM, DD")}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 px-3 mb-3">
                                                                <div className="col-md-5">
                                                                    <img className={"card-beach-image"}
                                                                         src={qrReservation.beach.thumbnail_image}/>
                                                                </div>
                                                                <div className="col">
                                                                    <h5>{qrReservation.beach.name}</h5>

                                                                    <div className="row mb-3">
                                                                        <div className="col-md-9 button-reservation">
                                                                            <div className="row">
                                                                                <div className="col-8 d-flex align-items-center">
                                                                                    <img
                                                                                        alt="Single Bed"
                                                                                        src={qrReservation.item.image}
                                                                                        className="bedImage"
                                                                                    />
                                                                                    <p className={"m-0 ms-2"}>
                                                                                        {qrReservation.item.name} (1)
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        qrReservation.reservation_status === 'checked_in' &&
                                                                        <div className="row mt-3">
                                                                            <div className={"paid"}>
                                                                                Checked In
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={"bag-card mt-5"}>

                                                        <div className="container-fluid ">
                                                            <div className="row card-bag-header py-3 px-3">
                                                                <div className="col-md-12 ">
                                                                    <p className={"bag-card-text m-0"}>You have
                                                                        selected {moment(qrReservation.selected_date).format("dddd MMMM, DD")}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 px-3 mb-3">
                                                                <div className="col-md-5">
                                                                    <img className={"card-beach-image"}
                                                                         src={qrReservation.beach.thumbnail_image}/>
                                                                </div>
                                                                <div className="col">
                                                                    <h5>{qrReservation.beach.name}</h5>
                                                                    <p>Day pass</p>
                                                                    <div className="row mb-3">
                                                                        <div className="col-md-9 button-reservation">
                                                                            <div className="row">
                                                                                <div className="col-8 d-flex align-items-center">
                                                                                    <p className={"m-0 ms-2"}>
                                                                                        <b>Count:</b> {qrReservation.day_pass_count}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                            }
                                        </div>
                                    </div>

                                    {
                                        (qrReservation.is_beach_admin && qrReservation.reservation_status !== 'checked_in') &&
                                        <div className="row justify-content-center" >
                                            <div className="col-md-4  text-center">
                                                <button onClick={()=>handleCheckInReservation()} className={"saveButton"}>Check In</button>
                                            </div>
                                        </div>
                                    }

                                </>
                                :
                                <div className="row justify-content-center mt-4">
                                    <div className="col-md-12">
                                        <div className="alert alert-danger" role="alert">
                                            Incorrect Qr Code.
                                        </div>
                                    </div>
                                </div>


                        }
                    </div>
            }
        </div>
    )
}
export default QrReservation
