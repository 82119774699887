export const ItemTypes = {
    SECTION: 'SECTION',
    ITEM: 'ITEM',
}

export const ItemBackground={
    Grass : '#a5c2a4',
    Beach :'#318da0',
    Sand : '#f5dec4'
}
