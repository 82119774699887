import {useDrop} from "react-dnd";
import React, {useEffect, useRef, useState} from "react";
import {Section} from "./Section";
import {ItemTypes} from "../../ItemTypes";
import './container.css'
import {useDispatch, useSelector} from "react-redux";
import {
    saveBeachMapAction,
    saveBeachMapInBackgroundAction,
    setCurrentSectionAction, setFirstUndoData, setItemIsOverAction,
    setLoading,
    setSavedSectionsAction,
    setSelectedAreaAction, setUndoData
} from "../../store/Map/MapActions";
import {DraggedItem} from "./DraggedItem";
import CustomContextMenu from "../../components/CustomContextMenu";
import DrawingArea from "./DrawingArea";
import NewSectionDetails from "./NewSectionDetails";
import {setMultipleSelectedItems, setUserMultiSelectingAction} from "../../store/MultipleSelect/MultipleSelectActions";
import ItemInformation from "./ItemInformation";
import CaptureModal from "./CaptureModal";

export const Container = () => {
    const {
        isLoading,
        initialSavedSections,
        mapScreenWidth,
        mapScreenHeight,
        selectedArea,
        optionSelected,
        currentSection,
        savedSections,
        isCapturingImage,
        openChangeNumberModal,
        isDraggingInside
    } = useSelector((state) => state.map)
    const {
        userMultiSelecting,
    } = useSelector((state) => state.multipleSelect)
    const dispatch = useDispatch()
    const [currentMapHeight, setCurrentMapHeight] = useState(0);
    const [overlayWidth, setOverlayWidth] = useState()
    const [numberOfRows, setNumberOfRows] = useState()
    const [contextMenuPosition, setContextMenuPosition] = useState({x: 0, y: 0});
    const [showContextMenu, setShowContextMenu] = useState(false);

    const handleResize = () => {
        dispatch(setLoading(true));
        let initialSize = mapScreenWidth === 0 ?  (window.innerWidth * (10/12))  : mapScreenWidth ;
        let currentMapSize = window.innerWidth;
        let currentSize = currentMapSize * (10/12);
        let ratio = initialSize / currentSize;
        let currentMapSizeHeight = window.innerHeight;
        setCurrentMapHeight(window.innerHeight)
        let heightRatio =( mapScreenHeight ??currentMapSizeHeight ) / currentMapSizeHeight;
        let data = initialSavedSections?.map(section => ({
            ...section,
            startX: section?.startX / ratio,
            endX: section?.endX / ratio,
            startY: section?.startY / heightRatio,
            endY: section?.endY / heightRatio,
            height: (section.special_height ?? 50 )/ heightRatio + 'px',
            width: (section.special_width ?? 50 ) / heightRatio + 'px'
        }));
        //width =50px and height = 50px
        let itemCurrentWidth = 50 / heightRatio
        setOverlayWidth((itemCurrentWidth / currentMapSizeHeight) * 100)
        setNumberOfRows(currentMapSizeHeight / itemCurrentWidth)
        dispatch(setSavedSectionsAction(data));
        dispatch(setFirstUndoData(data))
        dispatch(setLoading(false));
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialSavedSections]);

    useEffect(() => {
        handleResize()
    }, [initialSavedSections])

    const moveBox = (item, values) => {
        item.startX = item.startX + values.x
        item.endX = item.endX + values.x
        item.startY = item.startY + values.y
        item.endY = item.endY + values.y
        if (item.endX > (window.screen.width * (10/12))) {
            item.endX = (window.screen.width * 10 / 12)
        }
        if (item.startX < 0) {
            item.startX = 0
        }
        if (item.startY < 0) {
            item.startY = 0
        }
        if (item.endY > window.screen.height) {
            item.endY = window.screen.height - 30
        }

        let data = [...savedSections]
        data[item.index] = item
        dispatch(setSavedSectionsAction(data))
        dispatch(setUndoData(data))

    }
    const moveMultipleItems =  (items, values) => {
        let data = savedSections
        let currentMapSizeHeight = window.innerHeight;
        let heightRatio =( mapScreenHeight ??currentMapSizeHeight ) / currentMapSizeHeight;
        for (let i = 0; i < items.length; i++) {
             data = data.map((mapItem) =>
                mapItem.index === items[i].index ?
                    {...mapItem,
                        multiple_selected:false,
                        startX : mapItem.startX + values.x,
                        startY : mapItem.startY + values.y,
                        height : (mapItem.special_height ?? 50) / heightRatio + 'px',
                        width : (mapItem.special_width ?? 50) / heightRatio + 'px'
                    } : mapItem
            );
        }
        dispatch(setSavedSectionsAction(data))
        dispatch(setUndoData(data))

        dispatch(setMultipleSelectedItems([]))
        dispatch(setUserMultiSelectingAction(false))
    }
    const moveItem = (item, values) => {
        let data = [...savedSections]
        let currentMapSizeHeight = window.innerHeight;
        let heightRatio =( mapScreenHeight ??currentMapSizeHeight ) / currentMapSizeHeight;
        if (!item.hasOwnProperty('index')) {
            item.startX = Math.abs(values.x) - 20
            item.startY = Math.abs(values.y) - 15
            item.index = savedSections[savedSections.length -1]?.hasOwnProperty('index') ? (savedSections[savedSections.length -1]?.index + 1 ) : 0
            item.height = (item.special_height ?? 50 ) / heightRatio + 'px'
            item.width = (item.special_width ?? 50 ) / heightRatio + 'px'
            data.push(item)
        } else {
            item.startX = item.startX + values.x
            item.startY = item.startY + values.y
            item.height = (item.special_height ?? 50) / heightRatio + 'px'
            item.width = (item.special_width ?? 50) / heightRatio + 'px'
            data[item.indexInArray] = item
        }
        dispatch(setSavedSectionsAction(data))
        dispatch(setUndoData(data))

    }

    useEffect(() => {
        const saveMapInBackground =  () => {
            let currentMapWidth = window.innerWidth * (10/12);
            let currentMapSizeHeight = window.innerHeight;
            dispatch(saveBeachMapInBackgroundAction(savedSections ,currentMapWidth , currentMapSizeHeight ))
        };

        // Set up the interval (2 minutes = 120,000 milliseconds)
        const intervalId = setInterval(saveMapInBackground, 90000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [savedSections]);

    const [{canDrop, isOver}, drop] = useDrop(
        () => ({
            accept: [ItemTypes.SECTION, ItemTypes.ITEM],
            drop(item, monitor) {
                let delta = monitor.getDifferenceFromInitialOffset()
                if (item.hasOwnProperty('data')) {
                    moveMultipleItems(item.data, delta)
                } else {
                    if (item.type === 'ITEM') {
                        if (item.hasOwnProperty('index')) {
                            moveItem(item, delta)
                        } else {
                            delta = monitor.getClientOffset()
                            moveItem(item, delta)
                        }
                    } else {
                        moveBox(item, delta)

                    }
                    return undefined
                }

            },
            collect: (monitor) => {
                dispatch(setItemIsOverAction(monitor.canDrop()))
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                }
            },
        }),
        [moveBox],
    )

    const mapRef = useRef(null);


    const handleMouseDown = (event) => {
        if (currentSection) return;
        const {clientX, clientY} = event;
        const {left, top} = mapRef.current.getBoundingClientRect();
        const startX = clientX - left;
        const startY = clientY - top;

        dispatch(setSelectedAreaAction({startX, startY, endX: startX, endY: startY}))
    };

    const handleMouseMove = (event) => {
        if (currentSection) return;

        if (!selectedArea) return;
        const {clientX, clientY} = event;
        const endX = clientX;
        const endY = clientY;


        dispatch(setSelectedAreaAction({...selectedArea, endX, endY}))
    };
    const handleMouseUp = (e) => {
        if (currentSection) return;
        if (selectedArea) {
            const newSection = {
                startX: selectedArea.startX,
                startY: selectedArea.startY,
                endX: selectedArea.endX,
                endY: selectedArea.endY,
                name: 'Section ' + (savedSections.length + 1), // Example name
            };
            dispatch(setCurrentSectionAction(newSection))
            dispatch(setSavedSectionsAction([...savedSections, newSection]))
            dispatch(setUndoData([...savedSections, newSection]))

        }
        dispatch(setSelectedAreaAction(null))
    };
    const handleRightClick = (e) => {
        e.preventDefault();
        const x = e.clientX;
        const y = e.clientY
        setContextMenuPosition({x, y});
        setShowContextMenu(true);
    }
    const closeContextMenu = () => {
        setShowContextMenu(false);
    };
    return (
        <>
            {!isLoading &&
                (
                    <div onClick={closeContextMenu} onContextMenu={handleRightClick}
                         style={{opacity: userMultiSelecting && '0.5'}}>
                        {showContextMenu && (
                            <CustomContextMenu
                                onClose={closeContextMenu}
                                x={contextMenuPosition.x}
                                y={contextMenuPosition.y}
                            />
                        )
                        }
                        {
                            optionSelected === 'draw' ?
                                <div
                                    ref={mapRef}
                                    style={{height: '99.9vh', width: '100%', border: '1px solid black'}}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                >
                                    {
                                        !isLoading && (
                                            savedSections?.map((section, index) => (
                                                <Section key={'section_' + index} section={section} index={index}
                                                         optionSelected={optionSelected}/>
                                            ))
                                        )
                                    }


                                </div>
                                :
                                <div
                                    ref={drop}
                                    style={{
                                        height: '99.9vh',
                                        width: '100%',
                                        position: 'relative',
                                        border: '1px solid black',
                                        background: isOver ? '#defae2' : 'white'
                                    }}
                                >
                                    <>
                                        {/*<FieldDragLayer/>*/}
                                        {
                                            isDraggingInside &&
                                            <div>
                                                {/* Render horizontal grid lines */}
                                                {Array.from({length: numberOfRows}).map((_, index) => (
                                                    <div key={`horizontal-line-${index}`}
                                                         className="grid-line-horiz horizontal-line"
                                                         style={{top: `${index * overlayWidth}%`}}/>
                                                ))}

                                                {/* Render vertical grid lines */}
                                                {Array.from({length: numberOfRows}).map((_, index) => (
                                                    <div key={`vertical-line-${index}`}
                                                         className="grid-line-vertical vertical-line"
                                                         style={{left: `${index * overlayWidth}%`}}/>
                                                ))}
                                            </div>
                                        }
                                        {
                                            savedSections?.map((section, index) => (
                                                section?.type === 'ITEM' ?
                                                    (
                                                        <DraggedItem key={'item_dragged_' + index} section={section} index={index}/>
                                                    )
                                                    : (
                                                        <Section key={'section_dragged_' + index} section={section}
                                                                 index={index}/>
                                                    )
                                            ))

                                        }
                                    </>

                                </div>
                        }
                        {selectedArea && (<DrawingArea/>)}

                        {currentSection && (<NewSectionDetails currentMapHeight={currentMapHeight}/>)}
                        {openChangeNumberModal && <ItemInformation />}
                        {isCapturingImage && <CaptureModal />}
                    </div>
                )

            }
        </>

    )
}

