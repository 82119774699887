import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import GuestsMultiSelect from "./GuestsMultiSelect.tsx";

const validationSchema = Yup.object().shape({
    points: Yup.number().required('Points are required').min(1, 'Points must be at least 1'),
});

const AssignPointsModal = ({isOpen , onClose})=>{
    return(
        <Modal open={isOpen}>
            <Box
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white p-4 rounded-lg shadow-lg">
                <h2 className="text-lg text-primary-dark-green font-bold mb-4 text-center">Assign Points</h2>
                <p className="mb-4 text-primary-gray text-xs">Please indicate the number of points you wish to allocate
                    to the guests.</p>

                <Formik
                    initialValues={{ points: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log(values);
                        onClose();
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mb-4">
                                <label className="block text-primary-dark-green text-sm font-bold mb-2">Guests</label>
                                <GuestsMultiSelect/>
                            </div>
                            <div className="mb-4">
                                <label className="block text-primary-dark-green text-sm font-bold mb-2">Beach Points</label>
                                <Field
                                    className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded"
                                    id="points"
                                    name="points"
                                    type="number"
                                    placeholder="Enter number of points"
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-primary-dark-green hover:bg-primary-dark-green-dark text-white font-bold py-2 px-4 rounded"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Add
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    )
}
export default AssignPointsModal;
