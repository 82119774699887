import React from "react";
import {InfoWindow} from "@react-google-maps/api"
import './style.css'

const PinDetails = ({ position, onCloseClick,children}) => {
    return (
               <InfoWindow position={position} onCloseClick={onCloseClick}
                 options={{ pixelOffset: new window.google.maps.Size(40,-20) }} // Adjust the offset as needed
               >
                   <div className="custom-info-window">
                       {children}
                   </div>
               </InfoWindow>
    );
};
export default PinDetails;
