import React from "react";
import Slider from "react-slick";
import NextArrow from "../FilteredSlider/components/NextArrow";
import PrevArrow from "../FilteredSlider/components/PrevArrow";
import { useNavigate } from "react-router-dom";

const DefaultSlider = ({ slidesData }) => {
    const navigate = useNavigate();

    const settings = {
        dots: false,
        infinite: slidesData.length > 3,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const selectBeach = (id) => {
        localStorage.setItem("selected_beach", id);
        navigate("/beach-info");
    };

    return (
        <div>
            <Slider {...settings}>
                {slidesData.map((beach, index) => (
                    <div
                        key={index}
                        className="slide px-2 cursor-pointer"
                        onClick={() => selectBeach(beach.id)}
                    >
                        <img
                            src={beach.thumbnail_image}
                            alt={beach.name}
                            className="rounded-lg md:h-[350px] max-md:h-[200px] w-full object-cover"
                        />
                        <div className="md:flex items-center gap-2 my-2 pt-2 max-md:hidden">
                            {beach?.tags?.map((tag) => (
                                <div
                                    key={tag.value}
                                    className="bg-primary-orange/20 text-primary-orange max-md:my-1 max-md:py-2 max-md:text-center md:text-md max-md:text-xs md:px-2 rounded-md"
                                >
                                    {tag.value}
                                </div>
                            ))}
                        </div>
                        <h2 className="text-primary-dark-green md:text-xl max-md:text-sm max-md:pt-3 font-archivo">
                            {beach.name}
                        </h2>
                        <div className="flex items-center md:gap-2 text-lg pt-2">
                            <img src="/svgs/location.svg" alt="Location" />
                            <p className="text-primary-gray max-md:text-sm font-archivo">
                                {beach.location}
                            </p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default DefaultSlider;
