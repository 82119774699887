import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ReservationState {
    isLoading: boolean;
    reservations: any[];
    reservationType: string;
}

const initialState: ReservationState = {
    isLoading: false,
    reservations: [],
    reservationType: 'walkIn',
    errorMessage: null,
};

export const reservationsSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setReservations: (state, action: PayloadAction<any[]>) => {
            state.reservations = action.payload;
        },
        updateReservationStatus: (state, action: PayloadAction<{ id: number; status: string }>) => {
            const {id, status} = action.payload;
            const reservation = state.reservations.find(res => res.id === id);
            if (reservation) {
                reservation.status = status;
            }
        },
        setReservationType: (state, action: PayloadAction<string>) => {
            state.reservationType = action.payload;
        },
        setErrorMessage: (state, action: PayloadAction<string | null>) => {
            state.errorMessage = action.payload;
        }
    },
});

export const {
    setLoading,
    setReservations,
    updateReservationStatus,
    setReservationType,
    setErrorMessage
} = reservationsSlice.actions;
export default reservationsSlice.reducer;
