import React, { useState } from 'react';

interface DashboardTab {
    label: string;
    value: string;
}

interface TabProps {
    tabs: DashboardTab[];
    selectedTab: string;
    onTabChange: (tab: string) => void;
}
const DashboardTab = ({ tabs, selectedTab, onTabChange } : TabProps) => {
    return (
        <div className="flex items-center gap-2 mt-4">
            {tabs.map((tab) => (
                <button
                    key={tab.value}
                    type="button"
                    className={`bg-primary-dark-green text-white max-md:text-xs rounded-md px-3 py-1 ${selectedTab === tab.value ? 'opacity-100' : 'opacity-50'}`}
                    onClick={() => onTabChange(tab.value)}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    );
};

export default DashboardTab;
