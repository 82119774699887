import {useSelector} from "react-redux";
import './Plans.css'
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header";
export const Plans = () =>{
    const navigate = useNavigate();
    const handleBuildMap = () =>{
        navigate('/map')
    }
    const handleRedirectToDashboard = () =>{
        navigate('/dashboard')
    }

    const {plans , selectedPlanId} = useSelector((state)=> state.map)
    return (
            <>
                <div className={"container"} style={{height:'100vh' , marginTop:'200px'}}>
                    {/*<div className="row align-items-center mt-5" >*/}
                    {/*    <div className="col-md-12">*/}
                    {/*        <h1 className={"text-6xl text-primary-blue/30 mb-2"}>Create or edit plan</h1>*/}
                    {/*        <button onClick={() => handleBuildMap()} className={"bg-primary-blue text-white rounded-full py-2.5 mt-1 col-3"}>Start Building My Plan</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row mt-5">*/}
                    {/*    <h1 className={"text-primary-blue text-7xl"}><b>Or</b></h1>*/}
                    {/*</div>*/}
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h1 className={"text-6xl text-primary-blue/30 mb-2"}>Go to dashboard.</h1>
                            <button onClick={() => handleRedirectToDashboard()} className={"bg-primary-blue text-white rounded-full py-2.5 mt-1 col-3"}>Redirect</button>
                        </div>
                    </div>
                </div>

            </>
    )
}
export default Plans
