import React from "react";
import {useSelector} from "react-redux";

const DrawingArea = () =>{
    const {selectedArea } = useSelector((state) => state.map)
    return (
        <div
            style={{
                position: 'absolute',
                left: selectedArea.startX < selectedArea.endX ? selectedArea.startX : selectedArea.endX ,
                top: selectedArea.startY < selectedArea.endY ? selectedArea.startY : selectedArea.endY ,
                width: Math.abs(selectedArea.endX - selectedArea.startX) ,
                height: Math.abs(selectedArea.endY - selectedArea.startY) ,
                border: '2px dashed #599198',
                backgroundColor: 'white',
                pointerEvents: 'none',
            }}>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: '50%',
                width: '10px',
                backgroundColor: '#005662',
                height: '10px',
                transform: 'translate( -50% , -50%)'
            }}/>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                borderRadius: '50%',
                width: '10px',
                backgroundColor: '#005662',
                height: '10px',
                transform: 'translate( 50% , -50%)'
            }}/>
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                borderRadius: '50%',
                width: '10px',
                backgroundColor: '#005662',
                height: '10px',
                transform: 'translate( -50% , 50%)'
            }}/>
            <div style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                borderRadius: '50%',
                width: '10px',
                backgroundColor: '#005662',
                height: '10px',
                transform: 'translate( 50% , 50%)'
            }}/>
        </div>
    )
}
export default DrawingArea
