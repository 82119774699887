import {useEffect, useState} from "react";
import './DiscoverAll.css'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filterBeach} from "../../../store/Home/HomeActions";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Slider from '@mui/material/Slider';
import styled from "@emotion/styled";
import LoaderComp from "../../../components/LoaderComp";
 const SliderStyle = {
    color: '#005662',
}
const CustomSlider = styled(Slider)({
    ...SliderStyle,
});


export const DiscoverAll = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isMobileScreen , setIsMobileScreen] = useState(false)
    const {allBeaches ,locationFilters,isLoadingFilter, maxPrice , isLoading} = useSelector((state) => state.home)
    const [filter, setFilter] = useState({
        priceOne:0,
        priceTwo:Number(maxPrice),
        search: '',
        location: '',
        showNearMe: false,
    })
    useEffect(()=>{
        setFilter(prev => ({
            ...prev,
            priceTwo: maxPrice
        }))
    },[maxPrice])
    const selectBeach = (id) => {
        localStorage.setItem('selected_beach', id)
        navigate('/beach-info')
    }
    const handleFilterChange = (name, value) => {
        setFilter(prev => ({
            ...prev,
            [name]: value
        }))
    }
    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleGetLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setFilter(prev => ({
                    ...prev,
                    longitude:longitude,
                    latitude:latitude
                }))
            }, (error) => {
                console.error("Error getting location:", error);
            });
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };

    useEffect(() => {
        if(filter.showNearMe){
            handleGetLocation()
        }else{
            setFilter(prev => ({
                ...prev,
                longitude:'',
                latitude:''
            }))
        }
    }, [filter.showNearMe]);

    useEffect(() => {
       const timer = setTimeout(function (){
           if(filter.showNearMe){
               if(!filter.longitude || !filter.latitude){
                   return
               }
           }
           dispatch(filterBeach(filter))

        },500)
        return () => clearTimeout(timer);
    }, [filter]);
    return (
        <div style={{backgroundColor: '#ebf2f3'}} className="container-fluid  pb-5">
            {
                isLoadingFilter &&
                <LoaderComp/>
            }
            <div className="row  pt-lg-5 pt-md-5 pt-2">
                <div className="col-md-12 text-center">
                    <h2 className={"header-text"}>
                        Discover all
                    </h2>
                </div>
            </div>

            <div className={"discover-all-container"}>
                {
                   !isMobileScreen &&
                    <div className="row mt-3 px-3">
                        <div className="col">
                            <p className={"m-0 filter-text"}> Search By Beach Name</p>
                        </div>
                        <div className="col-md-3 ms-2">
                            <p className={"m-0 filter-text"}>Filter By Beach Location</p>
                        </div>
                        <div className="col-md-3 ms-2">
                            <p className={"m-0 filter-text"}>Show resorts near me</p>
                        </div>
                        <div className="col-md-3 ms-2">
                            <p className={"m-0 filter-text"}>Filter by price : ($)</p>
                        </div>
                    </div>
                }

                <div className="filter-card row px-3 pb-3 mt-lg-0 mt-md-0 mt-3">
                            <div className="col-6 col-lg col-md">
                                <input value={filter.search}
                                       onChange={(event) => handleFilterChange('search', event.target.value)}
                                       placeholder={"Search Beaches"} className={"no-borders-white"}/>
                            </div>
                            <div className="col-md-3 col-lg-3 col-6 ms-lg-2 ms-md-2 ms-0">
                                <select value={filter.location}
                                        onChange={(event) => handleFilterChange('location', event.target.value)}
                                        className={"form-control no-borders-white"}>
                                    <option value={""}></option>
                                    {
                                        locationFilters.map((item, index) => (
                                            <option key={"location_" + index} value={item.location}>{item.location}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-md-3 col-lg-3 col-12 mt-3 mt-lg-0 mt-md-0 ms-lg-2 ms-md-2 ms-0 d-flex align-items-center" style={{
                                cursor: 'pointer',
                                background: 'white',
                                borderRadius: '20px',
                                padding: '10px 10px'
                            }} onClick={() => handleFilterChange('showNearMe', !filter.showNearMe)}>
                                <MyLocationIcon style={{
                                    cursor: 'pointer',
                                    color: filter.showNearMe ? '#005662' : 'black',
                                    fontSize: '25px'
                                }}/>
                                <p style={{color: filter.showNearMe ? '#005662' : 'black'}} className={"m-0 ms-2"}>Show
                                    near me</p>
                            </div>
                            <div className="col-md-3 col-lg-3 col-12  mt-3 mt-lg-0 mt-md-0 ms-lg-2 ms-md-2 ms-0 d-flex align-items-center px-lg-3 px-md-3 px-3" style={{
                                cursor: 'pointer',
                                background: 'white',
                                borderRadius: '20px',
                            }} >
                                {
                                    !isLoading &&
                                        <CustomSlider
                                            getAriaLabel={() => 'Price'}
                                            orientation="horizontal"
                                            valueLabelDisplay="auto"
                                            max={Number(maxPrice)}
                                            value={[filter.priceOne,filter.priceTwo]}
                                            onChange={(event, newValue) =>
                                                setFilter(prev => ({
                                                    ...prev,
                                                    priceOne: newValue[0],
                                                    priceTwo: newValue[1],
                                                }))
                                            }
                                            onChangeCommitted={()=>console.log("")}
                                        />
                                }

                            </div>
                        </div>
                <div className="row">
                    {
                        allBeaches.map((beach, index) => (
                            <div key={'beach_' + index} onClick={() => selectBeach(beach.id)}
                                 className={"col-md-4 mt-5"} style={{cursor: 'pointer'}}>
                                <div>
                                    <img className="beach-image" src={beach.thumbnail_image}/>
                                </div>
                                <div className={"mt-3"}>
                                    <h4 className={"header-text"}>{beach.name}</h4>
                                </div>
                                <div>
                                    <p className={"header-text m-0"}>{beach.location}</p>
                                </div>
                            </div>
                        ))
                    }
                    {
                        allBeaches.length===0 &&
                        <div className={"mt-2"}>
                            <h3 className={"header-text"}>No resorts found.</h3>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default DiscoverAll
