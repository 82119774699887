import React from 'react';
import { MenuItem, Select } from "@mui/material";
import styled from "@emotion/styled";

const CustomDropdownField = styled(Select)(({ theme }) => ({
    '.MuiSelect-select': {
        backgroundColor: 'transparent',
        height: '50px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        padding:"0px",
        '&:focus': {
            backgroundColor: 'transparent',
            padding:"0px"
        },
    },
    '.MuiSvgIcon-root': {
        color: 'white'
    },
    '&.MuiInput-underline:before': {
        borderBottom: 'none'
    },
    '&.MuiInput-underline:after': {
        borderBottom: 'none'
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
        padding:"0px"
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
        padding:"0px"
    },
}));

export const Dropdown = (props) => {
    const { items, className, value, handleChange, minWidth } = props;

    return (
        <CustomDropdownField
            onChange={(event) => handleChange(event.target.value)}
            value={value}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            className={className}
            style={{ minWidth: minWidth }}
            MenuProps={{
                PaperProps: {
                    style: {
                        backgroundColor: 'transparent',
                        color: 'white',
                        borderColor: 'transparent',
                        padding:'0px',
                    },
                },
            }}
        >
            {items.map((item, index) => (
                <MenuItem key={index} value={item.id ?? item.location} style={{ display: 'flex', alignItems: 'center' }}>
                    {item.image && <img  height={10} width={30} src={item.image} alt="" />}
                </MenuItem>
            ))}
        </CustomDropdownField>
    );
};
