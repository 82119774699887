import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {forgotPasswordAction, loginAction} from "../../../store/Auth/AuthActions.ts";
import {toggleBagSideBar} from "../../../store/Home/HomeActions";
import {useDispatch} from "react-redux";

const ForgotPassword = () => {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1)
    }

    const [loginCreds, setLoginCreds] = useState({email: ""});
    const [wrongCredentials, setWrongCredentials] = useState(false)

    const dispatch = useDispatch();

    function handleUserRedirect(response) {
        navigate('/checkOtp')
    }
    function forgotPassword() {
        if (loginCreds.email) {
            dispatch(forgotPasswordAction(loginCreds))
                .then((response) => {
                    handleUserRedirect(response)
                })
                .catch((error) => {
                    setWrongCredentials(true)
                })
        }
    }
    return (
        <section
            className="background-image"
            style={{backgroundImage: `url(/main-background.png)`}}>
            <div className="overlay">
                <div className="container">
                    <button className="exit mb-5" onClick={goBack}>
                        <CloseIcon/>
                    </button>
                    <h1 className="my-5 text-3xl">
                        Forgot Password
                    </h1>
                    <div className="">
                        <input
                            className="inputStyle md:w-[400px]"
                            type="text"
                            value={loginCreds.email}
                            onChange={(e) =>
                                setLoginCreds((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                }))
                            }
                            placeholder="Email"
                        />
                    </div>
                    {
                        wrongCredentials &&
                        <div className="row w-100 justify-content-center mt-2">
                            <div className="col-md-6">
                                <div className="alert alert-danger text-center py-2" role="alert">
                                    Incorrect email!
                                </div>
                            </div>
                        </div>
                    }
                    <button
                        className="font-bold flex justify-content-end bg-white text-primary-dark-green px-4 py-1 mt-4 cursor-pointer rounded-full" onClick={forgotPassword}>
                        Send email
                    </button>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword;
