import React, {useState} from 'react';
import {beachInfoData} from "../../../constants/beachInfoData";
import {useSelector} from "react-redux";

export const BeachGalleryImages = () => {
    const {selectedBeach} = useSelector((state) => state.home);

    const images = 8;
    const [visibleImages, setVisibleImages] = useState(images);
    const [show, setShow] = useState(false);
    const [isHovered, setIsHovered] = useState(null);
    const [lightboxImage, setLightboxImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);

    const showMore = () => {
        const remainingImages = beachInfoData.beachImages.length - visibleImages;
        const showImages = remainingImages > images ? images : remainingImages;
        setVisibleImages(visibleImages + showImages);

        if (remainingImages <= images) {
            setShow(true);
        }
    };

    const showLess = () => {
        setVisibleImages(images);
        setShow(false);
    };

    const openLightbox = (image, index) => {
        setLightboxImage(image);
        setCurrentImage(index);
    };

    const closeLightbox = () => {
        setLightboxImage(null);
        setCurrentImage(null);
    };

    const showPrevImage = (e) => {
        e.stopPropagation();
        const prevImage = (currentImage - 1 + selectedBeach.gallery.length) % selectedBeach.gallery.length;
        setLightboxImage(selectedBeach.gallery[prevImage].image);
        setCurrentImage(prevImage);
    };

    const showNextImage = (e) => {
        e.stopPropagation();
        const nextImage = (currentImage + 1) % selectedBeach.gallery.length;
        setLightboxImage(selectedBeach.gallery[nextImage].image);
        setCurrentImage(nextImage);
    };

    return (
        <div className="flex flex-col justify-center mt-10">
            <div className="grid md:grid-cols-4 max-md:grid-cols-2 max-md:gap-4 md:pt-10">
                {selectedBeach?.gallery?.slice(0, visibleImages).map((image, index) => (
                    <div
                        key={index}
                        onMouseEnter={() => setIsHovered(index)}
                        onMouseLeave={() => setIsHovered(null)}
                        className="cursor-pointer overflow-hidden"
                    >
                        <div className="cursor-pointer flex justify-between relative md:mx-2 overflow-hidden md:my-3">
                            <img
                                src={image.image}
                                className={`rounded-lg h-[300px] w-full object-cover ${isHovered === index ? 'bg-primary-dark-green opacity-40' : ''}`}
                                onClick={() => openLightbox(image.image, index)}
                            />
                            {isHovered === index && (
                                <div
                                    className="absolute inset-0 rounded-lg flex items-center justify-center bg-primary-dark-green/40"
                                    onClick={() => openLightbox(image.image, index)}
                                >
                                    <img src="/svgs/loop.svg" className="w-10 h-10"/>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {lightboxImage && (
                <div
                    className="fixed inset-0 !z-40 flex items-center justify-center bg-black bg-opacity-50"
                    onClick={closeLightbox}
                >
                    <div className="relative">
                        <button
                            className="absolute top-1/2 left-0 transform -translate-y-1/2 md:left-5 text-white bg-primary-dark-green rounded-full w-10 h-10 aspect-square"
                            onClick={showPrevImage}
                            disabled={selectedBeach.gallery.length <= 1}
                            style={{ zIndex: 50 }}
                        >
                            ❮
                        </button>
                        <img
                            src={lightboxImage}
                            alt="Lightbox"
                            className="w-[600px] h-[400px] object-cover rounded-lg"
                            onClick={(e) => e.stopPropagation()}
                        />
                        <button
                            className="absolute top-2 right-2 md:top-5 md:right-5 z-50 cursor-pointer w-8 h-8 md:w-10 md:h-10 rounded-full text-xl text-white bg-primary-dark-green"
                            onClick={closeLightbox}
                        >
                            x
                        </button>
                        <button
                            className="absolute top-1/2 right-0 transform z-50 -translate-y-1/2 md:right-5 text-white bg-primary-dark-green rounded-full w-10 h-10 aspect-square"
                            onClick={showNextImage}
                            disabled={selectedBeach.gallery.length <= 1}
                        >
                            ❯
                        </button>
                    </div>
                </div>
            )}
            {!show && visibleImages < selectedBeach?.gallery?.length && (
                <button
                    type="button"
                    className="mt-5 text-primary-orange text-xl font-bold"
                    onClick={showMore}
                >
                Show More
                </button>
            )}
            {show && (
                <button
                    type="button"
                    className="mt-5 text-primary-orange text-xl font-bold"
                    onClick={showLess}
                >
                    Show Less
                </button>
            )}
        </div>
    );
};

export default BeachGalleryImages;
