export const dashboardHeader = [
    {
        id: 1,
        image: "../../../svgs/dashboard/home.svg",
        title: "Analytics",
        path:'/analytics'
    },
    {
        id: 2,
        image: "../../../svgs/dashboard/map.svg",
        title: "Map Builder",
        path:'/map-builder'
    },
    {
        id: 3,
        image: "../../../svgs/dashboard/reservations.svg",
        title: "Reservations",
        path:'/reservations/map?mode=reservationDetails'
    },

    {
        id: 5,
        image: "../../../svgs/dashboard/guests.svg",
        title: "Guests",
        path:'/guests'
    },
    {
        id: 6,
        image: "../../../svgs/dashboard/reviews.svg",
        title: "Reviews",
        path:'/reviews'
    },
    {
        id: 4,
        image: "../../../svgs/dashboard/orders.svg",
        title: "Orders",
        path:'/orders'
    },
    {
        id: 7,
        image: "../../../svgs/dashboard/menu-builder.svg",
        title: "Menu Builder",
        path:'/comingSoon'
    },
    {
        id: 8,
        image: "../../../svgs/dashboard/settings.svg",
        title: "Settings",
        path:'/settings'
    }
]
