import {mapSlice} from "./MapSlice"
import axios from 'axios'

export const setSelectedAreaAction = (data) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setSelectedArea(data))
    }
}

export const setSelectedSectionIndexAction = (data) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setSelectedSectionIndex(data))
    }
}

export const setOptionSelectedAction = (data) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setOptionSelected(data))
    }
}
export const setCurrentSectionAction = (data) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setCurrentSection(data))
    }
}
export const setSavedSectionsAction = (data) => {
    return async (dispatch) => {
        await dispatch(mapSlice.actions.setSavedSections(data))
    }
}
export const setShowRotateModal = (show , data , index) => {
    return async (dispatch) => {
        await dispatch(mapSlice.actions.setShowRotateModal(show))
        await dispatch(mapSlice.actions.setShowRotateModalItem(data))
        await dispatch(mapSlice.actions.setShowRotateModalItemIndex(index))
    }
}
export const openImageCaptureSection = (data , value , index) => {
    return async (dispatch) => {
         dispatch(mapSlice.actions.setCaptureImageSection(value))
         dispatch(mapSlice.actions.setItemCapture(data))
         dispatch(mapSlice.actions.setItemCaptureIndex(index))
    }
}

export const setLoading = (value) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setLoading(value))
    }
}

export const setItemIsOverAction = (value) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setItemIsOver(value))
    }
}

export const setUndoData = (data) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setWatchedSavedSections(data))
    }
}
export const setFirstUndoData = (data) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setFirstUndoData(data))
    }
}

export const setItemIsDraggingInsideAction = (value) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.setItemIsDraggingInside(value))
    }
}
export const undoOptionHandled = (value) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.handleUndo())
    }
}
export const redoOptionHandled = (value) => {
    return (dispatch) => {
        dispatch(mapSlice.actions.handleRedo())
    }
}

export const getBuilderInfo = () => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setIsLoading(true))
        const response = await axios.get('beach/' + sessionStorage.getItem('beach_id') + '/section-builder')
        dispatch(mapSlice.actions.setSectionBuilder(response.data.data))
        dispatch(mapSlice.actions.setIsLoading(false))
    }
}
export const directFileUpload = (data) => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setIsLoading(true))
        const response = await axios.post('/generate-image-url' , data)
        dispatch(mapSlice.actions.setIsLoading(false))
        return response.data.data
    }
}

export const getAllBeaches = () => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setIsLoading(true))
        const response = await axios.get('/beaches')
        dispatch(mapSlice.actions.setAllBeaches(response.data.data))
        dispatch(mapSlice.actions.setIsLoading(false))
    }
}
export const setBeachId = (id) => {
    return async (dispatch) => {
        sessionStorage.setItem('beach_id', id)
    }
}
export const resetMap = () => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setSectionBuilder({
            items: [],
            section_categories: []
        }))
    }
}
export const saveBeachMapAction = (map, width, height) => {

    return async (dispatch) => {
        dispatch(mapSlice.actions.setIsLoading(true))
        let availableSeats = map.reduce((count, obj) => {
            if (obj.is_a_seat === 1) {
                return count + 1;
            } else {
                return count;
            }
        }, 0);
        map = map.map((item, key) => (
            {
                ...item,
                index: key,
            }
        ))
        await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/map', {
            map: map,
            total_available_seats: availableSeats,
            map_height: height,
            map_width: width
        })
        dispatch(mapSlice.actions.setIsLoading(false))
    }
}
export const saveBeachMapInBackgroundAction = (map, width, height) => {
    return async (dispatch) => {
        let availableSeats = map.reduce((count, obj) => {
            if (obj.is_a_seat === 1) {
                return count + 1;
            } else {
                return count;
            }
        }, 0);
        map = map.map((item, key) => (
            {
                ...item,
                index: key,
            }
        ))
        await axios.post('beach/' + sessionStorage.getItem('beach_id') + '/map', {
            map: map,
            total_available_seats: availableSeats,
            map_height: height,
            map_width: width
        })
    }
}
export const getMapByBeachId = () => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setIsLoading(true))
        const response = await axios.get('beach/' + sessionStorage.getItem('beach_id') + '/map')
        dispatch(mapSlice.actions.setSavedSections(response.data.data.map))
        dispatch(mapSlice.actions.setInitialSavedSections(response.data.data.map))
        dispatch(mapSlice.actions.setMapScreenHeight(response.data.data.map_initial_height))
        dispatch(mapSlice.actions.setMapScreenWidth(response.data.data.map_initial_width))
        dispatch(mapSlice.actions.setIsLoading(false))
    }
}

export const handleGenerateItem = (item) => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setAutoGenerateItem(item))
        dispatch(mapSlice.actions.setAutoGenerateModal(true))
    }
}
export const closeAutoGenerateModal = () => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setAutoGenerateItem({}))
        dispatch(mapSlice.actions.setAutoGenerateModal(false))
    }
}
export const openChangeNumberModalAction = (value,item, index  ) => {
    return async (dispatch) => {
        dispatch(mapSlice.actions.setOpenChangeNumberModal(value))
        dispatch(mapSlice.actions.setSelectedItem(item))
        dispatch(mapSlice.actions.setSelectedItemIndex(index))
    }
}
