import Header from "../../components/Header";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Banner from "../../components/Banner";
import {homePageBanner, smallPageBanner} from "../../constants/homeData";
import React, {useEffect, useRef} from "react";
import {searchBeaches} from "../../store/Home/HomeActions";
import DefaultSlider from "../../components/DefaultSlider";
import SmallBanner from "../../components/SmallBanner";
import Footer from "../../components/Footer";

const SearchedBeaches = () => {
    const {searchedBeaches} = useSelector((state) => state.homePage)
    const {search} = useSelector((state) => state.home)
    const firstSectionRef = useRef(null);
    const scrollToFirstSection = () => {
        firstSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        scrollToFirstSection()
    }, []);
    return (
        <>
            <Header hasBackground={false}/>
            <SmallBanner data={smallPageBanner} onScrollClick={scrollToFirstSection} />

            <div ref={firstSectionRef}  className="container-fluid pb-10">
                <div className="container">
                    <div className="flex flex-col gap-4 px-8 ">
                        {
                            !search ?
                                <h2 className="text-2xl text-primary-dark-green uppercase ">Search for a beach</h2>
                                :
                                <h2 className="text-2xl text-primary-dark-green uppercase pt-8">
                                    {searchedBeaches.length} {searchedBeaches.length > 1 ? 'results' : 'result'} for
                                    "{search}"</h2>

                        }
                        <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                        {
                          (  searchedBeaches && searchedBeaches.length > 0) &&
                            <DefaultSlider slidesData={searchedBeaches}/>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
export default SearchedBeaches
