import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../../components/Header";
import SmallBanner from "../../components/SmallBanner";
import { smallPageBanner } from "../../constants/homeData";
import Footer from "../../components/Footer";
import axios from "axios";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";

const ContactUs = () => {
    const [email, setEmail] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [status, setStatus] = useState("");

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    useEffect(() => {
        const getEmail = localStorage.getItem("email");
        const token = localStorage.getItem("token");

        if (getEmail) {
            setEmail(getEmail);
        }

        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            email: email,
            subject: '',
            description: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
            subject: Yup.string().required('Subject is Required'),
            description: Yup.string().required('Message is Required')
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await axios.post('/contact-us', {
                    email:values.email,
                    subject: values.subject,
                    description: values.description
                });
                setOpenSnackBar(true);
                resetForm({ values: { ...values } });
            } catch (error) {
                setOpenSnackBar(false);
            }
        }
    });

    return (
        <section>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={'success'} sx={{ width: '100%' }}>
                    Message sent
                </Alert>
            </Snackbar>
            <div className="container">
                <p className="text-3xl font-bold text-primary-dark-green py-3">Contact Us</p>
                <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-4 w-50 pb-3">
                    <div>
                        <label htmlFor="email" className="px-2 text-primary-dark-green pb-1">Email</label>
                        <input
                            className="bg-primary-dark-green/10 text-primary-dark-green p-3 rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-dark-green"
                            type="text"
                            id="email"
                            placeholder="Email"
                            {...formik.getFieldProps('email')}
                            readOnly={isLoggedIn}
                        />
                    </div>
                    <div>
                        <label htmlFor="subject" className="px-2 text-primary-dark-green pb-1">Issue type/Subject</label>
                        <input
                            className="bg-primary-dark-green/10 text-primary-dark-green p-3 rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-dark-green"
                            type="text"
                            id="subject"
                            placeholder="Enter Subject"
                            {...formik.getFieldProps('subject')}
                        />
                        {formik.touched.subject && formik.errors.subject ? (
                            <div className="text-red-500">{formik.errors.subject}</div>
                        ) : null}
                    </div>
                    <div className="flex flex-col col-span-2">
                        <label htmlFor="message" className="px-2 text-primary-dark-green pb-1">Message</label>
                        <textarea
                            className="bg-primary-dark-green/10 text-primary-dark-green p-3 rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-dark-green"
                            id="description"
                            placeholder="Leave us a message..." rows={4}
                            {...formik.getFieldProps('description')}
                        ></textarea>
                        {formik.touched.description && formik.errors.description ? (
                            <div className="text-red-500">{formik.errors.description}</div>
                        ) : null}
                    </div>
                    <div className="col-span-2 cursor-pointer">
                        <button type="submit"
                                className="bg-primary-dark-green text-white rounded-md px-4 py-1 mt-3">Submit
                        </button>
                    </div>
                </form>
            </div>
            <Footer/>
        </section>
    );
};

export default ContactUs;
