import {createSlice} from "@reduxjs/toolkit";
const storedToken = localStorage.getItem('token');
const storedRole = localStorage.getItem('role');
const storedFirstName = localStorage.getItem('firstName');
const storeLastName = localStorage.getItem('lastName');
export const initialState= {
    token: storedToken || '',
    isLoading:false,
    role:storedRole || '',
    errorLogin : false,
    firstName:storedFirstName || '',
    lastName:storeLastName || '',
    profileInfo:{},
    errorUpdatePassword:false,
    bagInformation:[],
    subTotalBag:0,
    totalBag:0,
    clientSecret:"",
    beachesInBag:[],
    currentReceipts:[],
    myReservations:[],
    qrReservation:{},
    myTickets:[],
    beachBedPoints:null,
    myLevel:{},
    nextLevel:{},
    currentTotalReservations:0,
    mySplits:[],
    showSnackbar: false,
}

export const authSlice = createSlice(
    {
        name:'auth',
        initialState,
        reducers:{
            setIsLoading(state , action){
                state.isLoading = action.payload
            },
            setToken(state , action){
                state.token = action.payload
            },
            setErrorLogin(state , action){
                state.errorLogin = action.payload
            },
            setRole(state , action){
                state.role = action.payload
            },
            setFirstName(state,action){
                state.firstName = action.payload
            },
            setLastName(state,action){
                state.lastName = action.payload
            },
            setInitialValues(state){
                state.token =""
                state.role=""
                state.firstName=""
                state.lastName=""
            },
            setProfileInfo(state, action){
                state.profileInfo = action.payload
            },
            setErrorUpdatePassword(state,action){
                state.errorUpdatePassword = action.payload
            },
            setBagInformation(state,action){
                state.bagInformation = action.payload
            },
            setMyBeachBedPoints(state,action){
                state.beachBedPoints = action.payload
            },
            setMyLevel(state,action){
              state.myLevel = action.payload
            },
            setNextLevel(state,action){
              state.nextLevel = action.payload
            },
            setCurrentTotalReservations(state,action){
              state.currentTotalReservations = action.payload
            },
            setSubTotal(state,action){
                state.subTotalBag = action.payload
            },
            setTotal(state,action){
                state.totalBag = action.payload
            },
            setClientSecret(state , action){
                state.clientSecret = action.payload
            },
            setBagBeaches(state , action){
                state.beachesInBag = action.payload
            },
            setCurrentReceipts(state,action){
                state.currentReceipts = action.payload
            },
            setMyReservations(state , action){
                state.myReservations = action.payload
            },
            setQrReservation(state, action){
                state.qrReservation = action.payload
            },
            setMyTickets(state,action){
                state.myTickets = action.payload
            },
            setMySplits(state,action){
                state.mySplits = action.payload
            },
            setShowSnackbar(state, action) {
                state.showSnackbar = action.payload;
            },
        }
    }
)
export const { setShowSnackbar } = authSlice.actions;
export default authSlice.reducer;
