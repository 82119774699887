import {useEffect, useState} from "react";
import './style.css'
import DeleteIcon from '@mui/icons-material/Delete';
import {addToCart, addToCartAction, toggleBagSideBar} from "../../store/Home/HomeActions";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router-dom";
import {Avatar} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import {checkIfWeekend} from "../../util";
import moment from "moment";

export const BagSideBar = () => {
    const [bag, setBag] = useState(JSON.parse(localStorage.getItem('card')) ?? []);
    const navigate = useNavigate()
    const {
        token,
    } = useSelector((state) => state.auth)

    const dispatch = useDispatch()

    function add(index) {
        let newBag = bag;
        newBag[index].quantity++;
        newBag[index].totalPrice =
            newBag[index].price * newBag[index].quantity;
        setBag([...newBag]);
    }

    function remove(index) {
        let newBag = bag;
        if (newBag[index].quantity > 0) {
            newBag[index].quantity--;
        }
        newBag[index].totalPrice = newBag[index].price * newBag[index].quantity;
        setBag([...newBag]);
        if (newBag[index].quantity === 0) {
            deleteReservation(index);
        }
    }

    function closeSideBar() {
        dispatch(toggleBagSideBar(false))
    }

    function deleteReservation(index) {
        let newBag = bag;
        newBag.splice(index, 1);
        if (newBag.length === 0) {
            closeSideBar()
        }
        setBag([...newBag]);
    }

    function addToCart() {
        let selectedSeats = JSON.parse(localStorage.getItem('selectedItems'));
        let card = JSON.parse(localStorage.getItem('card'))
        if (token) {
            dispatch(addToCartAction(selectedSeats, card))
                .then(()=>{
                    navigate('/my-bag')
                })
            dispatch(toggleBagSideBar(false))

        } else {
            localStorage.setItem('route_from', 'checkout')
            dispatch(toggleBagSideBar(false))
            navigate('/login-user')
        }
    }
    return (
        <div className="sideBar">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                        <CloseIcon style={{cursor: 'pointer', color: '#005662', fontSize: '25px'}}
                                   onClick={closeSideBar}/>
                    </div>
                </div>
                <div className="row mb-3">
                    <h1 className="text-xl font-bold">Your bag : {bag.length}</h1>
                </div>
                <div className="row mb-5">
                    <h4 className="text-md font-bold text-primary-orange">Checking out will remove any old item in your card.</h4>
                </div>
                {bag.map((reservation, index) => (
                    reservation.type !== 'ITEM' ? (
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <img className={"bag-beach-image"} alt="Beach Image" width={"100"} height={"150"}
                                     src={reservation.beach_image}/>
                            </div>
                            <div className="col-md-8">
                                <h5>{reservation.beach_name} / Day pass</h5>
                                <p>
                                    {moment(reservation.date).format("dddd MMMM, DD")}                                 </p>
                                <div className="row">
                                    <div className="col-9 button-reservation">
                                        <div className="row">
                                            <div className="col-9 d-flex align-items-center">
                                                Count: <b>{reservation.count}</b>
                                            </div>
                                            <div className="col-3 d-flex align-items-center">
                                                <p className={"m-0"}>$ {reservation.count * reservation.day_pass_price}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => deleteReservation(index)} style={{cursor: 'pointer'}}
                                         className="col-2 d-flex  align-items-center">
                                        <Avatar className={"me-2 "}
                                                sx={{
                                                    bgcolor: 'white', color: '#005662', cursor: 'pointer',
                                                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                                                }}>
                                            <DeleteIcon/>
                                        </Avatar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row mt-4">
                            <div onClick={() => deleteReservation(index)} style={{cursor: 'pointer'}}
                                 className="col-2 mb-2 flex items-center max-md:hidden">
                                <Avatar className={"me-2 "}
                                        sx={{
                                            bgcolor: 'white',
                                            color: '#005662',
                                            cursor: 'pointer',
                                            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                                            width: "30px",
                                            height: "30px"
                                        }}>
                                    <DeleteIcon/>
                                </Avatar>
                            </div>
                            <div className="md:flex items-center gap-3">
                                <img className={"md:w-[150px] max-md:w-1/2 rounded-xl"} alt="Beach Image"
                                     src={reservation.beach_image}/>
                                <div className="max-md:text-sm pt-4">
                                    <div onClick={() => deleteReservation(index)} style={{cursor: 'pointer'}}
                                         className="max-md:flex align-items-center mb-2 md:hidden">
                                        <p className="underline text-xs">Delete</p>
                                    </div>
                                    <p className="font-bold text-lg">{reservation.name}</p>
                                    <p className="text-sm">
                                        {moment(reservation.date).format("dddd MMMM, DD")}
                                    </p>
                                    <div className="flex justify-between gap-4">
                                        <div className="flex button-reservation">
                                            <div className="gap-2 justify-between text-primary-dark-green">
                                                <div className="flex items-center">
                                                    <img
                                                        alt="Single Bed"
                                                        src={reservation.image} className="h-5"
                                                    />
                                                    <p className={"m-0 ms-2 text-sm"}>
                                                        {reservation.name} x
                                                        {reservation.count}
                                                    </p>
                                                </div>
                                                <p className="text-primary-orange">
                                                    {Number(reservation.pivot.number_of_persons) === 2 &&
                                                        <span> (entrance for 2 included)</span>}
                                                </p>
                                                <p className={"m-0 mt-2 text-primary-dark-green text-lg"}>
                                                Price:
                                                    ${(reservation.count * (checkIfWeekend(reservation.date) ? Number(reservation.pivot.weekend_price) : Number(reservation.pivot.weekday_price)))}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ))}
                <div
                    className="flex justify-end ">
                    <div onClick={addToCart}
                         className="bg-primary-orange text-white rounded-full p-3 mt-5 w-full text-center cursor-pointer">
                        Checkout
                    </div>
                </div>
            </div>
        </div>

    )
}
export default BagSideBar
