import AboutBeach from "./components/AboutBeach";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import {beachInfoBanner} from "../../constants/beachInfoData";
import BeachGalleryImages from "./components/BeachGalleryImages";
import Header from "../../components/Header";
import {useDispatch, useSelector} from "react-redux";
import {useBeachInfo} from "../Home/hooks";
import {useEffect, useRef} from "react";
import LoaderComp from "../../components/LoaderComp";
import {homeSlice} from "../../store/Home/HomeSlice";

export const BeachInfoNew = () => {
    const {selectedBeach} = useSelector((state) => state.home)
    let beach_id =  localStorage.getItem('selected_beach')

   const {data , isLoading} = useBeachInfo({beach_id:beach_id})
    useEffect(() => {
        if(data){
            localStorage.setItem('country_id', data.data.country_id);
            localStorage.setItem('bag_beach_id', data.data.id);
            dispatch(homeSlice.actions.setSelectedBeach(data.data));
        }

    }, [data]);


    const firstSectionRef = useRef(null);

    const scrollToFirstSection = () => {
        firstSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const dispatch = useDispatch()
    useEffect(() => {

        return () => {
            dispatch(homeSlice.actions.setSelectedBeach({}))
        };
    }, [dispatch]);

    return (
        <>
            {isLoading && <LoaderComp/>}
            <Header/>
            <Banner data={{image:selectedBeach.background_image , description: selectedBeach.name , isBeachInfo:true}} onScrollClick={scrollToFirstSection}/>
            <div className="container py-10" ref={firstSectionRef}>
                <AboutBeach/>
                <BeachGalleryImages/>
            </div>
            <Footer/>
        </>
    )
}
export default BeachInfoNew;
