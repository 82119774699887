import Header from "../../components/Header";
import GoogleMaps from "../../components/GoogleMaps";
import {useSelector} from "react-redux";
const GoogleMapsPage = () =>{
    const {allBeachesMap } = useSelector((state) => state.home)
    return (
        <div>
            <Header hasBackground={true}/>
            {
                allBeachesMap.length >0 && <GoogleMaps allBeaches={allBeachesMap}/>
            }
        </div>
    )
}
export default GoogleMapsPage
