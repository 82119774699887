import React, { useEffect, useState } from 'react';
import AppRouter from './router/AppRouter.tsx';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {AxiosMiddleware} from "./middelware";

AxiosMiddleware.boot();

export const App = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showSnackbar, setShowSnackbar] = useState(false);

    useEffect(() => {
        const fromUrlKey = sessionStorage.getItem('beach_url_key');
        if (fromUrlKey) {
            const location = window.location.href;
            if (location !== `${process.env.REACT_APP_FRONT_END}/beach?key=${fromUrlKey}`) {
                window.location.href = `/beach?key=${fromUrlKey}`;
            }
        }

        const handleOnline = () => {
            setIsOnline(true);
            setShowSnackbar(false);
        };

        const handleOffline = () => {
            setIsOnline(false);
            setShowSnackbar(true);
        };

        const handleAxiosNetworkError = () => {
            setIsOnline(false);
            setShowSnackbar(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        window.addEventListener('axiosNetworkError', handleAxiosNetworkError);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('axiosNetworkError', handleAxiosNetworkError);
        };
    }, []);

    return (
        <div className="App">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert onClose={() => setShowSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                    You are offline
                </Alert>
            </Snackbar>
            <AppRouter />
        </div>
    );
};
export default App;
