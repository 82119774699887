import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    bag:[],
    originalItems:[],
    uniqueBag:[],
    total:0,
    subTotal:0
}


export const guestSlice = createSlice({
    name:'guestSlice',
    initialState,
    reducers:{
        setBag(state , action) {
            state.bag = action.payload
        },
        setOriginalItems(state,action){
          state.originalItems = action.payload
        },
        setSubTotal(state , action) {
            state.subTotal = action.payload
        },
        setTotal(state , action) {
            state.total = action.payload
        },
        setGuestUniqueBag(state , action){
            state.uniqueBag = action.payload
        }
    }
})
export default guestSlice.reducer;
