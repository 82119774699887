import Footer from '../../../../components/Footer'
import React from "react";
import Header from "../../../../components/Header";

export const TermsConditions = () => {
    return (
        <>
            <Header hasBackground={true} />
            <div className="container">
                <p className="text-primary-orange text-2xl pt-28">General terms and conditions</p>
                <p className="mt-4 text-lg">
                    Welcome to<a href="https://beachbeds.com/"
                                 className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a>
                    These Terms and Conditions govern your use of our website and services. By
                    accessing or using our platform, you agree to abide by these terms.
                </p>
                <p className="pt-4 pb-2">
                    1.Business Information
                    <a href="https://beachbeds.com/"
                       className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a> is a platform
                    where users can browse, select, and book specific
                    seats or services at various beaches.
                </p>
                <p className="py-2">
                    2.Website Use There are no restrictions on who can use our website. Users must comply with the
                    rules and regulations of each beach booked through our service.
                </p>
                <p className="py-2"> 3.Account Creation You must create an account to book seats, bungalows, or any
                    service. During
                    account creation, we collect First Name, Last Name, Birthday, Gender, Email, and Phone Number.
                    Information collected may be shared with any of our partnered beaches and resorts for
                    reservations and /or marketing purposes.
                </p>
                <p className="py-2">
                    4.Purchases and Payments Accepted payment methods include Visa, Mastercard, Whish Money, and
                    OMT. By
                    making a purchase, you authorize us to charge applicable fees to your chosen payment method.
                </p>
                <p className="py-2">
                    5.Refund and Cancellation Policy
                    Refunds are available in the form of beach points for bookings canceled at least one week (7
                    days)
                    in advance. Beach points issued by
                    <a href="https://beachbeds.com/"
                       className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a>shall be used
                    at any of its partnered resorts
                    through the website for future bookings. For bookings canceled within 7 days of the start date,
                    no
                    refunds are provided. However, <a href="https://beachbeds.com/"
                                                      className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a> may
                    at its sole discretion, issue compensation in
                    beach
                    points that can be used at any of its partnered resorts for such cancellations. Please note that
                    booking and transaction fees may be deducted from any refund or beach points issued.
                </p>
                <p className="py-2">
                    6.Beach Access Terms
                    Each beach reserves the right to refuse admission or eject individuals for disorderly conduct,
                    abusive language, or failure to adhere to beach rules. Booking through <a
                    href="https://beachbeds.com/"
                    className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a> grants you
                    permission for beach access, which may be revoked by the beach management without refund if
                    their
                    rules are violated. Security measures, including searches of belongings, may be conducted upon
                    entry
                    to ensure compliance and safety.
                </p>
                <p className="py-2">
                    7.Intellectual Property Use of our logos, intellectual property, or identity requires our
                    written
                    consent.
                </p>
                <p className="py-2">
                    8.Limitation of Liability <a href="https://beachbeds.com/"
                                                 className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a> shall
                    not be liable for any damages except in cases of
                    willful misconduct or gross negligence.
                </p>
                <p className="py-2">
                    9.Privacy Policy Information on how we collect, use, and protect your personal data can be found
                    in
                    our Privacy Policy.
                </p>
                <p className="py-2">
                    10.Governing Law These Terms and Conditions are governed by the laws of Lebanon.
                </p>
                <p className="py-2">
                    11.Modifications to the T&C We will notify users of any changes to these Terms and Conditions
                    via
                    email or in-app notification.
                    By using <a href="https://beachbeds.com/"
                                className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a>,
                    you agree to these Terms and Conditions. If you have any questions or
                    concerns, please reach out using the contact us form on the website.
                </p>
            </div>
            <Footer/>
        </>
    )
}

export default TermsConditions;
