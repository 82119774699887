import {createSlice} from "@reduxjs/toolkit";

export const initialState={
    initialState: {
        isLoading: false,
        sliderInfo: null,
        downloadNowInfo: null,
        filteredSlider:null,
        homePageData: [],
        minPrice: null,
        maxPrice: null,
        locationFilters: [],
        selectedCountry:(localStorage.getItem('country_id')) ?? null,
        searchedBeaches:[],
        allBeaches : [],
        emailError:null
    },
}
export const HomePageSlice= createSlice(
    {
        name: 'homePage',
        initialState,
        reducers:{
            setIsLoading(state, action){
                state.isLoading =action.payload
            },
            setSliderInfo(state,action){
                state.sliderInfo=action.payload
            },
            setDownloadNowInfo(state, action) {
                state.downloadNowInfo = action.payload;
            },
            setFilteredSlider(state,action){
                state.filteredSlider=action.payload;
            },
            setHomePageData(state,action){
                state.homePageData=action.payload
            },
            setMaxPrice(state,action){
                state.maxPrice=action.payload
            },
            setMinPrice(state,action){
                state.minPrice=action.payload
            },
            setLocationFilters(state, action) {
                state.locationFilters = action.payload
            },
            setSelectedCountry(state , action){
                state.selectedCountry = action.payload
            },
            setSearchedBeaches(state , action){
                state.searchedBeaches = action.payload
            },
            setAllBeaches(state,action){
                state.allBeaches = action.payload
            },
            setEmailError(state, action) {
                state.emailError = action.payload;
            }
        }
    }
)

export const { setIsLoading, setSliderInfo,setDownloadNowInfo,setFilteredSlider ,setHomePageData,setMaxPrice,setMinPrice,setLocationFilters,setSearchedBeaches, setAllBeaches,setEmailError} = HomePageSlice.actions;
export default HomePageSlice.reducer
