import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Modal} from "@mui/material";
import {
    cancelReservation,
    checkInReservation, confirmReservation, handleSwapAction,
    setItemInformation, setShowSwappingMessage
} from "../../../../store/BeachAdmin/BeachAdminActions";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import {beachAdminSlice} from "../../../../store/BeachAdmin/BeachAdminSlice";
import KingBedIcon from "@mui/icons-material/KingBed";

const ItemDetailsSection = ({fromDashboard, filter}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius: '15px',
        outline: 0
    };
    const dispatch = useDispatch()
    const {itemInformation, selectedDate, showItemModal, reservations} = useSelector((state) => state.beachAdmin)
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleClose = () => {
        dispatch(setItemInformation({}, false))
        dispatch(setShowSwappingMessage(false));
    }

    const removeItem = () => {
        let index = reservations.findIndex(x => x.id === '45');
        let data = [...reservations];
        data.splice(index, 1)
        dispatch(beachAdminSlice.actions.setReservations(data))
    }
    const handleCheckIn = () => {
        removeItem()
        dispatch(checkInReservation(fromDashboard ? itemInformation.id : itemInformation.reservation_id, selectedDate))
    }
    const handleConfirmReservation = () => {
        removeItem()
        dispatch(confirmReservation(fromDashboard ? itemInformation.id : itemInformation.reservation_id, selectedDate))
    }
    const handleCancelReservation = () => {
        setShowConfirmModal(true);
    }
    const handleReservationCanceled = () => {
        removeItem()
        dispatch(cancelReservation(fromDashboard ? itemInformation.id : itemInformation.reservation_id, selectedDate));
        dispatch(setItemInformation({}, false))
        setShowConfirmModal(false);
    }
    const handleSwapSeats = () => {
        removeItem();
        dispatch(handleSwapAction(itemInformation, true, selectedDate));
        dispatch(setShowSwappingMessage(true));
    }

    return (
        <>
            <Modal
                open={showItemModal}
                onClose={handleClose}>
                <Box sx={style}>
                    <div className="text-primary-dark-green rounded-tl-lg rounded-tr-lg pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="mt-2 mb-2">
                                    <div className="flex justify-between">
                                        <h4 className={"text-lg font-bold"}>{itemInformation.user?.first_name} {itemInformation.user?.last_name}
                                            {
                                                itemInformation.guest_name &&
                                                <span>
                                     {itemInformation.guest_name}
                                    </span>
                                            }
                                        </h4>
                                    </div>
                                    {
                                        itemInformation.user?.phone_number ?
                                            <div className="flex items-center gap-2 text-sm">
                                                <LocalPhoneIcon
                                                    className={"mt-2 mb-2 cursor-pointer text-[#005662] text-sm"}/>
                                                <p>{itemInformation.user?.phone_number}</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    itemInformation.user?.email &&
                                                    <div
                                                        className="text-primary-dark-green flex items-center">
                                                        <MailIcon className={"mt-2 mb-2"}
                                                                  style={{
                                                                      cursor: 'pointer',
                                                                      color: '#005662',
                                                                      fontSize: '17px'
                                                                  }}/>
                                                        <p>{itemInformation.user?.email}</p>
                                                    </div>
                                                }
                                            </>

                                    }
                                    <div className="bg-primary-light-green/30 rounded-lg my-2 p-2">
                                        <div className="flex items-center gap-2 py-2">
                                            <KingBedIcon className="text-primary-dark-green"/>
                                            <p className={"m-0 mt-1"}>{itemInformation.name}
                                                <b>{itemInformation.item_number}</b></p>
                                        </div>
                                        <div
                                            className={"flex items-center text-sm text-primary-dark-green bg-p"}>{itemInformation.reservation_status === 'reserved' ?
                                            'Reserved (Pending Confirmation)' :
                                            itemInformation.reservation_status === 'reservation_confirmed' ?
                                                'Reservation Confirmed' :
                                                itemInformation.reservation_status === 'checked_in'
                                                && 'Checked In'
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="container">
                            {
                                itemInformation.reservation_status === 'reserved' &&
                                <div onClick={handleConfirmReservation}
                                     className="bg-[#FFC829] text-primary-dark-green rounded-lg d-flex justify-content-center align-items-center cursor-pointer m-2 p-2">
                                    <p className={" "}><b>Confirm Reservation</b></p>
                                </div>

                            }
                            {
                                itemInformation.reservation_status !== 'checked_in' &&
                                <div onClick={handleCheckIn}
                                     className="bg-[#a0e5b5] rounded-lg text-primary-dark-green cursor-pointer flex justify-center items-center m-2">
                                    <p className={" mt-2 mb-2 ms-2 m-0"}><b>Check-In</b></p>
                                </div>
                            }


                            {
                                !fromDashboard &&
                                <div onClick={handleSwapSeats}
                                     className="flex justify-center bg-primary-dark-green text-white rounded-md m-2 p-2 cursor-pointer">
                                    Swap/Rearrange Seats
                                </div>
                            }

                            {
                                itemInformation.reservation_status === 'checked_in' &&
                                <p className="text-sm py-3">Note: This seat is
                                    checked-in. Make sure to get the client approval before swapping.</p>
                            }
                            <div className="row justify-content-center mt-3 mb-3 mx-1">
                                <div onClick={handleCancelReservation}
                                     className="bg-red-700/20 text-red-700 rounded-lg flex justify-center cursor-pointer">
                                    <p className={"mt-2 mb-2 ms-2 m-0"}><b>Cancel Reservation</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
            >
                <Box sx={style} className="py-5">
                    <p className="text-primary-dark-green p-3 text-lg font-bold text-center"> Are you sure you want to
                        cancel the reservation?</p>
                    <div className="flex gap-2 items-center justify-center">
                        <button type="button" className="bg-primary-orange/10 text-primary-orange px-3 py-2 rounded-lg"
                                onClick={handleReservationCanceled}>
                            Yes
                        </button>
                        <button type="button" className="bg-primary-dark-green text-white px-3 py-2 rounded-lg"
                                onClick={() => setShowConfirmModal(false)}>
                            Cancel
                        </button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
export default ItemDetailsSection
