import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modifytInitialMap, setSelectedItems} from "../../store/Home/HomeActions";
import ShowImageModal from "../ShowImageModal";

export const DisabledItem = ({item, index, date , activeStep}) => {
    const {initialMap, selectedItems} = useSelector((state) => state.home)
    const dispatch = useDispatch()
    const [showImage, setShowImage] = useState(false)
    const handleItemClicked = () => {
        if (date && activeStep === 1) {
            if (item.can_be_reserved === 1 && !item.is_reserved) {
                const updatedInitialMap = initialMap.map((mapItem, i) =>
                    i === index ? {...mapItem, is_selected: !mapItem.is_selected} : mapItem
                );
                dispatch(modifytInitialMap(updatedInitialMap))
            }
            if (item.isCaptureImage) {
                setShowImage(true)
            }
        }

    }
    return (

        <>
            <div
                onClick={handleItemClicked}
                style={{
                    position: 'absolute',
                    display: 'grid',
                    left: item?.startX,
                    top: item?.startY,
                    width: item.width ?? '50px',
                    height: item.height ?? '50px',
                    backgroundColor: "transparent",
                    cursor: ((item.can_be_reserved == '1' && !item.is_reserved) || item.isCaptureImage) && 'pointer',
                    color: "black",
                    transform: `rotate(${item.rotateDegree}deg)`,
                    justifyContent: 'center',
                    filter: item.is_selected ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)' :
                        (((item.can_be_reserved == '1' && !item.is_reserved) || item.isCaptureImage) ? 'drop-shadow(1px 1px 7px #298a3e)' : ''),
                    zIndex: 10,
                }}
            >
                {
                    item.isCaptureImage ?

                        <img src={'/'+item.image} height={item.height ?? '50px'} width={item.width ?? '50px'}/>
                        :
                    item.is_reserved ?
                        <img style={{filter:  'brightness(0) saturate(100%) invert(45%) sepia(39%) saturate(3675%) hue-rotate(330deg) brightness(111%) contrast(101%)'
                        }} src={item.image} height={item.height ?? '50px'} width={item.width ?? '50px'}/>
                        :
                        <img src={item.image} height={item.height ?? '50px'} width={item.width ?? '50px'}/>
                }

            </div>
            {
                showImage &&
                <ShowImageModal open={showImage} handleClose={() => setShowImage(false)} imageUrl={item.imageAttached}/>
            }
        </>
    )
}
export default DisabledItem
