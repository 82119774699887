import Header from "../../components/Header";
import Footer from "../../components/Footer";

import SmallBanner from "../../components/SmallBanner";

import {smallPageBanner} from "../../constants/homeData";

export const OrderErrorMonty = () => {
    return (
        <div>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            <div className="grid text-center justify-center">
                <img src="order-error.png" width={400} />
                <div className="text-center">
                                        <h1 className={"text-primary-dark-green text-3xl max-md:text-xl"}>An Error Has Occured!</h1>
                                        <p className={"text-primary-dark-green"}>An error has occured during your checkout.</p>
                                    </div>
            </div>
            <Footer/>

        </div>
    )
}
export default OrderErrorMonty