import {Box, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const RefundModal = ({open, handleClose, handleRefund , ticket}) =>{
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600',
        height:'300',
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius:'15px',
        outline:0,
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Box sx={style}>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-12 mt-1">
                            <div className="float-end">
                                <CloseIcon onClick={handleClose} style={{cursor:'pointer'}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row px-4 mt-3">
                        <div className="col-md-12 text-center">
                            <h4 className={"label-admin"}>Are you sure you want to refund?</h4>
                            <p className={"label-admin m-0 mt-3"}>You will get refunded : {ticket.beach.partial_refund_percentage}% of the price.</p>
                            <p className={"label-admin"}>The value will be added as beach bed points to your account.</p>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-6 text-center mt-3">
                            <button className={"btn btn-warning"} onClick={handleRefund}>Refund</button>
                        </div>
                        <div className="col-md-6 text-center mt-3">
                            <button className={"btn btn-secondary"} onClick={handleClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default RefundModal