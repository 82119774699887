import {Box, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";

const ChangeEmailModal = ({open, handleClose, handleEmailChange, showError}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600',
        height: '300',
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius: '15px',
        outline: 0,
    };
    const [email, setEmail] = useState('')
    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Box sx={style}>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-12 mt-1">
                            <div className="float-end">
                                <CloseIcon onClick={handleClose} style={{cursor: 'pointer'}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row px-4 mt-3">
                        <div className="col-md-12 text-center">
                            <h3 className={"label-admin"}>Enter the email of the user</h3>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <input className={"form-control"} type="email" onChange={(e)=>setEmail(e.target.value)}/>
                        </div>
                    </div>
                    {
                        showError &&
                        <div className="row mt-3 text-center">
                            <p style={{color:'red'}}>Email already taken</p>
                        </div>
                    }

                    <div className="row ">
                        <div className="col-md-6 text-center mt-3">
                            <button className={"btn btn-secondary"} onClick={handleClose}>Cancel</button>
                        </div>
                        <div className="col-md-6 text-center mt-3">
                            <button className={"btn btn-warning"} onClick={()=> handleEmailChange(email)}>Change</button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default ChangeEmailModal