import {
    closeAutoGenerateModal,
    saveBeachMapAction,
    setCurrentSectionAction,
    setOptionSelectedAction,
    setSavedSectionsAction, setShowRotateModal, setUndoData
} from "../../store/Map/MapActions";
import {Item} from "../Map/Item";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {Box, Modal} from "@mui/material";
import {setMultipleSelectedItems, setUserMultiSelectingAction} from "../../store/MultipleSelect/MultipleSelectActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    backgroundColor: 'white',
    boxShadow: 24,
    borderRadius:'15px',
    p: 4,
    outline:0
};
const spacing = [
    {
        value:0,
        label:'0 Units',
    },
    {
        value:5,
        label:'1 Unit',
    },
    {
        value:10,
        label:'2 Units',
    },
    {
        value:15,
        label:'3 Units',
    },
    {
        value:20,
        label:'4 Units',
    },
    {
        value:25,
        label:'5 Units',
    },
    {
        value:30,
        label:'6 Units',
    },
    {
        value:35,
        label:'7 Units',
    },
    {
        value:40,
        label:'8 Units',
    },
    {
        value:45,
        label:'9 Units',
    },
    {
        value:50,
        label:'10 Units',
    },
]
export const Layout = ({children}) => {
    const min = 0;
    const max = 10;
    const {optionSelected  ,autoGenerateModal , autoGenerateItem , savedSections , selectedPlanId , itemsToDrop , isDraggingInside , showRotateModal , showRotateModalItem  , showRotateModalItemIndex} = useSelector((state)=> state.map)
    const [rotateDegree , setRotateDegree] = useState(showRotateModalItem.rotateDegree ?? 0)
    // console.log(showRotateModalItem)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [rowsNumber , setRowsNumber]=useState('')
    const [columnsNumber , setColumnsNumber] = useState('')
    const [horizontalSpacing , setHorizontalSpacing] = useState('')
    const [verticalSpacing , setVerticalSpacing] = useState('')
    const [reversedItems , setReversedItems] = useState('')
    const handleDelete = (index) => {
        dispatch(setCurrentSectionAction(null))
        let array = [...savedSections];
        array.splice(index, 1);
        dispatch(setSavedSectionsAction(array))
        dispatch(setUndoData(array))

    }
    const saveBeachMap = () =>{
        let currentMapWidth = window.innerWidth * (10/12);
        let currentMapSizeHeight = window.innerHeight;
        dispatch(saveBeachMapAction(savedSections ,currentMapWidth , currentMapSizeHeight ))
            .then((response)=>{
                dispatch(setSavedSectionsAction([]))
                if(localStorage.getItem('role') === 'beach'){
                    navigate('/dashboard')
                }
                if(localStorage.getItem('role') === 'admin'){
                    localStorage.removeItem('beach_id')
                    navigate('/all-beaches')
                }
            })
            .catch((error)=>{ })
    }
    const handleClose = () =>{
        dispatch(closeAutoGenerateModal())
    }
    const handleNumberOfRows = (event)=>{
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        setRowsNumber(value)
    }
    const handleNumberOfColumns = (event)=>{
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        setColumnsNumber(value)
    }
   const handleHorizontalSpacing = (event)=>{
        setHorizontalSpacing(event.target.value)
    }
    const handleVerticalSpacing = (event)=>{
        setVerticalSpacing(event.target.value)
    }

    const autoGenerateItems = () =>{
        let data = [...savedSections]
        let newData = []
        let numberOfColumns = Number(columnsNumber)
        let numberOfRows = Number(rowsNumber)
        for (let i =0 ; i<numberOfRows ; i++){
            let startY = autoGenerateItem.startY + (i* (50 + (+verticalSpacing ?? 55 )))
            let endY = autoGenerateItem.endY + (i*(50 + (+verticalSpacing ?? 55)))
            for(let j=0 ; j<numberOfColumns ; j++){
                let startX = autoGenerateItem.startX + (j*(50 + (+horizontalSpacing ?? 55)))
                let endX = autoGenerateItem.endX + (j*(50 + (+horizontalSpacing ?? 55)))
                newData.push({
                    ...autoGenerateItem,
                    index: data[data.length -1]?.hasOwnProperty('index')  ? (data[data.length -1]?.index + 1) : 0,
                    startX, endX, startY , endY,
                    multiple_selected:true
                })
                if(i === 0 && j ===0){
                    continue
                }
                data.push({
                    ...autoGenerateItem,
                    index:(data[data.length -1]?.index ?? 0) + 1,
                    startX, endX, startY , endY,
                    multiple_selected:true
                })
            }
        }
         data = data.map((mapItem) =>
            mapItem.index === autoGenerateItem.index ? { ...mapItem, multiple_selected: true } : mapItem
        );
        const selectedItems = data.filter(item=>item.multiple_selected === true)

        dispatch(setMultipleSelectedItems(selectedItems))
        dispatch(setUserMultiSelectingAction(true))
        dispatch(setSavedSectionsAction(data))
        dispatch(setUndoData(data))

        dispatch(closeAutoGenerateModal())
        setRowsNumber(0)
        setColumnsNumber(0)
    }

    const handleCloseRotate = () => {
        dispatch(setShowRotateModal(false , {} , null))
        setRotateDegree(0)
    }
    const handleSaveRotate = () => {
        let data = [...savedSections]
        data[showRotateModalItemIndex] = {...showRotateModalItem, rotateDegree: rotateDegree}
        dispatch(setSavedSectionsAction(data))
        dispatch(setUndoData(data))
        dispatch(setShowRotateModal(false , {} , null))

    }



    useEffect(() => {
        setReversedItems([...savedSections].reverse())
    }, [savedSections]);
    return (
        <div>
            <div className={"container-fluid"}>
                <div className="row">
                    <div className="col-md-10 p-0">
                       <Outlet />
                    </div>
                    <div className="col-md-2 p-0 fixed-sidebar" style={{background: isDraggingInside ? '#005F95' : ''}}>
                        <div className={"fixed-height"}>
                            <div className={!isDraggingInside ? "bg-primary-blue/20" : ""}>
                                <div className="container">
                                    <h4 className={"pt-4 pb-4 text-primary-blue"}>Build your plan</h4>
                                </div>
                            </div>
                            <div className={"mt-3"}>
                                <div className="container">
                                    <div style={{cursor: 'pointer'}}
                                         onClick={() => dispatch(setOptionSelectedAction('draw'))}>
                                        <h6 className={"mb-1"}
                                            style={{color: optionSelected === 'draw' ? '#005F95' : '#8e8e8e'}}>Section</h6>
                                        <i style={{color: optionSelected === 'draw' ? '#005F95' : '#8e8e8e'}}>Draw your plan
                                            by sections</i>
                                    </div>
                                    <div style={{cursor: 'pointer'}}
                                         onClick={() => dispatch(setOptionSelectedAction('move'))}>
                                        <h6 className={"mb-1 mt-4"}
                                            style={{color: optionSelected === 'move' ? '#005F95' : '#8e8e8e'}}>Move</h6>
                                        <i style={{color: optionSelected === 'move' ? '#005F95' : '#8e8e8e'}}>Move section
                                            elements for more accuracy</i>
                                    </div>

                                </div>
                            </div>
                            {
                                selectedPlanId && (
                                    !showRotateModal ?
                                    <div style={{background: isDraggingInside ? '#fadede' : ''}} className="mt-3 bg-primary-blue/20">
                                        <div className={" container"}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className={"mt-2 mb-0"} style={{color: '#005F95'}}>Drag and drop and item</p>
                                                    <span style={{fontSize:'13px'}} ><b>(Note: when dropped you can double click on a item to auto arrange it for you)</b></span>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                {itemsToDrop.map((item, index) => (
                                                    <div className="col-md-3 mb-3">
                                                        <Item key={'item_' + index} section={item}/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                        :
                                        <div className={" container bg-blue-300"}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className={"mt-2 mb-0"} style={{color: 'white'}}>Rotate the Item</p>
                                                    <span style={{fontSize:'13px'}}>In Degrees</span>
                                                </div>
                                            </div>
                                            <div className="row mt-3 px-3">
                                                <input value={rotateDegree} className={"form-control"} type={"number"} onChange={(e)=>setRotateDegree(e.target.value)}/>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <button onClick={handleCloseRotate} className={"btn btn-secondary"}>Close</button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button onClick={handleSaveRotate} className={"btn btn-primary"}>Save</button>
                                                </div>
                                            </div>


                                        </div>
                                )
                            }
                            <div>
                                {
                                    (selectedPlanId && reversedItems && reversedItems.length > 0) &&
                                    (
                                        reversedItems?.map((section, index) => (
                                            <div
                                                className={index % 2 === 0 ? (isDraggingInside ? '#fadede' : 'bg-primary-blue/20') : (isDraggingInside ? '#fadede' : '')}
                                                key={'sidebar_item_' + index}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-10 mt-4">
                                                            <h6 className={"mb-1 "} style={{color: '#005F95'}}>
                                                                {section?.name} - {section?.type}
                                                            </h6>
                                                        </div>
                                                        <div className="col-md-2 mt-4 mb-4">
                                                            <FontAwesomeIcon onClick={() => handleDelete((reversedItems.length -1 ) - index)} icon={faTrashCan}
                                                                             style={{
                                                                                 fontSize: 16,
                                                                                 color: "#005F95",
                                                                                 cursor: 'pointer'
                                                                                }} className="text-center"/>
                                                        </div>
                                                        {
                                                            section.item_number &&
                                                            <div className="col-md-10 mb-4">
                                                                <b>Number: </b>{section.item_number}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )) )}
                            </div>
                        </div>
                        {
                            savedSections?.length>0 &&
                            <div>
                                <button onClick={saveBeachMap} className={"bg-primary-blue text-white py-2 rounded-full fixed-button w-100"}>Submit</button>
                            </div>
                        }
                        <Modal
                            open={autoGenerateModal}
                            onClose={handleClose}>
                            <Box sx={style}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3>Auto-generate a group of the following item: </h3>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-12 text-center header-text">
                                           <h5>{autoGenerateItem.name}</h5>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <img style={{height:'50px' , width:'50px'}} width={'50'} height={'50'} src={autoGenerateItem.image} />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <b>Number of rows</b>
                                        </div>
                                        <div className="col-md-6">
                                            <input type={"number"} value={rowsNumber} className={"form-control"} onChange={handleNumberOfRows}  />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <b>Number of columns</b>
                                        </div>
                                        <div className="col-md-6">
                                            <input type={"number"}  value={columnsNumber}  className={"form-control"}  onChange={handleNumberOfColumns} />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <b>Horizontal Spacing</b>
                                        </div>
                                        <div className="col-md-6">
                                            <select  value={horizontalSpacing}  className={"form-control"}  onChange={handleHorizontalSpacing}>
                                                {
                                                    spacing.map((item)=>(
                                                        <option value={item.value}>{item.label}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6">
                                            <b>Vertical Spacing</b>
                                        </div>
                                        <div className="col-md-6">
                                            <select  value={verticalSpacing}  className={"form-control"}  onChange={handleVerticalSpacing}>
                                                {
                                                    spacing.map((item)=>(
                                                        <option value={item.value}>{item.label}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <button onClick={autoGenerateItems}
                                                className={"btn col-md-12"}
                                                style={{backgroundColor: '#005662', color: 'white'}}>Submit
                                        </button>
                                    </div>

                                </div>
                            </Box>

                        </Modal>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Layout
