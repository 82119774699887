import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    userMultiSelecting:false,
    multipleSelectedItems:[],
}


export const multipleSelectSlice = createSlice({
    name:'multipleSelect',
    initialState,
    reducers:{
        setMultipleSelectedItems(state , action){
            state.multipleSelectedItems = action.payload
        },
        setUserMultiSelecting(state , action){
            state.userMultiSelecting = action.payload
        }
    }
})
export default multipleSelectSlice.reducer;
