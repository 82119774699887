import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHomePageInfo} from "../../pages/Home/hooks";
import SliderReact from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrow from "./components/NextArrow";
import PrevArrow from "./components/PrevArrow";
import Slider from '@mui/material/Slider';
import styled from "@emotion/styled";
import {filterBeach, searchBeaches} from "../../store/Home/HomeActions";
import {useNavigate} from "react-router-dom";
import ReactSelect from 'react-select';

const SliderStyle = {
    color: '#005662',
}
const CustomSlider = styled(Slider)({
    ...SliderStyle,
});
const FilteredSlider = () => {
    const dispatch = useDispatch();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(4)
    const [isHovered, setIsHovered] = useState(null);
    const [isLocationsCardVisible, setIsLocationsCardVisible] = useState(false);
    const {isLoading, allBeaches, maxPrice, locationFilters, selectedCountry} = useSelector((state) => state.homePage)
    const [price, setPrice] = useState([0, Number(maxPrice)]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedPriceRange, setSelectedPriceRange] = useState([0, Number(maxPrice)]);
    const [location, setLocation] = useState('');
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const handlePriceChange = (event, newValue) => {
        setPrice(newValue);
        setSelectedPriceRange(newValue);
    };
    const locationsCardRef = useRef();

    const [filter, setFilter] = useState({
        priceOne: 0,
        priceTwo: maxPrice ? Number(maxPrice) : 0,
        search: null,
        location: '',
        showNearMe: false,
    })

    const handleFilterChange = (name, value) => {
        setFilter(prev => ({
            ...prev,
            [name]: value
        }))
    }

    useEffect(() => {
        const timer = setTimeout(function () {
            if (filter.showNearMe) {
                if (!filter.longitude || !filter.latitude) {
                    return
                }
            }
            dispatch(filterBeach(filter))

        }, 500)
        return () => clearTimeout(timer);
    }, [filter]);


    useEffect(() => {
        if (maxPrice) {
            setFilter(prev => ({
                ...prev,
                priceTwo: Number(maxPrice),
            }));
        }
    }, [maxPrice]);

    const toggleLocationsCard = () => {
        setIsLocationsCardVisible(prevState => !prevState);
    };
    // const handlePriceChange = (event, newValue) => {
    //     setFilter(prev => ({
    //         ...prev,
    //         priceOne: newValue[0],
    //         priceTwo: newValue[1],
    //     }));
    // };
    const handleClearFilters = () => {
        setFilter(prev => ({
            ...prev,
            priceOne: 0,
            priceTwo: Number(maxPrice),
            location: '',
        }));
        setIsFilterApplied(false)
        dispatch(filterBeach(filter))
    };

    const handleSubmitFilter = () => {
        setFilter(prev => ({
            ...prev,
            priceOne: price[0],
            priceTwo: price[1],
            location: location,
        }));
        dispatch(filterBeach({
            ...filter,
            priceOne: price[0],
            priceTwo: price[1],
            location: location,
        }));
        setIsLocationsCardVisible(false);
        setIsFilterApplied(true);
    };

    const updateSlidesToShow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            setSlidesToShow(1);
        } else {
            setSlidesToShow(3);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updateSlidesToShow);
        return () => {
            window.removeEventListener("resize", updateSlidesToShow);
        };
    }, []);

    const selectStyles = {
        control: styles => ({...styles, backgroundColor: 'white'}),
        option: (styles, {isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? '#00414A' : isFocused ? '#b1cbcf' : null,
                color: isDisabled ? '#ccc' : isSelected ? 'white' : ' #00414A',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
        }),
    };

    const locationOptions = locationFilters.map((item, index) => ({
        value: item.location,
        label: item.location,
        key: "location_" + index
    }));
    const handleLocationChange = selectedOption => {
        const location = selectedOption ? selectedOption.value : '';
        setLocation(location);
        setSelectedLocation(location);
    };
    const settings = {
        dots: false,
        infinite: allBeaches.length > 3,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [, setIsResponsive] = useState(false);

    function valueLabelFormat(value) {
        return `${value} $`;
    }

    const handleResize = () => {
        const screenWidth = window.innerWidth;
        setIsResponsive(screenWidth <= 767);
    };
    const handleGetLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const {latitude, longitude} = position.coords;
                setFilter(prev => ({
                    ...prev,
                    longitude: longitude,
                    latitude: latitude
                }))
            }, (error) => {
                console.error("Error getting location:", error);
            });
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };
    useEffect(() => {
        if (filter.showNearMe) {
            handleGetLocation()
        } else {
            setFilter(prev => ({
                ...prev,
                longitude: '',
                latitude: ''
            }))
        }
    }, [filter.showNearMe]);

    const navigate = useNavigate()
    const selectBeach = (id) => {
        localStorage.setItem('selected_beach', id)
        navigate('/beach-info')
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (locationsCardRef.current && !locationsCardRef.current.contains(event.target)) {
                setIsLocationsCardVisible(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [locationsCardRef]);

    return (
        <div>
            <div className="relative text-sm flex items-center justify-between text-primary-dark-green md:mx-4 gap-4">
                <button type="button" className="absolute left-0 mt-2 ml-2">
                    <img src="/svgs/green-search.svg" alt="Search Icon" className="md:w-6 md:h-6"/>
                </button>
                <input value={filter.search}
                       onChange={(event) => handleFilterChange('search', event.target.value)}
                       placeholder="Search"
                       className="py-2.5 px-10 rounded-full bg-[#E6EEEF] border-none focus:outline-none md:w-1/5"/>
                <div className="gap-2 flex">
                    <button onClick={toggleLocationsCard} className="cursor-pointer">
                        <img src="/svgs/filter-icon.svg" alt="Filter Icon" className="max-md:w-16 max-md:h-16"/>
                    </button>
                    <button onClick={() => handleFilterChange('showNearMe', !filter.showNearMe)}>
                        <img src="/svgs/location-near-me.svg" alt="Filter Icon" className="max-md:w-16 max-md:h-16"/>
                    </button>
                </div>
                {isLocationsCardVisible && (
                    <div ref={locationsCardRef}
                         className="absolute top-full md:right-0 max-md:left-0 text-primary-dark-green md:p-8 max-md:p-8 bg-white shadow-2xl rounded-lg z-30">
                        <p className="font-bold rounded hover:bg-gray-100 text-lg m-0">
                            Locations
                        </p>
                        <ReactSelect
                            styles={selectStyles}
                            options={locationOptions}
                            onChange={handleLocationChange}
                            value={locationOptions.find(option => option.value === filter.location)}
                        />
                        <p className="font-bold rounded hover:bg-gray-100 mt-4 text-lg m-0">
                            Price
                        </p>
                        <div
                            className="col-md-3 col-lg-3 col-12 w-[200px] mt-3 mt-lg-0 mt-md-0 ms-lg-2 ms-md-2 ms-0 d-flex align-items-center px-lg-3 px-md-3 px-3"
                            style={{
                                cursor: 'pointer',
                                background: 'white',
                                borderRadius: '20px'
                            }}>
                            <CustomSlider
                                getAriaLabel={() => 'Price'}
                                orientation="horizontal"
                                valueLabelDisplay="auto"
                                getAriaValueText={valueLabelFormat}
                                valueLabelFormat={valueLabelFormat}
                                max={Number(maxPrice)}
                                value={price}
                                onChange={handlePriceChange}
                            />
                        </div>
                        <div className="flex justify-between text-white">
                            <button type="button"
                                    className="bg-primary-dark-green text-white px-4 py-2 rounded-lg"
                                    onClick={handleClearFilters}>Clear
                            </button>
                            <button type="button" className="bg-primary-dark-green text-white px-4 py-2 rounded-lg"
                                    onClick={handleSubmitFilter}>Apply
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {isFilterApplied && (selectedLocation || (selectedPriceRange[0] !== 0 || selectedPriceRange[1] !== Number(maxPrice))) && (
                <div className="mt-3 mx-3">
                    <div className="flex items-center gap-2">
                        <p className="text-primary-dark-green font-bold text-md">Filters:</p>
                        {selectedLocation &&
                            <p className="bg-primary-orange/20 text-primary-orange px-3 py-1 rounded-full text-xs"> {selectedLocation}</p>}
                        {(selectedPriceRange[0] !== 0 || selectedPriceRange[1] !== Number(maxPrice)) && (
                            <p className="bg-primary-dark-green/20 text-primary-dark-green px-3 py-1 rounded-full text-xs">{selectedPriceRange[0]} - {selectedPriceRange[1]}</p>
                        )}
                    </div>
                </div>
            )}
            {allBeaches.length > 0 ? (
                <SliderReact {...settings}>
                    {allBeaches.map((beach, index) => (
                        <div
                            className="relative flex flex-col"
                            onClick={() => selectBeach(beach.id)}
                            key={index}
                            onMouseEnter={() => setIsHovered(index)}
                            onMouseLeave={() => setIsHovered(null)}
                        >
                            <div className="cursor-pointer justify-between  md:mx-2  my-5 md:my-0 max-md:mx-1">
                                <img
                                    src={beach.thumbnail_image}
                                    alt={`Beach ${beach.id}`}
                                    className={`rounded-lg md:h-[350px] max-md:h-[200px] w-full object-cover`}
                                />
                                {/*{isHovered === index && (*/}
                                <div className="rounded-lg pt-2">
                                    <h3 className="md:text-xl max-md:text-sm font-bold text-primary-dark-green">{beach.name}</h3>
                                    <p className="text-sm font-light font-archivo text-primary-dark-green">{beach.location}</p>
                                    <div
                                        className="md:flex items-center max-md:grid max-md:grid-cols-2 gap-2 my-2 pt-2">
                                        {beach.tags?.map((tag) => (
                                            <div
                                                key={tag.value}
                                                className="bg-primary-orange/20 text-primary-orange max-md:my-1 max-md:py-2 max-md:text-center md:text-md max-md:text-xs md:px-2 rounded-md"
                                            >
                                                {tag.value}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/*)}*/}
                            </div>
                        </div>
                    ))}
                </SliderReact>
            ) : (
                <div className="text-center py-10">
                    <h3 className="text-primary-dark-green text-xl font-bold text-center">No Beaches Found</h3>
                </div>
            )}
        </div>
    );
};

export default FilteredSlider;
