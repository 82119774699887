import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    isLoadingPopular: false,
    isLoadingFilter:false,
    isLoading:false,
    mapScreenWidth: 1920,
    mapScreenHeight: 995,
    backgroundSlider: [],
    popularThisSeason: [],
    locationFilters: [],
    allBeaches: [],
    allBeachesMap:[],
    initialMap:[],
    countries:[],
    search:'',
    selectedBeach:{},
    itemBackground:{},
    searchedBeaches:[],
    selectedItems:[],
    map:[],
    totalAmount: null,
    isFull:false,
    totalItems: null,
    selectedCountry:(localStorage.getItem('country_id')) ?? null,
    dayPassPrice:35,
    showSideBar:false,
    minPrice:null,
    maxPrice:null,
}

export const homeSlice = createSlice(
    {
        name: 'home',
        initialState,
        reducers: {
            setIsLoading(state, action) {
                state.isLoading = action.payload
            },
            setBackgroundSlider(state, action) {
                state.backgroundSlider = action.payload
            },
            setPopularThisSeason(state, action) {
                state.popularThisSeason = action.payload
            },
            setLocationFilters(state, action) {
                state.locationFilters = action.payload
            },
            setAllBeaches(state, action) {
                state.allBeaches = action.payload
            },
            setAllBeachesMap(state, action) {
                state.allBeachesMap = action.payload
            },
            setIsLoadingFilter(state , action){
                state.isLoadingFilter = action.payload
            },
            setIsLoadingPopular(state , action){
                state.isLoadingPopular = action.payload
            },
            setCountries(state , action){
                state.countries = action.payload
                if(!state.selectedCountry){
                    state.selectedCountry = state.countries[0].id
                    localStorage.setItem('country_id' ,  state.countries[0].id)
                }
            },
            setSelectedCountry(state , action){
                state.selectedCountry = action.payload
            },

            setSearchedBeaches(state , action){
                state.searchedBeaches = action.payload
            },

            setSelectedBeach(state , action){
                state.selectedBeach = action.payload
            },
            setBeachMap(state , action){
                state.map = action.payload
            },
            setInitialBeachMap(state , action){
                state.initialMap = action.payload
            },
            setMapScreenWidth(state , action){
                state.mapScreenWidth = action.payload
            },
            setMapScreenHeight(state,action){
                state.mapScreenHeight = action.payload
            },
            setItemBackground(state , action){
                let itemBackgroundObject = {}
                action.payload.section_categories.map((item)=>{
                    itemBackgroundObject[item.code] = item.background_image
                })
                state.itemBackground = itemBackgroundObject
            },
            setSelectedItems(state,action){
                state.selectedItems =action.payload
            }  ,
            setTotalItems(state,action){
                state.totalItems =action.payload
            },
            setTotalAmount(state,action){
                state.totalAmount =action.payload
            },
            setIsFull(state, action){
                state.isFull = action.payload
            },
            setShowSideBar(state ,action){
                state.showSideBar = action.payload
            },
            setDayPassPrice(state , action){
                state.dayPassPrice = action.payload
            },
            setSearch(state , action){
                state.search = action.payload
            },
            setMaxPrice(state,action){
                state.maxPrice=action.payload
            },
            setMinPrice(state,action){
                state.minPrice=action.payload
            }
        }
    }
)
export default homeSlice.reducer;
