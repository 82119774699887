import React from "react";
import {useSelector} from "react-redux";

export const DisabledSection = ({section}) =>{
    const { itemBackground } = useSelector((state)=> state.home)
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    left: section?.startX,
                    top: section?.startY,
                    width: section?.endX - section?.startX,
                    height: section?.endY - section?.startY,
                    border: !section.type && '2px dashed #599198',
                    backgroundImage: `url(${itemBackground[section.type]})`,
                    pointerEvents: 'auto',
                    backgroundSize:'cover',
                    backgroundPosition:'center' ,
                    borderRadius:section.rounded && '50%'
                }}
            >
                {
                    !section.type &&
                    (
                        <>
                            <div style={{position:'absolute' , top:0 , left:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( -50% , -50%)'}} />
                            <div style={{position:'absolute' , top:0 , right:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( 50% , -50%)'}} />
                            <div style={{position:'absolute' , bottom:0 , left:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( -50% , 50%)'}}/>
                            <div style={{position:'absolute' , bottom:0 , right:0 , borderRadius:'50%' , width:'10px', backgroundColor:'#005662' , height:'10px' , transform:'translate( 50% , 50%)'}}/>
                        </>
                    )
                }
            </div>
        </>
    )
}
export default DisabledSection
