import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const names = [
    'John Doe',
    'Jane Doe',
    'Alice Johnson',
    'Bob Smith',
    '3 Smith',
    '2 Smith',
    '1 Smith',
    'Test Smith',
];

const theme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                    margin: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: '7px',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '0.5rem',
                    backgroundColor: '#E5E5E5',
                    padding: '0.5rem 1rem',
                    borderRadius: '9999px',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    color: '#333',
                },
            },
        },
    }
})

const GuestsMultiSelect = () => {
    const [personName, setPersonName] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: {value},
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <FormControl sx={{m: 1, width: 300}}>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    placeholder="Guest Name"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput/>}
                    renderValue={(selected) => (
                        <div className="flex flex-wrap gap-1">
                            {selected.map((value) => (
                                <div
                                    key={value}
                                    className="bg-gray-200 rounded-full flex items-center justify-between px-2 py-1 text-sm"
                                >
                                    {value}
                                    <span
                                        onClick={() => {
                                            setPersonName(personName.filter((name) => name !== value));
                                        }}
                                        className="cursor-pointer ml-2 text-gray-600"
                                    >
                                        &times;
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1}/>
                            <ListItemText primary={name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
}
export default GuestsMultiSelect;
