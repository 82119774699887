import './PopularThisSeason.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useEffect, useState} from "react";
import {Divider, Fade, Zoom} from "@mui/material";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
export const PopularThisSeason = () =>{
    const { popularThisSeason , isLoading} = useSelector((state) => state.home)
    const [currenPopular , setCurrentPopular] = useState([])
    const navigate = useNavigate()
    const [isMobileScreen , setIsMobileScreen] = useState(false)
    const customDivider = {
        backgroundColor: 'gray', // You can also change the color if needed
    };
    useEffect(()=>{
            let data = [...popularThisSeason]
            setCurrentPopular(data.splice(0 , 3))
    },[])

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleNext = () =>{
        if(popularThisSeason.length > 3){
            const updatedItems = [...currenPopular];
            const lastIndex = updatedItems[2].index
            updatedItems.splice(0 , 1)
            if(popularThisSeason[lastIndex+1]){
                updatedItems.push(popularThisSeason[lastIndex+1])
            }else{
                updatedItems.push(popularThisSeason[0])
            }
            setCurrentPopular(updatedItems)
        }
    }
    const handleBack = () => {
        if(popularThisSeason.length > 3){
            const updatedItems = [...currenPopular];
            const firstIndex = updatedItems[0].index
            updatedItems.splice(2 , 1)
            if(popularThisSeason[firstIndex-1]){
                updatedItems.unshift(popularThisSeason[firstIndex - 1])
            }else{
                updatedItems.unshift(popularThisSeason[popularThisSeason.length -1])
            }
            setCurrentPopular(updatedItems)
        }
    }
    const selectBeach = (id) => {
        localStorage.setItem('selected_beach', id)
        navigate('/beach-info')
    }
    return (
    <>
        {
                <div style={{backgroundColor:'#ebf2f3'}} className={"container-fluid"}>
                    <div className="row pt-5">
                        <div className="col-md-12 mb-lg-5 mb-md-5 mb-2 text-center">
                            <h2 className={"header-text"} >
                                Popular this season
                            </h2>
                        </div>
                    </div>
                    <div className="row pt-2 pt-lg-5 pt-md-5 pb-2 pb-lg-5 pb-md-5">
                        <div className="col-2 col-lg col-md  flex-grow-0 flex-shrink-1 d-grid justify-content-end align-items-center">
                            <ArrowBackIcon className={"ms-5"} onClick={handleBack}  sx={{color:'#005662' , cursor:'pointer'}} fontSize={'large'} />
                        </div>
                        {
                            !isMobileScreen ?

                            currenPopular.map((beach , index)=>(
                                <Fade key={"popular_beach_" + index} in={true}>
                                    <div onClick={()=>selectBeach(beach.id)} style={{cursor:'pointer'}} className="col">
                                        <div className={ index === 1 ? "popular-card middle-card" : "popular-card" }  style={{ backgroundImage: `url('${beach.thumbnail_image}')` }}>
                                            <div className="background-popular-overlay">
                                                <div className="container">
                                                    <div className="row">
                                                        {
                                                            beach.tags?.map((tag , index)=>(
                                                                <div className={index===0 ? "tag" : "tag ms-2"}>
                                                                    {tag.value}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="beach-information">
                                                        <div className="row ">
                                                            <h3 className={"popular-beach-information"}>{beach.name}</h3>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className={"popular-beach-information m-0"}>{beach.location}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            ))
                                :
                                <Fade key={"popular_beach_middle" } in={true}>
                                    <div onClick={()=>selectBeach(currenPopular[1].id)} style={{cursor:'pointer'}} className="col-8 p-0">
                                        <div className={"popular-card" }  style={{ backgroundImage: `url('${currenPopular[1].thumbnail_image}')` }}>
                                            <div className="background-popular-overlay">
                                                <div className="container">
                                                    <div className="row">
                                                        {
                                                            currenPopular[1].tags?.map((tag , index)=>(
                                                                <div className={index===0 ? "tag" : "tag ms-2"}>
                                                                    {tag.value}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="beach-information">
                                                        <div className="row ">
                                                            <h3 className={"popular-beach-information"}>{currenPopular[1].name}</h3>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <p className={"popular-beach-information m-0"}>{currenPopular[1].location}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>

                        }
                        <div className="col-2 col-lg col-md  flex-grow-0 flex-shrink-1 d-grid justify-content-start align-items-center">
                            <ArrowForwardIcon className={"me-5"} onClick={handleNext} sx={{color:'#005662' , cursor:'pointer'}} fontSize={'large'} />
                        </div>
                    </div>
                    <div className="row mt-5 pb-2 pb-lg-5 pb-md-5 justify-content-center">
                        <div className="col-md-10">
                            <Divider  style={customDivider} orientation="horizontal" flexItem />
                        </div>
                    </div>
                </div>
        }
    </>
    )
}
export default PopularThisSeason
