import {authSlice} from "./AuthSlice.ts";
import axios from "axios";
import async from "async";
import {TUserLogin} from "../../types/login";

export const loginAction = (data:TUserLogin) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            const response = await axios.post('/login', data)
            return dispatch(setupLogin(response))
        } catch (e) {
            dispatch(authSlice.actions.setErrorLogin(true))
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const forgotPasswordAction = (data) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            const response = await axios.post('/forgot-password-email', data)
            localStorage.setItem('email', data.email)
            return response.data.data
        } catch (e) {
            dispatch(authSlice.actions.setErrorLogin(true))
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}

export const registerAction = (data) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            const response = await axios.post('/register', data)
            return dispatch(setupLogin(response))
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const createGuestUserAction = (data) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            const response = await axios.post('/create-guest-user', data)
            return dispatch(setupLogin(response))
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const loginProvider = (data, provider) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            const response = await axios.post('/login/provider', {...data, provider: provider})
            return dispatch(setupLogin(response))
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const verifyEmailOtp = (otp) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            await axios.post('/verify-email', {otp: otp})
        } catch (e) {
            throw e
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const checkOtp = (otp) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            const email = localStorage.getItem("email");
            await axios.post('/check-otp', { otp: otp, email: email })
            localStorage.setItem('otp', otp);
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false));
        }
    }
}

export const resendEmailOtp = (otp) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true))
        try {
            await axios.get('/resend-email-verification')
        } catch (e) {
            throw e
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const createPasswordAction = (password, password_confirmation) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true));
        try {
            const otp = localStorage.getItem('otp');
            const email = localStorage.getItem('email');
            const data = { otp, email, password, password_confirmation };

            const response = await axios.post('/create-new-password', data);
            return response.data;
        } catch (e) {
            console.error("Error in sending data:", e);
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false));
        }
    }
};

export const setupLogin = (response) => {
    return async (dispatch) => {
        let token = response.data.data.token
        let role = response.data.data.user.role
        let firstName = response.data.data.user.first_name
        let lastName = response.data.data.user.last_name
        localStorage.setItem('token', token)
        localStorage.setItem('role', role)
        localStorage.setItem('firstName', firstName)
        localStorage.setItem('lastName', lastName)
        if (role === 'beach' || role === 'beach_officer') {
            sessionStorage.setItem('beach_id', response.data.data.beach.id)
        }
        dispatch(authSlice.actions.setToken(token))
        dispatch(authSlice.actions.setRole(role))
        dispatch(authSlice.actions.setFirstName(firstName))
        dispatch(authSlice.actions.setLastName(lastName))
        return {
            'role': role,
            'email_verified_at': response.data.data.user.email_verified_at
        }
    }
}

export const signOutUser = () => {
    return async (dispatch) => {
        let countrySelected = localStorage.getItem('country_id');
        localStorage.clear();
        localStorage.setItem('country_id', countrySelected);
        dispatch(authSlice.actions.setInitialValues())

    }
}
export const getProfileInformation = () => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.get('/profile-information')
            dispatch(authSlice.actions.setProfileInfo(response.data.data))
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}
export const getBagInformation = (beachId) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.get('/bag-information/beach/' + beachId)
            let data = response.data.data
            data.forEach((bag) => {
                let names = []
                let count = bag.items.reduce((accumulator, currentItem) => {
                    return accumulator + currentItem.count;
                }, 0)
                for (let i = 0; i < count - 1; i++) {
                    names.push({
                        name: 'Guest ' + (i + 1)
                    })
                }
                bag.names = names
            })
            dispatch(authSlice.actions.setBagInformation(data))
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}
export const setBagInformation = (data) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setBagInformation(data))
    }
}
export const getBeachesInBag = () => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.get('/bag-beaches')
            dispatch(authSlice.actions.setBagInformation([]))
            dispatch(authSlice.actions.setBagBeaches(response.data.data))
            return response.data.data
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}
export const payAtDoorAction = (beachId , dataToSend) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(true));
        try {
            const response = await axios.post(`/beach/${beachId}/pay-at-door` , {data:dataToSend});
            return response.data;
        } catch (e) {
            console.error("Error processing cash payment:", e);
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false));
        }
    };
};
export const setTotalValues = (value, beforeTotal) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setSubTotal(beforeTotal))
        dispatch(authSlice.actions.setTotal(value))
    }
}
export const setIsLoading = (value) => {
    return async (dispatch) => {
        dispatch(authSlice.actions.setIsLoading(value))
    }
}

export const deleteUniqueBagItem = (id) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.delete('/uniqueBag/' + id)
            let data = response.data.data
            data.map((bag) => {
                let names = []
                for (let i = 0; i < bag.count - 1; i++) {
                    names.push({
                        name: 'Guest ' + (i + 1)
                    })
                }
                bag.names = names
            })
            dispatch(authSlice.actions.setBagInformation(data))
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}
export const getClientSecret = (beachId, data , paymentType) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.post('/beach/' + beachId + '/get-client-secret', {data})
            if(paymentType === 'stripe'){
                dispatch(authSlice.actions.setClientSecret(response.data.data.client_secret))
            }
            return response.data.data
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}
export const changeGuestEmail = () =>{
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.post('/change-guest-email')
            localStorage.removeItem('token')
            localStorage.removeItem('email')
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}
export const validatePaymentIntent = (paymentIntent) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            let beachId = localStorage.getItem('bag_beach_id')
            const response = await axios.post('/beach/' + beachId + '/validate-payment-intent', {payment_intent: paymentIntent})
            dispatch(authSlice.actions.setCurrentReceipts(response.data.data))
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}

export const montySuccess = (paymentIntent) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            let beachId = localStorage.getItem('bag_beach_id')
            const response = await axios.post('/beach/' + beachId + '/monty-success', {payment_intent: paymentIntent})
            dispatch(authSlice.actions.setCurrentReceipts(response.data.data))
        } catch (e) {
            throw e;
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))

        }
    }
}


export const updateProfileInfo = (data) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = axios.post('/profile-information/update', data)
        } catch (e) {

        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}

export const deleteProfileAction = (data) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.post('/delete-account')
            let countrySelected = localStorage.getItem('country_id');
            localStorage.clear();
            localStorage.setItem('country_id', countrySelected);
            dispatch(authSlice.actions.setInitialValues())


        } catch (e) {

        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}

export const updatePassword = (data) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.put('/update-password', data)
            dispatch(authSlice.actions.setErrorUpdatePassword(false))

        } catch (e) {
            dispatch(authSlice.actions.setErrorUpdatePassword(true))
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}

export const getMyReservations = () => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.get('/my-reservations')
            dispatch(authSlice.actions.setMyReservations(response.data.data.reservations))
            dispatch(authSlice.actions.setMyBeachBedPoints(response.data.data.points))
            dispatch(authSlice.actions.setMyLevel(response.data.data.current_level))
            dispatch(authSlice.actions.setNextLevel(response.data.data.next_level))
            dispatch(authSlice.actions.setCurrentTotalReservations(response.data.data.total_reservations))

        } catch (e) {
            dispatch(authSlice.actions.setErrorUpdatePassword(true))
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const getMyTickets = () => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            const response = await axios.get('/my-tickets')
            dispatch(authSlice.actions.setMyTickets(response.data.data.tickets))
            dispatch(authSlice.actions.setMySplits(response.data.data.splits))

        } catch (e) {
            dispatch(authSlice.actions.setErrorUpdatePassword(true))
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}

export const setupProfileAction = (values) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            await axios.post('/continue-profile', values)
            let firstName = values.first_name
            let lastName = values.last_name
            localStorage.setItem('firstName', firstName)
            localStorage.setItem('lastName', lastName)
            dispatch(authSlice.actions.setFirstName(firstName))
            dispatch(authSlice.actions.setLastName(lastName))
        } catch (e) {
            throw e
        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const getReservationByCode = (code) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))
            let token = localStorage.getItem('token')
            let response = token ? await axios.get('/reservation-by-qr-authenticated?code=' + code) : await axios.get('/reservation-by-qr?code=' + code)
            dispatch(authSlice.actions.setQrReservation(response.data.data))
        } catch (e) {
            dispatch(authSlice.actions.setQrReservation({}))

        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const refundReservationAction = (ticket, allTickets) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.setIsLoading(true))

            let response = await axios.post('/refund/' + ticket.id)
            let deletedTicketIndex = allTickets.findIndex(item => {
                return item.id === ticket.id
            })
            let newTickets = [...allTickets]
            newTickets.splice(deletedTicketIndex, 1)
            dispatch(authSlice.actions.setMyTickets(newTickets))
        } catch (e) {

        } finally {
            dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const changeSplittedItemEmail = (itemToSplit, email, mySplits) => {
    return async (dispatch) => {
        try {
            // dispatch(authSlice.actions.setIsLoading(true))
            let response = await axios.post('/change-email/' + itemToSplit.id, {email: email})
            let changedSplitIndex = mySplits.findIndex(item => {
                return item.id === itemToSplit.id
            })
            let newTickets = [...mySplits]
            newTickets[changedSplitIndex] = {email: email, ...newTickets[changedSplitIndex]}
            dispatch(authSlice.actions.setMySplits(newTickets))
        } catch (e) {
            throw e
        } finally {
            // dispatch(authSlice.actions.setIsLoading(false))
        }
    }
}
export const setShowSnackbarVisibility = (isVisible) => (dispatch) => {
    dispatch(authSlice.actions.setShowSnackbar(isVisible));
};
