import {createGuestUserAction, loginAction} from "../../../store/Auth/AuthActions.ts";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addToGuestCard} from "../../../store/Guest/GuestActions";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Checkbox} from "@mui/material";
import {Link} from "react-router-dom";

const GuestLogin = ({handleUserLoggedIn}) => {
    const {bag, originalItems} = useSelector((state) => state.guest);
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isGuestCheckout, setIsGuestCheckout] = useState(false);
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const loginFormik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Required"),
            password: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            login(values);
        },
    });

    const guestFormik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("First Name is required"),
            last_name: Yup.string().required("Last Name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            password: Yup.string().required("Password is required"),
        }),
        onSubmit: (values) => {
            createGuestUser(values);
        },
    });

    function login(values) {
        if (values.email && values.password) {
            dispatch(loginAction(values))
                .then((response) => {
                    dispatch(addToGuestCard(originalItems, bag))
                        .then(() => {
                            localStorage.setItem("email", values.email);
                            handleUserLoggedIn();
                        });
                })
                .catch((error) => {
                    setWrongCredentials(true);
                    setApiError(error.response?.data?.email?.[0] || "Invalid credentials");
                });
        }
    }

    function createGuestUser(values) {
        if (values.email && values.first_name && values.last_name) {
            let data = {
                ...values,
                beach_id: localStorage.getItem('bag_beach_id')
            }
            dispatch(createGuestUserAction(data))
                .then((response) => {
                    localStorage.setItem("email", values.email);
                    sessionStorage.setItem("is_guest", "true");
                    dispatch(addToGuestCard(originalItems, bag))
                        .then(() => {
                            handleUserLoggedIn();
                        });
                })
                .catch((error) => {
                    console.log(error)
                    setApiError(error.response?.data.message || "An error occurred during registration");
                });
        }
    }

    return (
        <div className="container-fluid">
            <div className="col-md-12 text-center">
                <h1 className="my-3 text-primary-dark-green font-bold text-2xl">
                    {!isGuestCheckout ? "Log In" : "Create Account & Checkout"}
                </h1>
            </div>

            {!isGuestCheckout ? (
                <>
                    <div className="row justify-content-center">
                        <div className="col-md-6 mt-3">
                            <input
                                className="inputStyleDark"
                                type="text"
                                {...loginFormik.getFieldProps("email")}
                                placeholder="Email"
                            />
                            {loginFormik.touched.email && loginFormik.errors.email ? (
                                <div className="text-red-500">{loginFormik.errors.email}</div>
                            ) : null}
                            <div>
                                <input
                                    className="inputStyleDark mt-3"
                                    type="password"
                                    {...loginFormik.getFieldProps("password")}
                                    placeholder="Password"
                                />
                                {loginFormik.touched.password && loginFormik.errors.password ? (
                                    <div className="text-red-500">{loginFormik.errors.password}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {wrongCredentials && (
                        <div className="row w-100 justify-content-center mt-2">
                            <div className="col-md-6">
                                <div className="alert alert-danger text-center py-2" role="alert">
                                    {apiError}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row justify-content-center">
                        <div className="flex justify-center items-center">
                            <button
                                className="bg-primary-dark-green text-white rounded-full px-10 mt-5 py-2 text-xl"
                                onClick={loginFormik.handleSubmit}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mb-8">
                        <a
                            onClick={() => setIsGuestCheckout(true)}
                            style={{
                                color: "#005662AA",
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            className="text-sm"
                        >
                            Quick Checkout
                        </a>
                    </div>
                </>
            ) : (
                <>
                    <div className="row">
                        <p className="text-center text-primary-dark-green mb-2">
                            Please provide basic information
                        </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <input
                                className="inputStyleDark"
                                type="text"
                                {...guestFormik.getFieldProps("first_name")}
                                placeholder="First Name"
                            />
                            {guestFormik.touched.first_name && guestFormik.errors.first_name ? (
                                <div className="text-red-500 text-sm px-2">{guestFormik.errors.first_name}</div>
                            ) : null}
                            <input
                                className="inputStyleDark mt-3"
                                type="text"
                                {...guestFormik.getFieldProps("last_name")}
                                placeholder="Last Name"
                            />
                            {guestFormik.touched.last_name && guestFormik.errors.last_name ? (
                                <div className="text-red-500 text-sm px-2">{guestFormik.errors.last_name}</div>
                            ) : null}
                            <input
                                className="inputStyleDark mt-3"
                                type="text"
                                {...guestFormik.getFieldProps("email")}
                                placeholder="Email"
                            />
                            {guestFormik.touched.email && guestFormik.errors.email ? (
                                <div className="text-red-500 text-sm px-2">{guestFormik.errors.email}</div>
                            ) : null}
                            <input
                                className="inputStyleDark mt-3"
                                type="password"
                                {...guestFormik.getFieldProps("password")}
                                placeholder="Password"
                            />
                            {guestFormik.touched.password && guestFormik.errors.password ? (
                                <div className="text-red-500 text-sm px-2">{guestFormik.errors.password}</div>
                            ) : null}
                            <div className="flex items-center mt-2">
                                <Link to="/terms-conditions" className="text-sm text-primary-dark-green m-0"
                                      target="_blank">
                                    Terms and conditions
                                </Link>
                                <Checkbox
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                    sx={{color: '#B0CBCE', '&.Mui-checked': {color: '#F96300'}}}
                                />
                            </div>
                        </div>
                    </div>
                    {apiError && (
                        <div className="row w-100 justify-content-center mt-2">
                            <div className="col-md-6">
                                <div className="alert alert-danger text-center py-2" role="alert">
                                    {apiError}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-center items-center">
                        <button
                            className={`text-white bg-primary-dark-green rounded-full my-4 px-10 py-2 ${!isChecked ? 'cursor-not-allowed opacity-50' : ''}`}
                            onClick={guestFormik.handleSubmit}
                            disabled={!isChecked}
                        >
                            Create Account & Checkout
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default GuestLogin;
