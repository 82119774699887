import { DataGrid } from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllBeaches, resetMap, setBeachId, setSavedSectionsAction} from "../../store/Map/MapActions";
import {useNavigate} from "react-router-dom";
export const AllBeaches = () =>{
    const {allBeaches} = useSelector((state) => state.map)
    const dispatch = useDispatch()
    const navigate= useNavigate()

    useEffect(()=>{
        dispatch(setSavedSectionsAction([]))
        dispatch(resetMap())
        dispatch(getAllBeaches())
    },[])

    const [selectedBeachIndex , setSelectedBeachIndex] = useState();
    const columns = [
        { field: 'id', headerName: 'ID' ,width: 70 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'location', headerName: 'Location', width: 130 },
        { field: 'check_in', headerName: 'Check In', width: 130 },
        { field: 'about', headerName: 'About', width: 300 },
        { field: 'is_activated', headerName: 'Active', width: 300 },
    ];
    const onHandleBeachSelection = (index) =>{
      setSelectedBeachIndex(index)
    }
    const proceedToMap = () =>{
        let index = selectedBeachIndex[0]
        dispatch(setBeachId(index))
        navigate('/map')
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 mt-4">
                    <h2 className={"header-text"}>Select one of the following beaches</h2>
                    <h4 className={"header-text mt-3"}>Beaches List:</h4>
                </div>
            </div>
            <div className="row mt-4">
                <div style={{  width: '100%' }}>
                    <DataGrid
                        rows={allBeaches}
                        columns={columns}
                        disableMultipleSelection={true}
                        pageSize={10}
                        autoHeight
                        rowsPerPageOptions={[5]}
                        rowSelectionModel={selectedBeachIndex}
                        onRowSelectionModelChange={onHandleBeachSelection}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        experimentalFeatures={{newEditingApi: true}}
                        pageSizeOptions={[5, 10]}
                    />
                </div>
            </div>
            {
                selectedBeachIndex &&
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="float-end">
                            <button onClick={proceedToMap} className={"primary-button"}>Continue to Map</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default AllBeaches
