import React, {useEffect} from 'react';
import './RightSideBar.css'
import {Container} from "./Container";
import {useDispatch, useSelector} from "react-redux";
import {getBuilderInfo, getMapByBeachId} from "../../store/Map/MapActions";
import LoaderComp from "../../components/LoaderComp";
const MapPage = () => {
    const dispatch = useDispatch()
    const {isLoading} = useSelector((state)=> state.map)

    useEffect(() => {
        dispatch(getBuilderInfo())
        dispatch(getMapByBeachId())

    }, [])
    return (
        <div>
            {
                !isLoading ?
                    <Container/>
                    :
                    <LoaderComp />
            }
        </div>
    );
};

export default MapPage;
