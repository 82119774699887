export const homePageBanner = {
    image: "/images/home/banner.png",
    logo:"/logo-text.svg",
    description: "Explore and reserve at the resort of your choice",
    first_button:"Join the waiting list",
};

export const smallPageBanner = {
    image: "/images/home/smallBanner.png",
};

export const marqueeData=[
    {
        id:1,
        text:"Rent Your Perfect Beach Bed",
        logo:"/bed.svg"
    },
    {
        id:2,
        text:"Elevate Your Beachside Comfort",
        logo:"/sunglasses.svg"
    },
    {
        id:3,
        text:"Enjoy Food & Beverages Delivered To Your Beach Bed",
        logo:"/juice.svg"
    }
]

export const filteredSliderData =[
    {
        id:1,
        image:"/images/location-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Byblos, Jbeil"
    },
    {
        id:2,
        image:"/images/location-2.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"Ocean Blue",
        location:"El Jiye"
    },
    {
        id:3,
        image:"/images/location-3.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:4,
        image:"/images/location-2.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"El Jiye"
    },
    {
        id:5,
        image:"/images/location-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:6,
        image:"/images/location-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:7,
        image:"/images/location-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:8,
        image:"/images/location-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
]
export const defaultSliderData =[
    {
        id:1,
        image:"/beach-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Byblos, Jbeil"
    },
    {
        id:2,
        image:"/beach-2.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"Ocean Blue",
        location:"El Jiye"
    },
    {
        id:3,
        image:"/beach-3.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:4,
        image:"/beach-2.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"El Jiye"
    },
    {
        id:5,
        image:"/beach-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:6,
        image:"/beach-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:7,
        image:"/beach-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
    {
        id:8,
        image:"/beach-1.png",
        tags:[
            {
                id:1,
                name:"Beach"
            },
            {
                id:1,
                name:"Resort"
            },
        ],
        name:"EddeSands",
        location:"Khaldeh, Lebanon"
    },
]