import React from "react";

function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="/svgs/prev.svg" className="w-10"/>
        </div>
    );
}

export default PrevArrow;