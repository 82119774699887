import { useState, useEffect } from "react";
import { Box, Grid, TextField, CircularProgress } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

export const SearchUsersDropdown = ({ handleSearch, users, handleSelect, initialValue }) => {
    const [value, setValue] = useState(initialValue);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userFound, setUserFound] = useState(false);

    const handleInputChange = (event, newInputValue) => {
        setLoading(true);
        setUserFound(false);
        handleSearch(newInputValue);
        setInputValue(newInputValue);
    };

    useEffect(() => {
        if (!loading) return;
        const timer = setTimeout(() => {
            setLoading(false);
            setUserFound(true);
        }, 500);

        return () => clearTimeout(timer);
    }, [users]);

    return (
        <Autocomplete
            id="search-for-users"
            style={{ color: 'black' }}
            sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#B0CBCE',
                    },
                    '&:hover fieldset': {
                        borderColor: '#005F95',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#005F95',
                    }
                },
                '& .MuiSvgIcon-root': {
                    color: '#B0CBCE',
                }
            }}
            filterOptions={(x) => x}
            options={users}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : (option.first_name + ' ' + option.last_name)
            }
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={inputValue && !loading && userFound && users.length === 0 ? "No users found" : ""}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                handleSelect(newValue);
            }}
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <TextField {...params} label="Search..." fullWidth sx={{
                    '& label': {
                        fontFamily: 'archivo-regular',
                        color: '#B0CBCE'
                    },
                    '& .MuiInputBase-root': {
                        fontFamily: 'archivo-regular',
                        color: '#B0CBCE'
                    }
                }} />
            )}
            renderOption={(props, user) => (
                <li {...props}>
                    <Grid container alignItems="center">
                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                            <Box
                                sx={{ color: '#005F95 !important' }}
                                component="span"
                            >
                                {user.first_name} {user.last_name}
                            </Box>
                            <Box
                                sx={{ color: '#005F95 !important' }}
                                component="span"
                            >
                                {}
                            </Box>
                        </Grid>
                    </Grid>
                </li>
            )}
            loading={loading}
            loadingText={
                <Box display="flex" alignItems="center">
                    <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                    Loading...
                </Box>
            }
        />
    );
};

export default SearchUsersDropdown;
