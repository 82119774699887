export const Banner = ({data , onScrollClick}) => {
    const {image, logo, description , isBeachInfo} = data;
    return (
        <div
            className="relative bg-no-repeat overflow-x-clip md:h-screen bg-cover flex items-center justify-center text-white"
            style={{backgroundImage: `url('${image}')`}}
        >
            <div className="container">
                <div className="relative md:w-1/4 max-md:mt-16 py-8 z-30 gap-y-5 flex flex-col items-center">
                    {/*<img src={logo} className="w-50 h-50"/>*/}
                    <p className="border-b-2 w-10"></p>
                    <p className="sm:text-3xl text-lg font-light  text-center font-archivo">{description}</p>
                    <button
                        type="button"
                        className="cursor-pointer"
                        onClick={onScrollClick} >
                        <img src="/svgs/scroll-down.svg" alt="Scroll Down"/>
                    </button>
                </div>
            </div>
            <img src="/images/curve.png"
                 className="absolute hidden md:block left-0 -bottom-1 right-0 w-full md:h-52 max-md:h-28 !z-30"/>
            <div className={"absolute inset-0 z-10 " + (isBeachInfo ? "bg-black opacity-20" : "")}></div>
        </div>
    )
}
export default Banner;
