import {Box, Modal} from "@mui/material";
import React, {useState} from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from "axios";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import * as yup from "yup";

const validationSchema = Yup.object({
    old_password: Yup.string().required('Password is required'),
    // password: Yup.string()
    //     .min(8, 'New Password must be at least 8 characters')
    //     .required('New Password is required'),
    password: yup
        .string()
        .required("New Password is required")
        .min(8, "Password must be 8 characters at minimum")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            "Password must contain at least one symbol"
        ),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});
const ResetPassword = ({open, handleClose}) => {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    const handleNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSubmitNewPassword = async (values, { setSubmitting, setFieldError }) => {
        try {
            const beachId = sessionStorage.getItem('beach_id');
            const data = {
                old_password: values.old_password,
                password: values.password,
                password_confirmation: values.confirm_password
            };
            const response = await axios.post(`/beach/${beachId}/change-password`, data);
            handleClose();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setFieldError('old_password', error.response.data.message);
            }
            setSubmitting(false);
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px',
        outline: 0,
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Formik
                    initialValues={{old_password: '', password: '', confirm_password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmitNewPassword}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="bg-white text-primary-dark-green rounded-md">
                                <h2 className="text-xl font-bold mb-4 text-center">Reset Password</h2>
                                <p className="text-sm text-primary-gray font-archivo-light py-3">
                                    Please enter below a new password in order to reset your old one.
                                </p>
                                <div className="py-3">
                                    <p>Current Password</p>
                                    <div className="relative">
                                        <Field
                                            type={showCurrentPassword ? "text" : "password"}
                                            placeholder="Enter Password"
                                            className="text-primary-gray text-sm font-archivo-light p-2 border border-[#A1A1A1]/20 mt-2 rounded-md w-full"
                                            name="old_password"
                                        />
                                        <button
                                            type="button"
                                            onClick={handleCurrentPassword}
                                            className="absolute right-2 top-4 transform -translate-y-1/2 text-primary-gray"
                                        >
                                            {showCurrentPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                        </button>
                                        <ErrorMessage name="old_password" component="div" className="text-red-500 text-sm mt-1" />
                                    </div>
                                </div>
                                <div className="py-3">
                                    <p>New Password</p>
                                    <div className="relative">
                                        <Field
                                            type={showNewPassword ? "text" : "password"}
                                            placeholder="Enter Password"
                                            className="text-primary-gray text-sm font-archivo-light p-2 border border-[#A1A1A1]/20 mt-2 rounded-md w-full"
                                            name="password"
                                        />
                                        <button
                                            type="button"
                                            onClick={handleNewPassword}
                                            className="absolute right-2 top-4 transform -translate-y-1/2 text-primary-gray"
                                        >
                                            {showNewPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                        </button>
                                        <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                                    </div>
                                </div>
                                <div className="py-3">
                                    <p>Confirm New Password</p>
                                    <div className="relative">
                                        <Field
                                            type={showConfirmPassword ? "text" : "password"}
                                            placeholder="Enter Password"
                                            className="text-primary-gray text-sm font-archivo-light p-2 border border-[#A1A1A1]/20 mt-2 rounded-md w-full"
                                            name="confirm_password"
                                        />
                                        <button
                                            type="button"
                                            onClick={handleConfirmPassword}
                                            className="absolute right-2 top-4 transform -translate-y-1/2 text-primary-gray"
                                        >
                                            {showConfirmPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                        </button>
                                        <ErrorMessage name="confirm_password" component="div" className="text-red-500 text-sm mt-1" />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        type="submit"
                                        className="mt-4 bg-primary-dark-green text-white rounded-md w-full py-2"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
}

export default ResetPassword;
