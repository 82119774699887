import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import React, {useEffect, useState} from "react";
import DashboardTab from "../../components/DashboardTab.tsx";
import ResetPassword from "./ResetPassword.tsx";
import axios from "axios";
import {Backdrop, CircularProgress} from "@mui/material";

const Settings = () => {
    const beachId = sessionStorage.getItem('beach_id');
    const [selectedTab, setSelectedTab] = useState("beach_details")
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [beachData, setBeachData] = useState({
        beach: {},
        beach_admin: {}
    });
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    const tabs = [
        {label: 'Beach Details', value: 'beach_details'},
        {label: 'Admin Details', value: 'admin_details'}
    ];
    const handleOpenResetPassword = () => {
        setOpenResetPassword(true);
    };

    const handleCloseResetPassword = () => {
        setOpenResetPassword(false);
    };
    const getBeachProfile = async () => {
        if (beachId) {
            setIsLoading(true);
            try {
                const response = await axios.get(`/beach/${beachId}/get-profile`);
                setBeachData({
                    beach: response.data.data.beach,
                    beach_admin: response.data.data.beach_admin,
                });
            } catch (error) {
                console.error("Error", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            console.error("No beachId found");
        }
    };
    useEffect(() => {
        getBeachProfile();
    }, [])

    return (
        <>
            {isLoading && (
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            )}
            <div className="container pt-10">
                <h2 className="text-primary-dark-green text-2xl font-bold">Settings</h2>
                <p className="text-primary-gray text-xs pt-2">Here are your administrator profile and the particulars
                    regarding your beach setup.
                    <br/> Feel free to update your personal information as needed. </p>
                <div className="flex justify-between items-start shadow-2xl rounded-2xl mt-8">
                    <div className="md:grid md:grid-cols-[1fr_2fr] gap-3">
                        <img src={beachData.beach.thumbnail_image}
                             className="rounded-tl-2xl rounded-bl-2xl shadow-2xl h-full"/>
                        <div className="py-8 max-md:px-8">
                            <p className="text-primary-dark-green text-xl font-bold">{beachData.beach.name}</p>
                            <div className="flex items-center gap-2 pt-2 text-primary-gray">
                                <LocationOnIcon className="text-[#B0CBCE]"/>
                                {beachData.beach.location}
                            </div>
                            <div className="flex items-center gap-2 pt-2 text-primary-gray">
                                <PersonIcon className="text-[#B0CBCE]"/>
                                Beach Admin: <p
                                className="text-primary-dark-green">{beachData.beach_admin.first_name} {beachData.beach_admin.last_name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="md:flex gap-2 m-3">
                        {
                            beachData.beach.tags && beachData.beach.tags.map((tag, index) => (
                                <div key={index}
                                     className="bg-primary-orange/20 text-primary-orange rounded-md px-3 py-1 max-md:my-2 max-md:text-sm">
                                    {tag.value}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="flex items-center gap-2 mt-4">
                    <DashboardTab tabs={tabs} selectedTab={selectedTab} onTabChange={handleTabClick}/>
                </div>
                {selectedTab === "beach_details" && (
                    <div className="pt-3">
                        <p className="text-primary-dark-green">Description:</p>
                        <p className="text-primary-gray text-sm font-archivo-light py-3">{beachData.beach.about}</p>
                        <p className="text-primary-dark-green">Services:</p>
                        <div className="md:flex max-md:grid grid-cols-2 max-md:text-sm items-center gap-2 py-3">
                            {beachData.beach.services && beachData.beach.services.map((service, serviceIndex) => (
                                <div
                                    key={serviceIndex}
                                    className="flex items-center gap-2 bg-primary-gray/20 px-3 py-2 rounded-md text-primary-dark-green"
                                >
                                    <img src={service.icon} alt={service.title} className="w-8 h-8"/>
                                    <p className="font-archivo-light">{service.title}</p>
                                </div>
                            ))}
                        </div>
                        <p className="text-primary-dark-green pt-4">Offerings:</p>
                        <div className="md:grid grid-cols-3 gap-3 py-3">
                            {beachData.beach.items && beachData.beach.items.filter(item => item.can_be_reserved === 1 && item.is_a_seat === 1)
                                .map((offering, offeringIndex) => (
                                    <div key={offeringIndex} className="max-md:py-3">
                                        <div className="flex items-center gap-2 text-primary-dark-green">
                                            <img src={offering.image} alt={offering.name} className="w-5 h-5"/>
                                            <p className="font-bold">{offering.name}</p> / <p
                                            className="font-archivo-light">{offering.pivot.number_of_persons} prs</p>
                                        </div>
                                        <div className="text-primary-gray pt-2 flex items-center gap-2">
                                            <p className="text-2xl text-primary-orange">$</p>
                                            <p className="text-sm">${offering.pivot.weekday_price} Weekdays |
                                                ${offering.pivot.weekend_price} Weekends &
                                                Holidays</p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
                {selectedTab === "admin_details" && (
                    <div className="pt-3 grid grid-cols-2 gap-3">
                        <div>
                            <p className="text-primary-dark-green">Full Name:</p>
                            <input type="text" placeholder={beachData.beach_admin.first_name}
                                   value={beachData.beach_admin.first_name}
                                   className="text-primary-gray text-sm font-archivo-light p-2 border border-[#A1A1A1]/20 mt-2 rounded-md"/>
                        </div>
                        <div>
                            <p className="text-primary-dark-green">Email:</p>
                            <input type="text" placeholder={beachData.beach_admin.email}
                                   value={beachData.beach_admin.email}
                                   className="text-primary-gray text-sm font-archivo-light p-2 border border-[#A1A1A1]/20 mt-2 rounded-md"/>
                        </div>
                        <div>
                            <p className="text-primary-dark-green">Password:</p>
                            <div
                                   className="text-primary-gray text-sm font-archivo-light p-2 border border-[#A1A1A1]/20 mt-2 rounded-md">******
                                </div>
                            <button
                                type="button"
                                className="text-primary-orange font-archivo-light underline text-sm pt-1 cursor-pointer"
                                onClick={handleOpenResetPassword}
                            >
                                Reset Password
                            </button>
                        </div>
                    </div>
                )}
                <ResetPassword open={openResetPassword} handleClose={handleCloseResetPassword}/>
            </div>
        </>
    )
}
export default Settings;
