import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

const PayAtDoor = () => {
    return (
        <div className="">
            <Header hasBackground={true}/>
            <div className="container my-64">
                <div className="flex items-center">
                    <ThumbUpAltIcon className="text-primary-dark-green" style={{fontSize:'30px'}}/>
                    <h1 className={"text-primary-dark-green text-3xl max-md:text-xl"}>Reservation Confirmed!</h1>
                </div>
                <p className={"text-primary-dark-green"}>Please Check Your Email</p>
            </div>
            <Footer/>
        </div>
    )
}
export default PayAtDoor
