import {PieChart as MUIPieChart} from '@mui/x-charts/PieChart';

export const PieChart = ({ data, colors, innerRadius }) => {
    if (!data) {
        return null;
    }
    return (
        <MUIPieChart
            series={[
                {
                    data: data.map(({id, value, label}) => ({
                        id,
                        value,
                        label
                    })),
                    innerRadius: innerRadius || '50%'
                },
            ]}

            height={200}
            colors={colors}
        />
    )
}

export default PieChart;
