import {Box, Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    directFileUpload,
    openImageCaptureSection,
    saveBeachMapInBackgroundAction,
    setSavedSectionsAction
} from "../../../store/Map/MapActions";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";

const CaptureModal = () =>{
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius:'15px',
        outline:0,
    };
    const {isCapturingImage , itemToCapture , itemToCaptureIndex , savedSections } = useSelector((state) => state.map)
    const dispatch = useDispatch()
    const [displayImage , setDisplayImage] = useState('')
    const [file , setFile] = useState()
    const handleClose = () => {
        dispatch(openImageCaptureSection({} , false , null))
    }
    const handleFileChange = (e) =>{
        setFile(e.target.files[0])
        setDisplayImage(URL.createObjectURL(e.target.files[0]))
    }

    const saveImage = () =>{
        const data = new FormData()
        data.append('image' , file)
        if(itemToCapture.imageAttached){
           data.append('old_file_name' , itemToCapture.imageAttachedName)
        }
        dispatch(directFileUpload(data))
            .then((res)=>{
                let data = [...savedSections]
                data[itemToCaptureIndex] = {...itemToCapture, imageAttached:process.env.REACT_APP_BACKEND_URL_STORAGE + 'CaptureImages/' + res , imageAttachedName:res}
                dispatch(setSavedSectionsAction(data))
                let currentMapWidth = window.innerWidth *(10/12);
                let currentMapSizeHeight = window.innerHeight;
                dispatch(saveBeachMapInBackgroundAction(data ,currentMapWidth , currentMapSizeHeight ))
                handleClose()
            })
    }

    return (
        <Modal
            open={isCapturingImage}
            onClose={handleClose}>
            <Box sx={style}>
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12 mt-1">
                            <div className="float-end">
                                <CloseIcon onClick={handleClose} style={{cursor:'pointer'}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12 text-center mt-2">
                            <h3>Attach Image</h3>
                        </div>
                    </div>
                    {
                       ( itemToCapture.imageAttached && !displayImage)&&
                        <div className="row mt-3">
                            <div className="col-md-12 row justify-content-center text-center">
                                    <img src={itemToCapture.imageAttached} style={{height:'300px', width:'500px' , borderRadius:'10px'}} />
                            </div>
                        </div>
                    }
                    {
                      displayImage &&
                        <div className="row mt-3">
                            <div className="col-md-12 text-center">
                                <img src={displayImage} style={{height:'300px', width:'500px' , borderRadius:'10px'}} />
                            </div>
                        </div>
                    }
                    <div className="row mb-3">
                        <div className="col-md-12 text-center">
                            <label className={"attach-image-label mt-3"} htmlFor={"attachImage"}>Add Image</label>
                            <input onChange={handleFileChange} id={"attachImage"} type={"file"} hidden/>
                        </div>
                    </div>
                    {displayImage &&
                        <div className="row mt-3">
                            <div className="col-md-12 text-center">
                                <button onClick={saveImage} className={"btn btn-primary"} style={{background:'#005662'}}>Save</button>
                            </div>
                        </div>
                    }

                </div>
            </Box>
        </Modal>
    )
}
export default CaptureModal
